import React from 'react';
import './AnimatedLogo.css';

const AnimatedLogo = () => {
  return (
    <div className="logo-container" style={{ direction: 'ltr' }}>
      <div className="logo-text">
        <span className="letter">A</span>
        <span className="letter">J</span>
        <span className="letter">E</span>
        <span className="letter">E</span>
        <span className="letter">B</span>
      </div>
    </div>
  );
};

export default AnimatedLogo;
