import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_CONFIG } from '../constants/config';

export default function Roles() {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${API_CONFIG.BASE_URL}/api/user-management/roles`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRoles(response.data.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const getRoleColor = (role) => {
    const colors = {
      admin: 'bg-purple-100 text-purple-800 border-purple-200',
      moderator: 'bg-blue-100 text-blue-800 border-blue-200',
      user: 'bg-green-100 text-green-800 border-green-200'
    };
    return colors[role.toLowerCase()] || 'bg-gray-100 text-gray-800 border-gray-200';
  };

  const getRoleIcon = (role) => {
    const icons = {
      admin: '👑',
      moderator: '🛡️',
      user: '👤'
    };
    return icons[role.toLowerCase()] || '🔹';
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">{t('roles.title')}</h1>
        <p className="mt-2 text-sm text-gray-600">{t('roles.description')}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {roles.map((role) => (
          <div 
            key={role}
            className={`p-4 rounded-lg border ${getRoleColor(role)} transition-transform transform hover:scale-105`}
          >
            <div className="flex items-center space-x-3">
              <span className="text-2xl">{getRoleIcon(role)}</span>
              <div>
                <h3 className="text-lg font-medium capitalize">{role}</h3>
                <p className="text-sm opacity-75">
                  {role === 'admin' && t('roles.admin.description')}
                  {role === 'moderator' && t('roles.moderator.description')}
                  {role === 'user' && t('roles.user.description')}
                  {!['admin', 'moderator', 'user'].includes(role.toLowerCase()) && t('roles.custom.description')}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {roles.length === 0 && (
        <div className="text-center py-12">
          <div className="text-gray-400 text-xl">{t('roles.noRoles')}</div>
        </div>
      )}
    </div>
  );
}