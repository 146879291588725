import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import Spinner from '../../components/Spinner';
import SearchableSelect from '../../components/SearchableSelect';
import { API_CONFIG, STORAGE_KEYS } from '../../constants/config';

export default function AddProduct() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [categories, setCategories] = useState([]);
    const [fields, setFields] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [dragActive, setDragActive] = useState(false);
    
    const [formData, setFormData] = useState({
        nameEng: '',
        nameAr: '',
        descEng: '',
        descAr: '',
        quantity: '',
        price: '',
        fieldId: '',
        categoryId: '',
        status: '1',
        rate: 0,
        discount: 0
    });

    // Get current market ID from localStorage
    const getCurrentMarketId = () => {
        const marketData = localStorage.getItem(STORAGE_KEYS.MARKET);
        if (marketData) {
            const market = JSON.parse(marketData);
            return market.id;
        }
        return null;
    };

    // Fetch categories
    const fetchCategories = async () => {
        try {
            const response = await api.get('/api/category-management', {
                params: {
                    pageSize: 50
                },
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (response.data?.status) {
                setCategories(response.data.data.categories || []);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setError(t('marketProducts.error.fetchCategories'));
        }
    };

    // Fetch fields
    const fetchFields = async () => {
        try {
            const response = await api.get('/api/field-management', {
                params: {
                    pageSize: 50
                },
                headers: {
                    'Accept': 'application/json'
                }
            });

            if (response.data?.status) {
                setFields(response.data.data.fields || []);
            }
        } catch (error) {
            console.error('Error fetching fields:', error);
            setError(t('marketProducts.error.fetchFields'));
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchFields();
    }, []);

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleImageFile(e.dataTransfer.files[0]);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            handleImageFile(e.target.files[0]);
        }
    };

    const handleImageFile = (file) => {
        if (file.size > 5 * 1024 * 1024) {
            setError(t('marketProducts.error.imageSize'));
            return;
        }
        setSelectedImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const marketId = getCurrentMarketId();
            if (!marketId) {
                setError(t('marketProducts.error.noMarket'));
                return;
            }

            const formDataToSend = new FormData();
            Object.keys(formData).forEach(key => {
                formDataToSend.append(key, formData[key]);
            });
            formDataToSend.append('marketId', marketId);
            if (selectedImage) {
                formDataToSend.append('image', selectedImage);
            }

            const response = await api.post('/api/product-management', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem(STORAGE_KEYS.TOKEN)}`
                }
            });

            if (response.data?.status) {
                navigate('/admin/market/products');
            } else {
                throw new Error(response.data?.message || t('marketProducts.error.create'));
            }
        } catch (error) {
            console.error('Error adding product:', error);
            setError(error.message || t('marketProducts.error.submit'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white rounded-xl shadow-sm p-6">
                    <h2 className="text-2xl font-bold text-gray-900 mb-8">{t('marketProducts.modal.add.title')}</h2>
                    
                    {error && (
                        <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                            <p className="text-sm">{error}</p>
                        </div>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-8">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Product Information */}
                            <div className="space-y-6">
                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </span>
                                    <input
                                        type="text"
                                        name="nameEng"
                                        value={formData.nameEng}
                                        onChange={handleInputChange}
                                        placeholder={t('marketProducts.form.nameEng')}
                                        className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                        required
                                    />
                                </div>

                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </span>
                                    <input
                                        type="text"
                                        name="nameAr"
                                        value={formData.nameAr}
                                        onChange={handleInputChange}
                                        placeholder={t('marketProducts.form.nameAr')}
                                        className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                        required
                                        dir="rtl"
                                    />
                                </div>

                                <div className="relative">
                                    <textarea
                                        name="descEng"
                                        value={formData.descEng}
                                        onChange={handleInputChange}
                                        placeholder={t('marketProducts.form.descEng')}
                                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                        rows="3"
                                        required
                                    />
                                </div>

                                <div className="relative">
                                    <textarea
                                        name="descAr"
                                        value={formData.descAr}
                                        onChange={handleInputChange}
                                        placeholder={t('marketProducts.form.descAr')}
                                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                        rows="3"
                                        required
                                        dir="rtl"
                                    />
                                </div>
                            </div>

                            {/* Product Details */}
                            <div className="space-y-6">
                                <div className="grid grid-cols-2 gap-6">
                                    <div className="relative">
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                        <input
                                            type="number"
                                            name="price"
                                            value={formData.price}
                                            onChange={handleInputChange}
                                            placeholder={t('marketProducts.form.price')}
                                            className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                            required
                                            min="0"
                                            step="0.01"
                                        />
                                    </div>

                                    <div className="relative">
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                            </svg>
                                        </span>
                                        <input
                                            type="number"
                                            name="discount"
                                            value={formData.discount}
                                            onChange={handleInputChange}
                                            placeholder={t('marketProducts.form.discount')}
                                            className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                            min="0"
                                            max="100"
                                        />
                                    </div>
                                </div>

                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </span>
                                    <input
                                        type="number"
                                        name="quantity"
                                        value={formData.quantity}
                                        onChange={handleInputChange}
                                        placeholder={t('marketProducts.form.quantity')}
                                        className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                                        required
                                        min="0"
                                    />
                                </div>

                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                                        </svg>
                                    </span>
                                    <select
                                        name="categoryId"
                                        value={formData.categoryId}
                                        onChange={handleInputChange}
                                        className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors appearance-none bg-transparent"
                                        required
                                    >
                                        <option value="">{t('marketProducts.form.selectCategory')}</option>
                                        {categories.map(category => (
                                            <option key={category.id} value={category.id}>
                                                {category.nameEng}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="relative">
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                                        </svg>
                                    </span>
                                    <select
                                        name="fieldId"
                                        value={formData.fieldId}
                                        onChange={handleInputChange}
                                        className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors appearance-none bg-transparent"
                                        required
                                    >
                                        <option value="">{t('marketProducts.form.selectField')}</option>
                                        {fields.map(field => (
                                            <option key={field.id} value={field.id}>
                                                {field.nameEng}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="relative">
                                    <select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                        className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-colors appearance-none bg-transparent"
                                        required
                                    >
                                        <option value="1">{t('marketProducts.form.status.active')}</option>
                                        <option value="0">{t('marketProducts.form.status.inactive')}</option>
                                    </select>
                                </div>
                            </div>

                            {/* Image Upload */}
                            <div
                                className={`col-span-2 border-2 border-dashed rounded-lg p-6 text-center ${
                                    dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
                                }`}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                            >
                                <input
                                    type="file"
                                    id="image-upload"
                                    onChange={handleImageChange}
                                    accept="image/*"
                                    className="hidden"
                                />
                                <label
                                    htmlFor="image-upload"
                                    className="cursor-pointer"
                                >
                                    <div className="text-gray-600">
                                        <span className="text-blue-500 font-medium">{t('marketProducts.form.preview')}</span>
                                    </div>
                                    <p className="text-xs text-gray-500">{t('marketProducts.form.imageSize')}</p>
                                </label>
                                {imagePreview && (
                                    <div className="mt-4">
                                        <img
                                            src={imagePreview}
                                            alt="Preview"
                                            className="mx-auto h-32 w-32 object-cover rounded-lg shadow-sm"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Form Actions */}
                        <div className="flex justify-end space-x-4 mt-8">
                            <button
                                type="button"
                                onClick={() => navigate('/admin/market/products')}
                                className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                            >
                                {t('marketProducts.actions.cancel')}
                            </button>
                            <button
                                type="submit"
                                disabled={loading}
                                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors disabled:bg-blue-300"
                            >
                                {loading ? <Spinner size="sm" /> : t('marketProducts.actions.create')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}