import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import { API_CONFIG } from '../constants/config';

const Customers = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 10;
  const [modalType, setModalType] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}${imagePath}`;
  };

  useEffect(() => {
    fetchCustomers();
  }, [currentPage, searchTerm]);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const queryParams = {
        PageNumber: currentPage,
        PageSize: pageSize
      };

      // Add search parameter if there's a search term
      if (searchTerm.trim()) {
        queryParams.Search = searchTerm.trim();
      }

      const response = await api.get('/api/user-management', { 
        params: queryParams,
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.data?.status) {
        setCustomers(response.data.data.users || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error(response.data?.message || 'Failed to fetch customers');
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
      console.error('Error response:', error.response?.data);
      setError(error.response?.data?.message || error.message || 'An error occurred');
      setCustomers([]);
    } finally {
      setLoading(false);
    }
  };

  // Debounce search to avoid too many API calls
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentPage !== 1) {
        setCurrentPage(1); // Reset to first page when searching
      } else {
        fetchCustomers();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // No need for client-side filtering since API handles search
  const filteredCustomers = customers;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image size should be less than 5MB');
        return;
      }
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetForm = () => {
    setModalType(null);
    setFormData({});
    setError('');
    setSelectedCustomer(null);
    setSelectedImage(null);
    setImagePreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
      setLoading(true);
      setError('');
      
      if (modalType === 'add') {
        // Validate required fields
        const requiredFields = ['userName', 'email', 'password', 'phoneNumber'];
        const missingFields = requiredFields.filter(field => !formData[field]);
        
        if (missingFields.length > 0) {
          throw new Error(`Please fill in all required fields: ${missingFields.join(', ')}`);
        }

        // Create form data for multipart/form-data
        const formDataToSend = new FormData();
        
        // Add all form fields
        Object.keys(formData).forEach(key => {
          if (formData[key]) {
            formDataToSend.append(key, formData[key]);
          }
        });

        // Add image if selected
        formDataToSend.append('profileImage', "a.png");
        formDataToSend.append('birth', "2025-01-04 03:45:09.407758");
        formDataToSend.append('role', 'user');
        // Log the form data being sent
        console.log('Creating user with form data:', Object.fromEntries(formDataToSend.entries()));

        const response = await api.post('/api/user-management', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          },
        });

        if (response.data?.status) {
          resetForm();
          await fetchCustomers();
        } else {
          throw new Error(response.data?.message || 'Failed to add customer');
        }
      } else if (modalType === 'edit' && selectedCustomer) {
        const updateData = {
          fullName: formData.fullName,
          phoneNumber: formData.phoneNumber,
          city: formData.city,
          gender: formData.gender
        };
        
        // Remove empty fields
        Object.keys(updateData).forEach(key => {
          if (!updateData[key]) {
            delete updateData[key];
          }
        });
        
        // Log the data to verify structure
        console.log('Update Data:', JSON.stringify(updateData));
        
        try {
          const response = await api.put(`/api/user-management/${selectedCustomer.id}`, updateData, {
            headers: {
              'Content-Type': 'application/json'
            }
          });
        
          if (response.data?.status) {
            resetForm();
            await fetchCustomers();
          } else {
            throw new Error(response.data?.message || 'Failed to update customer');
          }
        } catch (error) {
          console.error('Error updating customer:', error);
          // Handle the error appropriately, e.g., display a message to the user
        }
      }

  };

  const openDeleteDialog = (customer) => {
    setCustomerToDelete(customer);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setCustomerToDelete(null);
    setDeleteDialogOpen(false);
    setDeleteLoading(false);
    setError('');
  };

  const handleDelete = async () => {
    if (!customerToDelete) return;
    
    try {
      setDeleteLoading(true);
      const response = await api.delete(`/api/user-management/${customerToDelete.id}`);
      
        setError('');
        closeDeleteDialog();
        await fetchCustomers();
    
    } catch (error) {
      console.error('Error deleting customer:', error);
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 401:
            setError('You need to be logged in to delete this customer.');
            break;
          case 403:
            setError('You do not have permission to delete this customer.');
            break;
          case 404:
            setError('Customer not found. It may have already been deleted.');
            break;
          default:
            setError(error.response?.data?.message || 'Failed to delete customer');
        }
      } else {
        setError(error.message || 'An error occurred while deleting the customer');
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const openEditModal = (customer) => {
    setSelectedCustomer(customer);
    setFormData({
      userName: customer.userName || '',
      email: customer.email || '',
      phoneNumber: customer.phoneNumber || '',
      fullName: customer.fullName || '',
      city: customer.city || '',
      gender: customer.gender || '',
    });
    setImagePreview(customer.profileImage ? getImageUrl(customer.profileImage) : null);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setSelectedCustomer(null);
    setFormData({});
    setEditModalOpen(false);
    setEditLoading(false);
    setError('');
    setImagePreview(null);
    setSelectedImage(null);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      setEditLoading(true);
      
      // Create FormData object for multipart/form-data
      const formDataToSend = new FormData();
      
      // Add all form fields that have values
      Object.keys(formData).forEach(key => {
        if (formData[key]) {
          formDataToSend.append(key, formData[key]);
        }
      });

      // Add image if a new one was selected
      if (selectedImage) {
        formDataToSend.append('profileImage', selectedImage);
      }

      const response = await api.put(`/api/user-management/${selectedCustomer.id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        closeEditModal();
        await fetchCustomers();
      } else {
        throw new Error(response.data?.message || 'Failed to update customer');
      }
    } catch (error) {
      console.error('Error updating customer:', error);
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 400:
            const validationErrors = error.response.data?.errors;
            if (validationErrors) {
              const errorMessages = Object.values(validationErrors).flat()
                .join('\n');
              setError(`Validation errors:\n${errorMessages}`);
            } else {
              setError('Invalid form data. Please check your input.');
            }
            break;
          case 401:
            setError('You need to be logged in to update this customer.');
            break;
          case 403:
            setError('You do not have permission to update this customer.');
            break;
          case 404:
            setError('Customer not found. It may have been deleted.');
            break;
          default:
            setError(error.response?.data?.message || 'Failed to update customer');
        }
      } else {
        setError(error.message || 'An error occurred while updating the customer');
      }
    } finally {
      setEditLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6 transition-all duration-300 hover:shadow-md">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
          <h1 className="text-xl font-semibold text-gray-800">{t('customers.title')}</h1>
          <div className="flex flex-col md:flex-row gap-3 md:items-center">
            <div className="relative">
              <input
                type="text"
                placeholder={t('customers.search.placeholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
              />
              <svg
                className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <button
              onClick={() => {
                setSelectedCustomer(null);
                setModalType('add');
              }}
              className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105 space-x-2"
            >
              <svg
                className="w-5 h-5 animate-pulse"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <span>{t('customers.actions.add')}</span>
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-3 mb-4 animate-fadeIn">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {filteredCustomers.map((customer) => (
              <div
                key={customer.id}
                className="bg-white rounded-xl border border-gray-100 hover:shadow-lg transition-all duration-300"
              >
                <div className="p-6">
                  <div className="flex items-center space-x-4 rtl:space-x-reverse">
                    {customer.profileImage ? (
                      <img
                        src={getImageUrl(customer.profileImage)}
                        alt={customer.userName}
                        className="w-16 h-16 rounded-full object-cover border-2 border-purple-100"
                      />
                    ) : (
                      <div className="w-16 h-16 rounded-full bg-gradient-to-r from-purple-600 to-purple-800 flex items-center justify-center text-white text-xl font-semibold">
                        {customer.fullName?.charAt(0).toUpperCase() || customer.userName?.charAt(0).toUpperCase() || '?'}
                      </div>
                    )}
                    <div className="flex-1 min-w-0">
                      <h3 className="text-lg font-semibold text-gray-900 truncate mb-1">
                        {customer.fullName || customer.userName}
                      </h3>
                      <p className="text-sm text-gray-500 truncate">{customer.email}</p>
                    </div>
                  </div>
                  
                  <div className="mt-4">
                    <div className="flex items-center text-sm text-gray-600 bg-gray-50 rounded-lg p-2.5 rtl:space-x-reverse">
                      <svg className="w-4 h-4 me-2 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                      </svg>
                      <span className="truncate">{customer.userName}</span>
                    </div>
                  </div>
                  
                  <div className="mt-6 flex gap-3 rtl:space-x-reverse">
                    <button
                      onClick={() => openEditModal(customer)}
                      className="flex-1 px-4 py-2 text-sm font-medium text-purple-700 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors"
                    >
                      {t('customers.actions.edit')}
                    </button>
                    <button
                      onClick={() => openDeleteDialog(customer)}
                      className="flex-1 px-4 py-2 text-sm font-medium text-red-700 bg-red-50 rounded-lg hover:bg-red-100 transition-colors"
                    >
                      {t('customers.actions.delete')}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-4">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />
        </div>

        {(modalType === 'add' || modalType === 'edit') && (
          <Modal 
            isOpen={modalType !== null || editModalOpen} 
            onClose={modalType === 'add' ? resetForm : closeEditModal}
            title={t(modalType === 'add' ? 'customers.form.addTitle' : 'customers.form.editTitle')}
          >
            <form onSubmit={modalType === 'add' ? handleSubmit : handleEdit} className="p-6">
              <div className="space-y-8">
                {/* Header */}
                <div className="border-b border-gray-100 pb-5">
                  <h2 className="text-2xl font-bold text-gray-900 mb-1">
                    {t(modalType === 'add' ? 'customers.form.addTitle' : 'customers.form.editTitle')}
                  </h2>
                  <p className="text-sm text-gray-500">
                    {t(modalType === 'add' ? 'customers.form.addDescription' : 'customers.form.editDescription')}
                  </p>
                </div>

                {error && (
                  <div className="p-4 bg-red-50 border-l-4 border-red-500 rounded-r-lg">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                        </svg>
                      </div>
                      <div className="ms-3">
                        <p className="text-sm text-red-700">{error}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Profile Image Upload */}
                <div className="flex flex-col items-center space-y-4">
                  <div className="relative group">
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt={formData.userName}
                        className="w-32 h-32 rounded-full object-cover border-4 border-purple-100 group-hover:border-purple-200 transition-all duration-300"
                      />
                    ) : (
                      <div className="w-32 h-32 rounded-full bg-gradient-to-r from-purple-600 to-purple-800 flex items-center justify-center text-white text-4xl font-semibold group-hover:from-purple-700 group-hover:to-purple-900 transition-all duration-300">
                        {formData.fullName?.charAt(0).toUpperCase() || formData.userName?.charAt(0).toUpperCase() || '?'}
                      </div>
                    )}
                    <label
                      htmlFor="profile-image"
                      className="absolute bottom-0 right-0 bg-white rounded-full p-2.5 shadow-lg cursor-pointer hover:bg-gray-50 transition-all duration-300 border border-gray-100 group-hover:border-purple-200"
                    >
                      <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </label>
                    <input
                      type="file"
                      id="profile-image"
                      name="profileImage"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </div>
                  <p className="text-sm text-gray-500">
                    {t('customers.form.imageUpload')}
                  </p>
                </div>

                {/* Form Fields */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Full Name */}
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t('customers.form.fields.fullName.label')}
                    </label>
                    <input
                      type="text"
                      name="fullName"
                      value={formData.fullName || ''}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 focus:bg-white transition-all duration-300"
                      placeholder={t('customers.form.fields.fullName.placeholder')}
                    />
                  </div>

                  {/* Username */}
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t('customers.form.fields.username.label')}
                    </label>
                    <input
                      type="text"
                      name="userName"
                      value={formData.userName || ''}
                      onChange={handleInputChange}
                      className={`w-full px-4 py-2.5 border rounded-lg transition-all duration-300 ${
                        modalType === 'edit'
                          ? 'bg-gray-100 border-gray-200 text-gray-500 cursor-not-allowed'
                          : 'bg-gray-50 border-gray-200 focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 focus:bg-white'
                      }`}
                      required={modalType === 'add'}
                      placeholder={t('customers.form.fields.username.placeholder')}
                      disabled={modalType === 'edit'}
                    />
                  </div>

                  {/* Email */}
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t('customers.form.fields.email.label')}
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email || ''}
                      onChange={handleInputChange}
                      className={`w-full px-4 py-2.5 border rounded-lg transition-all duration-300 ${
                        modalType === 'edit'
                          ? 'bg-gray-100 border-gray-200 text-gray-500 cursor-not-allowed'
                          : 'bg-gray-50 border-gray-200 focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 focus:bg-white'
                      }`}
                      required={modalType === 'add'}
                      placeholder={t('customers.form.fields.email.placeholder')}
                      disabled={modalType === 'edit'}
                    />
                  </div>

                  {/* Phone Number */}
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t('customers.form.fields.phoneNumber.label')}
                    </label>
                    <div className="relative">
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber || ''}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 focus:bg-white transition-all duration-300"
                        required={modalType === 'add'}
                        placeholder={t('customers.form.fields.phoneNumber.placeholder')}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none text-gray-400">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Password - Only show in add mode */}
                  {modalType === 'add' && (
                    <div className="relative">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {t('customers.form.fields.password.label')}
                      </label>
                      <div className="relative">
                        <input
                          type="password"
                          name="password"
                          value={formData.password || ''}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 focus:bg-white transition-all duration-300"
                          required
                          placeholder={t('customers.form.fields.password.placeholder')}
                          minLength={8}
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none text-gray-400">
                          <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8V7a4 4 0 00-8 0v4h8z" />
                          </svg>
                        </div>
                      </div>
                      <p className="mt-1.5 text-xs text-gray-500">{t('customers.form.fields.password.hint')}</p>
                    </div>
                  )}

                  {/* City */}
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t('customers.form.fields.city.label')}
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        name="city"
                        value={formData.city || ''}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 focus:bg-white transition-all duration-300"
                        placeholder={t('customers.form.fields.city.placeholder')}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none text-gray-400">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  {/* Gender */}
                  <div className="relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      {t('customers.form.fields.gender.label')}
                    </label>
                    <div className="relative">
                      <select
                        name="gender"
                        value={formData.gender || ''}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2.5 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500/20 focus:border-purple-500 focus:bg-white transition-all duration-300 appearance-none"
                      >
                        <option value="">{t('customers.form.fields.gender.placeholder')}</option>
                        <option value="male">{t('customers.form.fields.gender.options.male')}</option>
                        <option value="female">{t('customers.form.fields.gender.options.female')}</option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none text-gray-400">
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex justify-end items-center space-x-3 rtl:space-x-reverse pt-6 border-t border-gray-100">
                  <button
                    type="button"
                    onClick={modalType === 'add' ? resetForm : closeEditModal}
                    className="px-5 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-all duration-300"
                  >
                    {t('common.cancel')}
                  </button>
                  <button
                    type="submit"
                    className="px-5 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-purple-600 to-purple-800 rounded-lg hover:from-purple-700 hover:to-purple-900 transition-all duration-300 disabled:opacity-70"
                    disabled={loading || editLoading}
                  >
                    {loading || editLoading ? (
                      <span className="flex items-center">
                        <Spinner className="w-4 h-4 me-2" />
                        {t('common.loading')}
                      </span>
                    ) : (
                      t(modalType === 'add' ? 'common.create' : 'common.save')
                    )}
                  </button>
                </div>
              </div>
            </form>
          </Modal>
        )}

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={deleteDialogOpen}
          onClose={closeDeleteDialog}
          title={t('customers.delete.title')}
        >
          <div className="p-6">
            <p className="text-lg font-medium text-gray-900 mb-4">
              {t('customers.delete.message')}
            </p>
            <p className="text-sm text-gray-500 mb-6">
              {t('customers.delete.warning')}
            </p>
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={closeDeleteDialog}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                disabled={deleteLoading}
              >
                {t('customers.actions.cancel')}
              </button>
              <button
                type="button"
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
                disabled={deleteLoading}
              >
                {deleteLoading ? t('customers.delete.processing') : t('customers.delete.confirm')}
              </button>
            </div>
          </div>
        </Modal>

        {/* Edit Modal */}
        <Modal
          isOpen={editModalOpen}
          onClose={closeEditModal}
          title="Edit Customer"
        >
          <div className="p-6">
            <form onSubmit={handleEdit} className="space-y-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900">Edit Customer Information</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Update the customer's information below.
                </p>
              </div>

              {/* Profile Image Upload */}
              <div className="flex flex-col items-center space-y-4">
                <div className="relative">
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Profile preview"
                      className="w-32 h-32 rounded-full object-cover border-4 border-purple-100"
                    />
                  ) : (
                    <div className="w-32 h-32 rounded-full bg-gradient-to-r from-purple-600 to-purple-800 flex items-center justify-center text-white text-4xl font-semibold">
                      {formData.userName?.charAt(0).toUpperCase() || '?'}
                    </div>
                  )}
                  <label
                    htmlFor="profile-image"
                    className="absolute bottom-0 right-0 bg-white rounded-full p-2 shadow-lg cursor-pointer hover:bg-gray-50 transition-colors"
                  >
                    <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </label>
                  <input
                    type="file"
                    id="profile-image"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                  />
                </div>
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label htmlFor="userName" className="block text-sm font-medium text-gray-700">
                    Username
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    value={formData.userName || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                  />
                </div>

                <div>
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                  />
                </div>

                <div>
                  <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                  />
                </div>

                <div>
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formData.city || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                  />
                </div>

                <div>
                  <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                    Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender || ''}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>

              {error && (
                <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
                  {error}
                </div>
              )}

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={closeEditModal}
                  className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                  disabled={editLoading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
                  disabled={editLoading}
                >
                  {editLoading ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Customers;
