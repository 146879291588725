import axios from 'axios';
import { API_CONFIG } from '../constants/config';

const api = axios.create({
    baseURL: API_CONFIG.BASE_URL,
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // Set content type based on request type
        if (config.method === 'get') {
            config.headers['Content-Type'] = 'application/json';
        } else if (config.data instanceof FormData) {
            delete config.headers['Content-Type']; // Let the browser set it
        } else {
            config.headers['Content-Type'] = 'application/json';
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            // Handle unauthorized access
            localStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default api;
