import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import { API_CONFIG } from '../constants/config';
import toast from '../components/Toast';

export default function Drivers() {
  const { t } = useTranslation();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 12;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [formData, setFormData] = useState({
    phoneNumber: '',
    password: '',
    fullName: '',
    marketId: '',
    addressName: '',
    lat: '',
    lng: '',
    area: '',
  });
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImages, setSelectedImages] = useState({
    profileImage: null,
    idImage: null,
    drivingLicense: null,
    vehicleLicense: null
  });
  const [imagePreview, setImagePreview] = useState({
    profileImage: null,
    idImage: null,
    drivingLicense: null,
    vehicleLicense: null
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    sortBy: 'createdate',
    isDescending: true
  });

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}/images/${imagePath}`;
  };

  useEffect(() => {
    fetchDrivers();
    fetchMarkets();
  }, [currentPage, searchTerm, sortConfig]);

  const fetchMarkets = async () => {
    try {
      const response = await api.get('/api/market-management', {
        params: {
          pageSize: 50
        },
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setMarkets(response.data.data.markets || []);
      }
    } catch (error) {
      console.error('Error fetching markets:', error);
    }
  };

  const fetchDrivers = async () => {
    try {
      setLoading(true);
      const queryParams = {
        pageNumber: currentPage,
        pageSize: pageSize,
        sortBy: sortConfig.sortBy,
        isDescending: sortConfig.isDescending
      };

      if (searchTerm.trim()) {
        queryParams.searchTerm = searchTerm.trim();
      }

      const response = await api.get('/api/driver-management', {
        params: queryParams
      });

      if (response.data?.status) {
        setDrivers(response.data.data.drivers || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error(response.data?.message || 'Failed to fetch drivers');
      }
    } catch (error) {
      console.error('Error fetching drivers:', error);
      setError(error.response?.data?.message || error.message || 'An error occurred');
      setDrivers([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const handleImageChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError(t('drivers.errors.imageSize'));
        return;
      }
      if (!file.type.startsWith('image/')) {
        setError(t('drivers.errors.imageType'));
        return;
      }
      setSelectedImages(prev => ({ ...prev, [imageType]: file }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(prev => ({ ...prev, [imageType]: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const resetForm = () => {
    setIsModalOpen(false);
    setModalType(null);
    setSelectedDriver(null);
    setFormData({
      phoneNumber: '',
      password: '',
      fullName: '',
      marketId: '',
      addressName: '',
      lat: '',
      lng: '',
      area: '',
    });
    setSelectedImages({
      profileImage: null,
      idImage: null,
      drivingLicense: null,
      vehicleLicense: null
    });
    setImagePreview({
      profileImage: null,
      idImage: null,
      drivingLicense: null,
      vehicleLicense: null
    });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataObj = new FormData();
      
      if (modalType === 'add') {
        // Add user fields
        formDataObj.append('PhoneNumber', formData.phoneNumber);
        formDataObj.append('Password', formData.password);
        formDataObj.append('FullName', formData.fullName);

        // Add driver object as a nested structure
        formDataObj.append('Driver.MarketId', parseInt(formData.marketId));
        formDataObj.append('Driver.AddressName', formData.addressName);
        formDataObj.append('Driver.Lat', parseFloat(formData.lat));
        formDataObj.append('Driver.Lng', parseFloat(formData.lng));
        formDataObj.append('Driver.Area', parseFloat(formData.area));

        // Add image files
        if (selectedImages.profileImage) {
          formDataObj.append('ProfileImage', selectedImages.profileImage);
        }
        if (selectedImages.idImage) {
          formDataObj.append('IdImage', selectedImages.idImage);
        }
        if (selectedImages.drivingLicense) {
          formDataObj.append('DrivingLicense', selectedImages.drivingLicense);
        }
        if (selectedImages.vehicleLicense) {
          formDataObj.append('VehicleLicense', selectedImages.vehicleLicense);
        }

        // Debug log
        console.log('Form Data:');
        for (let [key, value] of formDataObj.entries()) {
          console.log(key, ':', value);
        }

        const response = await api.post('/api/driver-management', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });

        console.log('API Response:', response);
      } else {
        // For update, use the existing structure
        await handleUpdate(selectedDriver.id);
      }

      resetForm();
      fetchDrivers();
    } catch (error) {
      console.error('API Error:', error.response?.data || error);
      setError(error.response?.data?.message || error.message || 'An error occurred');
    }
  };

  const handleUpdate = async (driverId) => {
    try {
      setLoading(true);
      
      // Create FormData for file uploads
      const formData = new FormData();
      
      // Add driver fields
      const driverFields = {
        addressName: formData.addressName,
        lat: parseFloat(formData.lat),
        lng: parseFloat(formData.lng),
        area: parseFloat(formData.area),
        status: parseInt(formData.status),
        marketId: parseInt(formData.marketId)
      };

      // Add files if they exist
      if (selectedImages.profileImage) {
        formData.append('profileImage', selectedImages.profileImage);
      }
      if (selectedImages.idImage) {
        formData.append('idImage', selectedImages.idImage);
      }
      if (selectedImages.drivingLicense) {
        formData.append('drivingLicense', selectedImages.drivingLicense);
      }
      if (selectedImages.vehicleLicense) {
        formData.append('vehicleLicense', selectedImages.vehicleLicense);
      }

      // Add driver fields to FormData
      Object.entries(driverFields).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          formData.append(key, value);
        }
      });

      const response = await api.put(`/api/driver-management/${driverId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data?.status) {
        // Update the drivers list with the updated driver
        setDrivers(prevDrivers => 
          prevDrivers.map(driver => 
            driver.id === driverId ? response.data.data : driver
          )
        );
        setIsModalOpen(false);
        setError('');
        // Show success message
        // toast.success('Driver updated successfully');
      } else {
        throw new Error(response.data?.message || 'Failed to update driver');
      }
    } catch (error) {
      console.error('Error updating driver:', error);
      setError(error.response?.data?.message || error.message || 'Failed to update driver');
      // Show error message
      // toast.error(error.response?.data?.message || 'Failed to update driver');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!driverToDelete) return;
    
    try {
      setDeleteLoading(true);
      await api.delete(`/api/driver-management/${driverToDelete.id}`);
      setDeleteDialogOpen(false);
      setDriverToDelete(null);
      fetchDrivers();
    } catch (error) {
      setError(error.response?.data?.message || error.message || 'Failed to delete driver');
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleSort = (sortBy) => {
    setSortConfig(prev => ({
      sortBy,
      isDescending: prev.sortBy === sortBy ? !prev.isDescending : true
    }));
  };

  if (loading) return <Spinner />;

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6 transition-all duration-300 hover:shadow-md">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
          <h1 className="text-xl font-semibold text-gray-800">{t('drivers.title')}</h1>
          <div className="flex flex-col md:flex-row gap-3 md:items-center">
            <div className="relative">
              <input
                type="text"
                placeholder={t('drivers.search.placeholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
              />
              <svg
                className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <button
              onClick={() => {
                setModalType('add');
                setIsModalOpen(true);
              }}
              className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105 space-x-2"
            >
              <svg
                className="w-5 h-5 animate-pulse"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <span>{t('drivers.actions.add')}</span>
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-3 mb-4 animate-fadeIn">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.image')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.name')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.phone')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.date')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.rating')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.documentation')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.status')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.online')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('drivers.table.action')}</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {drivers.map((driver) => (
                  <tr key={driver.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-10 h-10 rounded-full overflow-hidden">
                        <img
                          src={getImageUrl(driver.user?.profileImage || driver.profilleImage) || '/default-avatar.png'}
                          alt={driver.user?.fullName}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{driver.user?.fullName}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                      {driver.user?.phoneNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {new Date(driver.createAte).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <span className="text-yellow-500">★</span>
                        <span className="ml-1">{driver.rating.toFixed(1)}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button className="text-blue-600 hover:underline">{t('drivers.actions.showDetails')}</button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 py-1 text-xs rounded-full ${
                        driver.status === 1
                          ? 'bg-green-100 text-green-800'
                          : 'bg-gray-100 text-gray-800'
                      }`}>
                        {driver.status === 1 ? t('drivers.status.active') : t('drivers.status.pending')}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className={`w-3 h-3 rounded-full ${
                        driver.user?.status === 'ACTIVE' ? 'bg-green-500' : 'bg-red-500'
                      }`}></div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      <button
                        onClick={() => {
                          setSelectedDriver(driver);
                          setFormData({
                            ...driver,
                            fullName: driver.user?.fullName,
                            phoneNumber: driver.user?.phoneNumber,
                            password: ''
                          });
                          setModalType('edit');
                          setIsModalOpen(true);
                        }}
                        className="text-gray-600 hover:text-gray-900 mr-3"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          setDriverToDelete(driver);
                          setDeleteDialogOpen(true);
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="mt-4">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />
        </div>

        <Modal
          isOpen={isModalOpen}
          onClose={resetForm}
          title={t(modalType === 'add' ? 'drivers.form.addTitle' : 'drivers.form.editTitle')}
        >
          <form onSubmit={handleSubmit} className="space-y-4">
            {modalType === 'add' && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.phoneNumber')}</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.password')}</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border rounded-md shadow-sm p-2"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.fullName')}</label>
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border rounded-md shadow-sm p-2"
                    required
                  />
                </div>
              </>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.market')}</label>
              <select
                name="marketId"
                value={formData.marketId}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                required
              >
                <option value="">{t('drivers.form.fields.selectMarket')}</option>
                {markets.map(market => (
                  <option key={market.id} value={market.id}>
                    {market.titleEng}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.address')}</label>
              <input
                type="text"
                name="addressName"
                value={formData.addressName}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.coordinates.latitude')}</label>
                <input
                  type="number"
                  step="any"
                  name="lat"
                  value={formData.lat}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border rounded-md shadow-sm p-2"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.coordinates.longitude')}</label>
                <input
                  type="number"
                  step="any"
                  name="lng"
                  value={formData.lng}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border rounded-md shadow-sm p-2"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.coordinates.area')}</label>
              <input
                type="number"
                step="0.1"
                name="area"
                value={formData.area}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                required
              />
            </div>

            {modalType === 'add' && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.images.profile')}</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, 'profileImage')}
                    className="mt-1"
                    required
                  />
                  {imagePreview.profileImage && (
                    <img src={imagePreview.profileImage} alt="Profile preview" className="mt-2 h-20 w-20 object-cover" />
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.images.id')}</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, 'idImage')}
                    className="mt-1"
                    required
                  />
                  {imagePreview.idImage && (
                    <img src={imagePreview.idImage} alt="ID preview" className="mt-2 h-20 w-20 object-cover" />
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.images.drivingLicense')}</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, 'drivingLicense')}
                    className="mt-1"
                    required
                  />
                  {imagePreview.drivingLicense && (
                    <img src={imagePreview.drivingLicense} alt="License preview" className="mt-2 h-20 w-20 object-cover" />
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('drivers.form.fields.images.vehicleLicense')}</label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, 'vehicleLicense')}
                    className="mt-1"
                    required
                  />
                  {imagePreview.vehicleLicense && (
                    <img src={imagePreview.vehicleLicense} alt="Vehicle license preview" className="mt-2 h-20 w-20 object-cover" />
                  )}
                </div>
              </div>
            )}

            {error && <div className="text-red-500">{error}</div>}

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={resetForm}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
              >
                {t('drivers.actions.cancel')}
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                {modalType === 'add' ? t('drivers.actions.create') : t('drivers.actions.update')}
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={deleteDialogOpen}
          onClose={() => {
            setDeleteDialogOpen(false);
            setDriverToDelete(null);
          }}
          title={t('drivers.delete.title')}
        >
          <div className="p-6">
            <p className="mb-4">{t('drivers.delete.message')}</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => {
                  setDeleteDialogOpen(false);
                  setDriverToDelete(null);
                }}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                disabled={deleteLoading}
              >
                {t('drivers.actions.cancel')}
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                disabled={deleteLoading}
              >
                {deleteLoading ? t('drivers.delete.processing') : t('drivers.delete.confirm')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}