import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell, AreaChart, Area
} from 'recharts';

import { API_CONFIG } from '../constants/config';

const Home = () => {
  const { t } = useTranslation();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_CONFIG.BASE_URL}/api/dashboard`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (!response.ok) {
          throw new Error(t('home.error.fetchData'));
        }
        
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error(t('home.error.fetchDataLog'), error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [t]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!dashboardData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-gray-500">{t('home.error.loadData')}</p>
      </div>
    );
  }

  const { stats, marketGrowth, userGrowth, orderStatus, weeklySales, recentOrders } = dashboardData;

  // Convert orderStatus to pie chart data
  const orderStatusData = [
    { name: t('home.orderStatus.delivered'), value: orderStatus.delivered },
    { name: t('home.orderStatus.pending'), value: orderStatus.pending },
    { name: t('home.orderStatus.processing'), value: orderStatus.processing }
  ];

  const COLORS = ['#10B981', '#F59E0B', '#6366F1'];

  const formatCurrency = (value) => t('home.currency', { value: value.toLocaleString() });

  const formatDate = (dateString) => {
    try {
      if (!dateString) return '';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return '';
      return format(date, 'MMM dd, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  return (
    <div className="p-4 space-y-6 max-w-[2000px] mx-auto">
      {/* Stats Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-3">
        {/* Total Users */}
        <div className="col-span-1 bg-white rounded-xl p-3 shadow-sm">
          <div className="flex justify-between items-start mb-2">
            <div className="bg-violet-100 p-2 rounded-lg">
              <svg className="w-4 h-4 text-violet-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </div>
            <div className="px-2 py-0.5 rounded-lg bg-violet-100 text-violet-600 text-xs font-medium">
              {stats.totalUsers.percentageChange >= 0 ? '↑' : '↓'} {Math.abs(stats.totalUsers.percentageChange)}%
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold text-gray-900">{stats.totalUsers.value}</h3>
            <p className="text-xs text-gray-600">{t('home.stats.totalUsers')}</p>
            <p className="text-[10px] text-gray-500 mt-1">{stats.totalUsers.comparisonPeriod}</p>
          </div>
        </div>

        {/* Total Orders */}
        <div className="col-span-1 bg-white rounded-xl p-3 shadow-sm">
          <div className="flex justify-between items-start mb-2">
            <div className="bg-blue-100 p-2 rounded-lg">
              <svg className="w-4 h-4 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
              </svg>
            </div>
            <div className="px-2 py-0.5 rounded-lg bg-blue-100 text-blue-600 text-xs font-medium">
              {stats.totalOrders.percentageChange >= 0 ? '↑' : '↓'} {Math.abs(stats.totalOrders.percentageChange)}%
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold text-gray-900">{stats.totalOrders.value}</h3>
            <p className="text-xs text-gray-600">{t('home.stats.totalOrders')}</p>
            <p className="text-[10px] text-gray-500 mt-1">{stats.totalOrders.comparisonPeriod}</p>
          </div>
        </div>

        {/* Total Sales */}
        <div className="col-span-1 bg-white rounded-xl p-3 shadow-sm">
          <div className="flex justify-between items-start mb-2">
            <div className="bg-emerald-100 p-2 rounded-lg">
              <svg className="w-4 h-4 text-emerald-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="px-2 py-0.5 rounded-lg bg-emerald-100 text-emerald-600 text-xs font-medium">
              {stats.totalSales.percentageChange >= 0 ? '↑' : '↓'} {Math.abs(stats.totalSales.percentageChange)}%
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold text-gray-900">{formatCurrency(stats.totalSales.value)}</h3>
            <p className="text-xs text-gray-600">{t('home.stats.totalSales')}</p>
            <p className="text-[10px] text-gray-500 mt-1">{stats.totalSales.comparisonPeriod}</p>
          </div>
        </div>

        {/* Total Pending */}
        <div className="col-span-1 bg-white rounded-xl p-3 shadow-sm">
          <div className="flex justify-between items-start mb-2">
            <div className="bg-rose-100 p-2 rounded-lg">
              <svg className="w-4 h-4 text-rose-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div className="px-2 py-0.5 rounded-lg bg-rose-100 text-rose-600 text-xs font-medium">
              {stats.totalPending.percentageChange >= 0 ? '↑' : '↓'} {Math.abs(stats.totalPending.percentageChange)}%
            </div>
          </div>
          <div>
            <h3 className="text-xl font-bold text-gray-900">{stats.totalPending.value}</h3>
            <p className="text-xs text-gray-600">{t('home.stats.totalPending')}</p>
            <p className="text-[10px] text-gray-500 mt-1">{stats.totalPending.comparisonPeriod}</p>
          </div>
        </div>
      </div>

      {/* Charts Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Market Growth Chart */}
        <div className="bg-white rounded-2xl p-4 shadow-lg min-h-[400px]">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">{t('home.charts.marketGrowth')}</h2>
          <div className="w-full h-[300px] md:h-[350px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={marketGrowth} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="month" 
                  tick={{ fontSize: 12 }}
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis width={80} tick={{ fontSize: 12 }} />
                <Tooltip 
                  formatter={(value) => value.toLocaleString()} 
                  contentStyle={{ fontSize: '12px' }}
                />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
                <Bar 
                  dataKey="value" 
                  name={t('home.charts.marketGrowthLabel')} 
                  fill="#8B5CF6" 
                  radius={[4, 4, 0, 0]} 
                  maxBarSize={50}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* User Growth Chart */}
        <div className="bg-white rounded-2xl p-4 shadow-lg min-h-[400px]">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">{t('home.charts.userGrowth')}</h2>
          <div className="w-full h-[300px] md:h-[350px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={userGrowth} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="month" 
                  tick={{ fontSize: 12 }}
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={60}
                />
                <YAxis width={80} tick={{ fontSize: 12 }} />
                <Tooltip 
                  formatter={(value) => value.toLocaleString()} 
                  contentStyle={{ fontSize: '12px' }}
                />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
                <Area
                  type="monotone"
                  dataKey="value"
                  name={t('home.charts.userGrowthLabel')}
                  stroke="#3B82F6"
                  fill="#93C5FD"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Order Status Chart */}
        <div className="bg-white rounded-2xl p-4 shadow-lg min-h-[400px]">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">{t('home.charts.orderStatus')}</h2>
          <div className="w-full h-[300px] md:h-[350px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={orderStatusData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {orderStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => value.toLocaleString()} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Weekly Sales Chart */}
        <div className="bg-white rounded-2xl p-4 shadow-lg min-h-[400px]">
          <h2 className="text-lg font-semibold text-gray-800 mb-4">{t('home.charts.weeklySales')}</h2>
          <div className="w-full h-[300px] md:h-[350px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={weeklySales} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="day" 
                  tick={{ fontSize: 12 }}
                />
                <YAxis 
                  width={80} 
                  tick={{ fontSize: 12 }}
                  tickFormatter={formatCurrency}
                />
                <Tooltip 
                  formatter={formatCurrency}
                  contentStyle={{ fontSize: '12px' }}
                />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
                <Bar 
                  dataKey="value" 
                  name={t('home.charts.weeklySalesLabel')} 
                  fill="#10B981" 
                  radius={[4, 4, 0, 0]}
                  maxBarSize={50}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Recent Orders Table */}
      <div className="bg-white rounded-2xl p-4 shadow-lg">
        <h2 className="text-lg font-semibold text-gray-800 mb-4">{t('home.recentOrders.title')}</h2>
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th className="px-4 py-3">{t('home.recentOrders.columns.orderId')}</th>
                <th className="px-4 py-3">{t('home.recentOrders.columns.customer')}</th>
                <th className="px-4 py-3">{t('home.recentOrders.columns.product')}</th>
                <th className="px-4 py-3">{t('home.recentOrders.columns.amount')}</th>
                <th className="px-4 py-3">{t('home.recentOrders.columns.status')}</th>
                <th className="px-4 py-3">{t('home.recentOrders.columns.date')}</th>
              </tr>
            </thead>
            <tbody>
              {recentOrders.map((order) => (
                <tr key={order.id} className="bg-white border-b hover:bg-gray-50">
                  <td className="px-4 py-3">#{order.id}</td>
                  <td className="px-4 py-3">{order.customer}</td>
                  <td className="px-4 py-3">{order.product}</td>
                  <td className="px-4 py-3">{formatCurrency(order.amount)}</td>
                  <td className="px-4 py-3">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      order.status === 'completed' ? 'bg-green-100 text-green-800' :
                      order.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {t(`home.recentOrders.status.${order.status}`)}
                    </span>
                  </td>
                  <td className="px-4 py-3">{formatDate(order.date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Home;