import { NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { STORAGE_KEYS } from '../../constants/config';
import { API_CONFIG } from '../../constants/config';
import ProfilePlaceholder from '../../components/ProfilePlaceholder';
import EnhancedLanguageSwitcher from '../../components/EnhancedLanguageSwitcher';
import { motion } from 'framer-motion';
import AnimatedLogo from '../../components/AnimatedLogo';

const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    if (imagePath.startsWith('http')) return imagePath;
    return `${API_CONFIG.BASE_URL}/images/${imagePath}`;
};

const MenuItem = ({ title, icon, to, onClick }) => {
    const { t } = useTranslation();
    return (
        <NavLink
            to={to}
            onClick={onClick}
            className={({ isActive }) => `
                flex flex-row items-center mb-2 p-3 rounded-lg transition-all duration-200 ease-in-out
                ${isActive 
                    ? 'bg-white/20 text-white shadow-lg backdrop-blur-sm' 
                    : 'text-white/80 hover:bg-white/10 hover:text-white hover:translate-x-1'
                }
            `}
        >
            {({ isActive }) => (
                <>
                    <div className={`w-10 h-10 rounded-lg flex items-center justify-center ${isActive ? 'bg-white/20' : 'bg-white/5'}`}>
                        <img className="w-5 h-5 object-contain" src={`../images/${icon}`} alt={t(title)} />
                    </div>
                    <p className="font-medium mx-3 text-sm lg:text-base">{t(title)}</p>
                </>
            )}
        </NavLink>
    );
};

const Header = ({ onMenuClick, isMobileMenuOpen }) => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.language === 'ar';
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
        navigate('/login');
    };

    return (
        <header className="bg-white/80 backdrop-blur-md shadow-sm border-b border-gray-100">
            <div className="flex items-center justify-between h-16 px-4 md:px-6">
                {/* Left Section */}
                <div className="flex items-center gap-4">
                    <button
                        onClick={onMenuClick}
                        className="p-2 rounded-lg text-gray-500 hover:bg-gray-100 md:hidden"
                    >
                        {isMobileMenuOpen ? (
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        ) : (
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        )}
                    </button>
                    <div className="relative">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder={t('common.search')}
                            className={`w-full md:w-[300px] h-10 pl-10 pr-4 rounded-lg bg-gray-50 border border-gray-200 
                                focus:bg-white focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 
                                transition-all duration-200 placeholder-gray-400 text-gray-600`}
                        />
                        <svg
                            className={`absolute top-1/2 ${isRTL ? 'right-3' : 'left-3'} -translate-y-1/2 w-4 h-4 text-gray-400`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                </div>

                {/* Right Section */}
                <div className="flex items-center gap-3">
                    <EnhancedLanguageSwitcher />
                    
                    {/* Notifications */}
                    <button className="p-2 rounded-lg text-gray-500 hover:bg-gray-100 relative">
                        <span className="absolute top-1.5 right-1.5 w-2 h-2 bg-red-500 rounded-full animate-pulse"></span>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                    </button>

                    {/* Profile Dropdown */}
                    <div className="relative">
                        <button
                            onClick={() => setIsProfileOpen(!isProfileOpen)}
                            className="flex items-center gap-2 p-2 rounded-lg hover:bg-gray-100"
                        >
                            <ProfilePlaceholder size={32} />
                            <span className="hidden md:inline text-sm font-medium text-gray-700">
                                {t('common.profile')}
                            </span>
                        </button>

                        {isProfileOpen && (
                            <div className="absolute top-full right-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-gray-100 py-1">
                                <button
                                    onClick={handleLogout}
                                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50 flex items-center gap-2"
                                >
                                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                    </svg>
                                    {t('common.logout')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default function MarketDashboard() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const isRTL = i18n.language === 'ar';

    const menuItems = [
        { title: 'marketDashboard.menu.dashboard', icon: "dashboard.png", path: '/market/home' },
        { title: 'marketDashboard.menu.orderManagement', icon: "orders.png", path: '/market/orders' },
        { title: 'marketDashboard.menu.drivers', icon: "users.png", path: '/market/drivers' },
        { title: 'marketDashboard.menu.productList', icon: "products.png", path: '/market/products' },
        { title: 'marketDashboard.menu.addProduct', icon: "category.png", path: '/market/add-product' },
        { title: 'marketDashboard.menu.settings', icon: "settings.png", path: '/market/settings' }
    ];

    useEffect(() => {
        const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
        if (!token) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div className="min-h-screen flex bg-gray-50/50">
            {/* Sidebar */}
            <div className={`
                fixed top-0 ${isRTL ? 'right-0' : 'left-0'} h-screen w-[300px] 
                bg-gradient-to-b from-[#1a237e] to-[#283593]
                transform transition-transform duration-300 ease-in-out z-30 
                ${isMobileMenuOpen ? 'translate-x-0' : `${isRTL ? 'translate-x-full' : '-translate-x-full'} md:translate-x-0`}
            `}>
                <div className="flex flex-col h-full">
                    <div className="p-4 mb-6">
                        <AnimatedLogo />
                    </div>
                    <div className="flex-1 px-4 overflow-y-auto menu-container">
                        {menuItems.map((item, index) => (
                            <MenuItem
                                key={index}
                                title={item.title}
                                icon={item.icon}
                                to={item.path}
                                onClick={() => setIsMobileMenuOpen(false)}
                            />
                        ))}
                    </div>
                    <div className="p-4">
                        <button
                            onClick={() => {
                                setIsMobileMenuOpen(false);
                                navigate('/login');
                            }}
                            className="w-full flex items-center p-3 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-all duration-200"
                        >
                            <div className="w-10 h-10 rounded-lg bg-white/5 flex items-center justify-center">
                                <img className="w-5 h-5" src="../images/logout.png" alt={t('common.logout')} />
                            </div>
                            <span className={`${isRTL ? 'mr-3' : 'ml-3'} font-medium`}>{t('common.logout')}</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className={`flex-1 ${isRTL ? 'md:mr-[300px]' : 'md:ml-[300px]'}`}>
                <div className="fixed top-0 right-0 left-0 z-10">
                    <Header onMenuClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} isMobileMenuOpen={isMobileMenuOpen} />
                </div>
                <div className="mt-16 min-h-[calc(100vh-4rem)] p-4 md:p-6 w-full">
                    <Outlet />
                </div>
            </div>

            {/* Mobile menu overlay */}
            {isMobileMenuOpen && (
                <div
                    className="fixed inset-0 bg-black/50 backdrop-blur-sm z-20 md:hidden"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}
        </div>
    );
}