import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import SearchableSelect from '../components/SearchableSelect';
import { API_CONFIG } from '../constants/config';

export default function Products() {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 12;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    nameEng: '',
    nameAr: '',
    descEng: '',
    descAr: '',
    quantity: '',
    price: '',
    fieldId: '',
    categoryId: '',
    marketId: '',
    status: '1',
    rate: 0,
    discount: 0
  });
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [fields, setFields] = useState([]);
  const [filters, setFilters] = useState({
    marketId: '',
    categoryId: '',
    fieldId: '',
    status: '',
    minPrice: '',
    maxPrice: ''
  });

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}/images/${imagePath}`;
  };

  // Fetch markets
  const fetchMarkets = async () => {
    try {
      const response = await api.get('/api/market-management', {
        params: {
          pageSize: 50
        },
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setMarkets(response.data.data.markets || []);
      }
    } catch (error) {
      console.error('Error fetching markets:', error);
    }
  };

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await api.get('/api/category-management', {
        params: {
          pageSize: 50
        },
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setCategories(response.data.data.categories || []);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch fields
  const fetchFields = async () => {
    try {
      const response = await api.get('/api/field-management', {
        params: {
          pageSize: 50
        },
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setFields(response.data.data.fields || []);
      }
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  // Fetch products with filters and pagination
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const queryParams = {
        pageNumber: currentPage,
        pageSize: pageSize,
        search: searchTerm.trim(),
        ...filters
      };

      const response = await api.get('/api/product-management', {
        params: queryParams,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setProducts(response.data.data.products || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error(response.data?.message || 'Failed to fetch products');
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image size should not exceed 5MB');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Reset form
  const resetForm = () => {
    setFormData({
      nameEng: '',
      nameAr: '',
      descEng: '',
      descAr: '',
      quantity: '',
      price: '',
      fieldId: '',
      categoryId: '',
      marketId: '',
      status: '1',
      rate: 0,
      discount: 0
    });
    setSelectedImage(null);
    setImagePreview(null);
    setIsModalOpen(false);
  };

  // Open create modal
  const openCreateModal = () => {
    setModalType('add');
    resetForm();
    setIsModalOpen(true);
  };

  // Open update modal
  const openUpdateModal = (product) => {
    setModalType('update');
    setFormData({
      nameEng: product.nameEng,
      nameAr: product.nameAr,
      descEng: product.descEng,
      descAr: product.descAr,
      quantity: product.quantity,
      price: product.price,
      fieldId: product.fieldId,
      categoryId: product.categoryId,
      marketId: product.marketId,
      status: product.status.toString(),
      rate: product.rate,
      discount: product.discount
    });
    setImagePreview(getImageUrl(product.imageUrl));
    setIsModalOpen(true);
  };

  // Open delete dialog
  const openDeleteDialog = (product) => {
    setProductToDelete(product);
    setDeleteDialogOpen(true);
  };

  // Close delete dialog
  const closeDeleteDialog = () => {
    setProductToDelete(null);
    setDeleteDialogOpen(false);
    setDeleteLoading(false);
  };

  // Handle create product
  const handleCreateProduct = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
      if (selectedImage) {
        formDataToSend.append('image', selectedImage);
      }

      const response = await api.post('/api/product-management', formDataToSend, {
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setError('');
        resetForm();
        fetchProducts();
      } else {
        throw new Error(response.data?.message || 'Failed to create product');
      }
    } catch (error) {
      console.error('Error creating product:', error);
      setError(error.message || 'Failed to create product');
    }
  };

  // Handle update product
  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      const updatableFields = ['nameEng', 'nameAr', 'descEng', 'descAr', 'quantity', 'price', 'status', 'rate', 'discount'];
      updatableFields.forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
      if (selectedImage) {
        formDataToSend.append('image', selectedImage);
      }

      const response = await api.put(`/api/product-management/${selectedProduct.id}`, formDataToSend, {
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setError('');
        resetForm();
        fetchProducts();
      } else {
        throw new Error(response.data?.message || 'Failed to update product');
      }
    } catch (error) {
      console.error('Error updating product:', error);
      setError(error.message || 'Failed to update product');
    }
  };

  // Handle delete product
  const handleDelete = async () => {
    if (!productToDelete) return;
    
    try {
      setDeleteLoading(true);
      const response = await api.delete(`/api/product-management/${productToDelete.id}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (response.status === 204 || response.data?.status) {
        setError('');
        closeDeleteDialog();
        await fetchProducts();
      } else {
        throw new Error(response.data?.message || 'Failed to delete product');
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 401:
            setError('You need to be logged in to delete this product.');
            break;
          case 403:
            setError('You do not have permission to delete this product.');
            break;
          case 404:
            setError('Product not found. It may have already been deleted.');
            break;
          default:
            setError(error.response?.data?.message || 'Failed to delete product');
        }
      } else {
        setError(error.message || 'An error occurred while deleting the product');
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handle filter change
  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const resetFilters = () => {
    setFilters({
      marketId: '',
      categoryId: '',
      fieldId: '',
      status: '',
      minPrice: '',
      maxPrice: ''
    });
  };

  const applyFilters = () => {
    fetchProducts();
  };

  useEffect(() => {
    fetchMarkets();
    fetchCategories();
    fetchFields();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [currentPage, searchTerm, filters]);

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6 transition-all duration-300 hover:shadow-md">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
          <h1 className="text-xl font-semibold text-gray-800">{t('products.title')}</h1>
          <div className="flex flex-col md:flex-row gap-3 md:items-center">
            <div className="relative">
              <input
                type="text"
                placeholder={t('products.search.placeholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
              />
              <svg
                className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <button
              onClick={() => {
                setModalType('add');
                setIsModalOpen(true);
              }}
              className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105 space-x-2"
            >
              <svg
                className="w-5 h-5 animate-pulse"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <span>{t('products.actions.add')}</span>
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="mb-6">
          <div className="flex items-center mb-4 text-gray-600">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
            </svg>
            <span className="text-sm font-medium ml-2">{t('products.filters.title')}</span>
          </div>
          <div className="flex flex-wrap gap-3">
            <SearchableSelect
              value={filters.categoryId}
              onChange={(value) => handleFilterChange('categoryId', value)}
              options={categories.map(cat => ({
                value: cat.id,
                label: `${cat.nameAr} (${cat.nameEng})`
              }))}
              placeholder={t('products.filters.category')}
              className="min-w-[200px]"
            />

            <SearchableSelect
              value={filters.fieldId}
              onChange={(value) => handleFilterChange('fieldId', value)}
              options={fields.map(field => ({
                value: field.id,
                label: `${field.nameAr} (${field.nameEng})`
              }))}
              placeholder={t('products.filters.field')}
              className="min-w-[200px]"
            />

            <SearchableSelect
              value={filters.marketId}
              onChange={(value) => handleFilterChange('marketId', value)}
              options={markets.map(market => ({
                value: market.id,
                label: market.titleEng
              }))}
              placeholder={t('products.filters.store')}
              className="min-w-[200px]"
            />

            <select
              value={filters.status}
              onChange={(e) => handleFilterChange('status', e.target.value)}
              className="min-w-[150px] px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
            >
              <option value="">{t('products.filters.all')}</option>
              <option value="1">{t('products.filters.active')}</option>
              <option value="2">{t('products.filters.inactive')}</option>
            </select>

            <div className="flex gap-2">
              <div>
                <input
                  type="number"
                  placeholder={t('products.filters.price.min')}
                  value={filters.minPrice}
                  onChange={(e) => handleFilterChange('minPrice', e.target.value)}
                  className="w-24 px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                />
              </div>
              <div>
                <input
                  type="number"
                  placeholder={t('products.filters.price.max')}
                  value={filters.maxPrice}
                  onChange={(e) => handleFilterChange('maxPrice', e.target.value)}
                  className="w-24 px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                />
              </div>
            </div>

            <button
              onClick={resetFilters}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors duration-200"
            >
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
              {t('products.filters.reset')}
            </button>

            <button
              onClick={applyFilters}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-lg transition-colors duration-200"
            >
              {t('products.filters.apply')}
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-3 mb-4 animate-fadeIn">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : (
          <div className="bg-white p-4 rounded-lg shadow-sm overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('products.table.image')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('products.table.name')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('products.table.price')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('products.table.discount')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('products.table.store')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('products.table.action')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {products.map((product) => (
                  <tr key={product.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="h-12 w-12 rounded-lg overflow-hidden bg-gray-100">
                        <img
                          src={getImageUrl(product.imageUrl)}
                          alt={product.nameEng}
                          className="h-full w-full object-cover"
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{product.nameEng}</div>
                      <div className="text-sm text-gray-500">{product.nameAr}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-sm text-gray-900">${product.price.toFixed(2)}</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {product.discount > 0 && (
                        <span className="text-sm text-red-600">{product.discount}%</span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="text-sm text-gray-900">
                        {markets.find(m => m.id === product.marketId)?.nameEng || '-'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => openUpdateModal(product)}
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => openDeleteDialog(product)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4 bg-white p-4 rounded-lg shadow-sm">
        <div className="flex items-center">
          <span className="text-sm text-gray-700">
            {t('products.pagination.showing')} {products.length} {t('products.pagination.of')} {totalCount} {t('products.pagination.results')}
          </span>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(totalCount / pageSize)}
          onPageChange={handlePageChange}
        />
      </div>

      {/* Add/Edit Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={resetForm}
        title={modalType === 'add' ? t('products.form.addTitle') : t('products.form.editTitle')}
      >
        <form onSubmit={modalType === 'add' ? handleCreateProduct : handleUpdateProduct} className="p-6">
          <div className="space-y-6">
            {/* Basic Information */}
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">{t('products.form.basicInformation')}</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.name.en')}</label>
                  <input
                    type="text"
                    name="nameEng"
                    value={formData.nameEng}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.name.ar')}</label>
                  <input
                    type="text"
                    name="nameAr"
                    value={formData.nameAr}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>

                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.description.en')}</label>
                  <textarea
                    name="descEng"
                    value={formData.descEng}
                    onChange={handleInputChange}
                    rows={3}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>

                <div className="md:col-span-2">
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.description.ar')}</label>
                  <textarea
                    name="descAr"
                    value={formData.descAr}
                    onChange={handleInputChange}
                    rows={3}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.price')}</label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.quantity')}</label>
                  <input
                    type="number"
                    name="quantity"
                    value={formData.quantity}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.category')}</label>
                  <SearchableSelect
                    name="categoryId"
                    value={formData.categoryId}
                    onChange={handleInputChange}
                    options={categories.map(cat => ({
                      value: cat.id,
                      label: `${cat.nameAr} (${cat.nameEng})`
                    }))}
                    placeholder={t('products.form.fields.selectCategory')}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.field')}</label>
                  <SearchableSelect
                    name="fieldId"
                    value={formData.fieldId}
                    onChange={handleInputChange}
                    options={fields.map(field => ({
                      value: field.id,
                      label: `${field.nameAr} (${field.nameEng})`
                    }))}
                    placeholder={t('products.form.fields.selectField')}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.store')}</label>
                  <SearchableSelect
                    name="marketId"
                    value={formData.marketId}
                    onChange={handleInputChange}
                    options={markets.map(market => ({
                      value: market.id,
                      label: market.titleEng
                    }))}
                    placeholder={t('products.form.fields.selectStore')}
                  />
                </div>

                {modalType === 'update' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.status')}</label>
                    <select
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    >
                      <option value="1">{t('products.form.fields.active')}</option>
                      <option value="2">{t('products.form.fields.inactive')}</option>
                    </select>
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.rate')}</label>
                  <input
                    type="number"
                    name="rate"
                    value={formData.rate}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">{t('products.form.fields.discount')}</label>
                  <input
                    type="number"
                    name="discount"
                    value={formData.discount}
                    onChange={handleInputChange}
                    className="mt-1 block w-full px-3 py-2 border border-gray-200 rounded-lg text-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Image Upload */}
            <div>
              <h4 className="text-sm font-medium text-gray-900 mb-4">{t('products.form.image')}</h4>
              {!imagePreview ? (
                <div className="relative">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    id="image-upload"
                    required={modalType === 'add'}
                  />
                  <label
                    htmlFor="image-upload"
                    className="group flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:border-indigo-500 hover:bg-gray-50 transition-all duration-300"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6 space-y-3">
                      <svg className="w-12 h-12 text-gray-400 group-hover:text-indigo-500 transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                      <div className="flex flex-col items-center">
                        <p className="text-sm text-gray-600 group-hover:text-indigo-500 transition-colors duration-300">
                          <span className="font-medium">{t('products.form.upload')}</span> {t('products.form.or')} {t('products.form.drag')}
                        </p>
                        <p className="text-xs text-gray-500">{t('products.form.imageSize')}</p>
                      </div>
                    </div>
                  </label>
                </div>
              ) : (
                <div className="relative rounded-lg overflow-hidden group">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-full h-64 object-cover"
                  />
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-all duration-300">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedImage(null);
                        setImagePreview(null);
                      }}
                      className="p-2 bg-white/10 backdrop-blur-sm rounded-full text-white hover:bg-white/20 transform hover:scale-110 transition-all duration-300"
                    >
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Form Actions */}
          <div className="mt-8 flex items-center justify-end space-x-4">
            <button
              type="button"
              onClick={resetForm}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors duration-200"
            >
              {t('products.actions.cancel')}
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-sm font-medium rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105"
            >
              {modalType === 'add' ? t('products.actions.create') : t('products.actions.update')}
            </button>
          </div>
        </form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={deleteDialogOpen}
        onClose={closeDeleteDialog}
        title={t('products.delete.title')}
      >
        <div className="p-6">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900">{t('products.delete.confirm')}</h3>
            <p className="mt-2 text-sm text-gray-500">
              {t('products.delete.message')}
            </p>
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <button
              onClick={closeDeleteDialog}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors duration-200"
              disabled={deleteLoading}
            >
              {t('products.actions.cancel')}
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-red-600 text-white text-sm font-medium rounded-lg hover:bg-red-700 transition-all duration-300 disabled:opacity-50"
              disabled={deleteLoading}
            >
              {deleteLoading ? t('products.delete.deleting') : t('products.actions.delete')}
            </button>
          </div>

          {error && (
            <div className="mt-4 bg-red-50 border-l-4 border-red-400 p-3 rounded-lg">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-600">{error}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}