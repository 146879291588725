import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import SearchableSelect from './SearchableSelect';
import api from '../services/api';
import toast from './Toast';

const marketTypes = [
    { value: 'supermarket', label: 'marketRegistration.marketTypes.supermarket' },
    { value: 'hypermarket', label: 'marketRegistration.marketTypes.hypermarket' },
    { value: 'grocery', label: 'marketRegistration.marketTypes.grocery' },
    { value: 'convenience', label: 'marketRegistration.marketTypes.convenience' },
    { value: 'specialty', label: 'marketRegistration.marketTypes.specialty' }
];

const steps = [
    {
        title: 'marketRegistration.steps.basicInfo',
        description: 'marketRegistration.steps.basicInfoDesc',
        fields: ['titleAr', 'titleEng', 'descAr', 'descEng', 'fieldId']
    },
    {
        title: 'marketRegistration.steps.contactInfo',
        description: 'marketRegistration.steps.contactInfoDesc',
        fields: ['nameOwner', 'email', 'phoneNumber', 'password', 'addressName']
    },
    {
        title: 'marketRegistration.steps.documents',
        description: 'marketRegistration.steps.documentsDesc',
        fields: ['logoImage', 'commercialImage']
    }
];

const slideVariants = {
    enter: (direction) => ({
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
    }),
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => ({
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
    })
};

const StepIndicator = ({ currentStep, isStepValid }) => {
    const { t } = useTranslation();
    return (
        <div className="mb-8">
            <div className="flex justify-between mb-2">
                <span className="text-sm font-medium text-gray-700">{t('marketRegistration.progress')}</span>
                <span className="text-sm font-medium text-gray-700">{Math.round((currentStep / steps.length) * 100)}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                    className="bg-purple-600 h-2.5 rounded-full transition-all duration-300 ease-in-out"
                    style={{ width: `${(currentStep / steps.length) * 100}%` }}
                />
            </div>
            <div className="flex justify-between mt-2">
                {steps.map((step, index) => (
                    <div 
                        key={index}
                        className={`text-xs ${
                            currentStep > index 
                                ? 'text-purple-600 font-medium' 
                                : currentStep === index 
                                    ? isStepValid 
                                        ? 'text-green-600 font-medium' 
                                        : 'text-gray-900' 
                                    : 'text-gray-500'
                        }`}
                    >
                        {t(step.title)}
                    </div>
                ))}
            </div>
        </div>
    );
};

const ImagePreview = ({ src, alt, onRemove }) => (
    <div className="relative w-16 h-16 group">
        <img
            src={src}
            alt={alt}
            className="w-full h-full object-cover rounded-lg transition-all duration-300 group-hover:opacity-75"
        />
        <button
            type="button"
            onClick={onRemove}
            className="absolute -top-1 -right-1 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
        >
            <svg className="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-10 transition-all duration-300 rounded-lg" />
    </div>
);

const FileInput = ({ name, label, value, preview, accept, onChange }) => {
    const { t } = useTranslation();
    return (
        <div>
            <label className="block text-start mb-2 text-sm font-medium text-gray-700">
                {t(label)}
            </label>
            <div className="flex items-center space-x-4">
                <div className="relative flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                    </div>
                    <div className="relative group">
                        <input
                            type="file"
                            id={name}
                            name={name}
                            onChange={onChange}
                            accept={accept}
                            className="sr-only"
                            required
                        />
                        <label
                            htmlFor={name}
                            className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-purple-600 focus-within:border-transparent"
                        >
                            <span className="text-sm text-gray-600">
                                {preview ? t('marketRegistration.buttons.changeFile') : t('marketRegistration.buttons.clickToUpload')}
                            </span>
                        </label>
                    </div>
                </div>
                {preview && <ImagePreview src={preview} alt={t(label)} onRemove={() => {
                    onChange({
                        target: {
                            name,
                            value: null,
                            type: 'file'
                        }
                    });
                }} />}
            </div>
        </div>
    );
};

const SuccessPage = ({ onBackToLogin }) => {
    const { t } = useTranslation();
    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="text-center py-8"
        >
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
            </div>
            <h2 className="mt-6 text-2xl font-semibold text-gray-900">{t('marketRegistration.success.title')}</h2>
            <p className="mt-2 text-sm text-gray-600">
                {t('marketRegistration.success.message')}
            </p>
            <div className="mt-6">
                <button
                    onClick={onBackToLogin}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                    <svg className="mr-2 -ml-1 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
                    </svg>
                    {t('marketRegistration.success.backToLogin')}
                </button>
            </div>
        </motion.div>
    );
};

export default function MarketRegistration({ onClose }) {
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fields, setFields] = useState([]);
    const [formData, setFormData] = useState({
        fieldId: '',
        titleAr: '',
        titleEng: '',
        descAr: '',
        descEng: '',
        nameOwner: '',
        email: '',
        phoneNumber: '',
        password: '',
        addressName: '',
        lat: 0,
        lng: 0,
        discount: 0,
        logoImage: null,
        commercialImage: null
    });

    useEffect(() => {
        fetchFields();
    }, []);

    const fetchFields = async () => {
        try {
            const response = await api.get('/api/field-management', {
                params: {
                    pageNumber: 1,
                    pageSize: 50
                }
            });

            if (response.data?.status) {
                const fieldsData = response.data.data.fields || [];
                setFields(fieldsData.map(field => ({
                    value: field.id,
                    label: field.name || field.nameEng || field.id // Fallback if name is not available
                })));
            }
        } catch (error) {
            console.error('Error fetching fields:', error);
            toast.error('Failed to load fields');
            setFields([]); // Ensure fields is always an array
        }
    };

    const validateStep = useCallback((stepIndex) => {
        const stepFields = steps[stepIndex].fields;
        const newErrors = [];
        
        stepFields.forEach(field => {
            if (!formData[field]) {
                newErrors.push(`${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')} is required`);
            }
            
            if (field === 'email' && formData[field] && !/\S+@\S+\.\S+/.test(formData[field])) {
                newErrors.push('Please enter a valid email address');
            }
            
            if (field === 'phoneNumber' && formData[field] && !/^\+?[\d\s-]{10,}$/.test(formData[field])) {
                newErrors.push('Please enter a valid phone number');
            }
        });

        return newErrors;
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        
        if (type === 'file' && files?.[0]) {
            const file = files[0];
            const reader = new FileReader();
            
            reader.onloadend = () => {
                setFormData(prev => ({
                    ...prev,
                    [name]: file,
                    [`${name}Preview`]: reader.result
                }));
            };
            
            if (file.type.startsWith('image/')) {
                reader.readAsDataURL(file);
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
        
        setErrors([]);
    };

    const handleNextStep = () => {
        const newErrors = validateStep(step - 1);
        if (newErrors.length === 0) {
            setStep(step + 1);
        } else {
            setErrors(newErrors);
        }
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
        setErrors([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateStep(steps.length - 1);
        if (newErrors.length === 0) {
            try {
                setLoading(true);
                const formDataToSend = new FormData();
                
                // Append all text fields
                Object.keys(formData).forEach(key => {
                    if (!key.endsWith('Preview') && formData[key] !== null) {
                        formDataToSend.append(key, formData[key]);
                    }
                });

                // Add lat, lng, and discount if not present in form
                if (!formData.hasOwnProperty('lat')) formDataToSend.append('lat', 0);
                if (!formData.hasOwnProperty('lng')) formDataToSend.append('lng', 0);
                if (!formData.hasOwnProperty('discount')) formDataToSend.append('discount', 0);

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                };

                await api.post('/api/market-management', formDataToSend, config);
                setIsSuccess(true);
                toast.success('Market registered successfully');
            } catch (error) {
                console.error('Error:', error);
                setErrors([error.response?.data?.message || 'Registration failed. Please try again.']);
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(newErrors);
        }
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="space-y-4">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 0h8v12H6V4z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <SearchableSelect
                                name="fieldId"
                                value={formData.fieldId}
                                onChange={handleInputChange}
                                options={fields}
                                placeholder={t('marketRegistration.fields.fieldId')}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </div>
                            <input
                                name="titleAr"
                                type="text"
                                value={formData.titleAr}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.titleAr')}
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </div>
                            <input
                                name="titleEng"
                                type="text"
                                value={formData.titleEng}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.titleEng')}
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute top-3 left-0 pl-3 flex items-start pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <textarea
                                name="descAr"
                                value={formData.descAr}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.descAr')}
                                rows="3"
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute top-3 left-0 pl-3 flex items-start pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <textarea
                                name="descEng"
                                value={formData.descEng}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.descEng')}
                                rows="3"
                                required
                            />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="space-y-4">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <input
                                name="nameOwner"
                                type="text"
                                value={formData.nameOwner}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.nameOwner')}
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                </svg>
                            </div>
                            <input
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.email')}
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                </svg>
                            </div>
                            <input
                                name="phoneNumber"
                                type="tel"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.phoneNumber')}
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <input
                                name="password"
                                type="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.password')}
                                required
                            />
                        </div>

                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <input
                                name="addressName"
                                type="text"
                                value={formData.addressName}
                                onChange={handleInputChange}
                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                placeholder={t('marketRegistration.fields.addressName')}
                                required
                            />
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="space-y-6">
                        <FileInput
                            name="logoImage"
                            label="marketRegistration.fields.logoImage"
                            value={formData.logoImage}
                            preview={formData.logoImagePreview}
                            accept="image/*"
                            onChange={handleInputChange}
                        />

                        <FileInput
                            name="commercialImage"
                            label="marketRegistration.fields.commercialImage"
                            value={formData.commercialImage}
                            preview={formData.commercialImagePreview}
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    if (isSuccess) {
        return <SuccessPage onBackToLogin={onClose} />;
    }

    return (
        <div>
            <StepIndicator currentStep={step} isStepValid={errors.length === 0} />
            <form onSubmit={handleSubmit} className="space-y-6">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={step}
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        className="min-h-[400px]"
                    >
                        {renderStep()}
                    </motion.div>
                </AnimatePresence>

                {errors.length > 0 && (
                    <div className="mt-4 p-4 bg-red-50 rounded-lg">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">
                                    {t('marketRegistration.validation.errors')}
                                </h3>
                                <div className="mt-2 text-sm text-red-700">
                                    <ul className="list-disc pl-5 space-y-1">
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex justify-between pt-4">
                    <button
                        type="button"
                        onClick={handlePreviousStep}
                        className={`px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 ${
                            step === 1 ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={step === 1}
                    >
                        {t('marketRegistration.buttons.previous')}
                    </button>
                    
                    {step < 3 ? (
                        <button
                            type="button"
                            onClick={handleNextStep}
                            className="px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            {t('marketRegistration.buttons.next')}
                        </button>
                    ) : (
                        <button
                            type="submit"
                            className="px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                        >
                            {t('marketRegistration.buttons.submit')}
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}
