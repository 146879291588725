export const API_CONFIG = {
    //   BASE_URL: 'http://localhost:5030',
     BASE_URL: 'https://api.ajeeb.sa',
};

export const ENDPOINTS = {
    ADMIN_LOGIN: '/admin-login',
    ADMIN_REGISTER: '/admin-signup',
    MARKET_LOGIN: '/market-login',
};

export const STORAGE_KEYS = {
    TOKEN: 'token',
    USER: 'user',
    MARKET: 'market',
    TOKEN_EXPIRATION: 'tokenExpiration',
    USER_ROLE: 'userRole',
};

export const ROLES = {
    ADMIN: 'admin',
    MARKET: 'market',
};

export const GENDER_OPTIONS = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
];
