import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PageTransition from '../../components/PageTransition';
import { API_CONFIG } from '../../constants/config';

const Home = () => {
    // Initialize with empty arrays
    const [fields, setFields] = useState([]);
    const [products, setProducts] = useState([]);
    const [stores, setStores] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [activeProduct, setActiveProduct] = useState(null);
    const [activeTab, setActiveTab] = useState('all');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`${API_CONFIG.BASE_URL}/home/website-home`);
                const data = await response.json();
                
                if (data.fields) {
                    setFields(data.fields.map(field => ({
                        id: field.id,
                        name: field.nameEng,
                        image: `${API_CONFIG.BASE_URL}/images/${field.imageUrl}`,
                        count: "200+ stores"
                    })));
                }

                if (data.topRatedProducts) {
                    setProducts(data.topRatedProducts.map(product => ({
                        id: product.id,
                        name: product.nameEng,
                        price: product.price,
                        originalPrice: product.price + (product.price * product.discount / 100),
                        rating: product.rate,
                        reviews: product.reviews,
                        image: `${API_CONFIG.BASE_URL}/images/${product.imageUrl}`,
                        location: "Fresh Market",
                        discount: product.discount
                    })));
                }

                if (data.topRatedStores) {
                    setStores(data.topRatedStores.map(store => ({
                        id: store.id,
                        name: store.titleEng,
                        category: store.descEng,
                        rating: store.rate || 0,
                        reviews: store.orderCount || 0,
                        image: `${API_CONFIG.BASE_URL}/images/${store.imageLogo}`,
                        deliveryTime: store.timeDelvary || "15-20",
                        minOrder: store.minimum || "$20",
                        featured: true,
                        isOpen: store.status === 1,
                        distance: store.distance || "1.2"
                    })));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const filteredStores = activeTab === 'all' 
        ? stores 
        : stores.filter(store => store.category.toLowerCase() === activeTab);

    const stats = [
        { number: "1000+", label: "Orders" },
        { number: "1000+", label: "Users" },
        { number: "4.8 ★", label: "Rating" }
    ];

    const featuredRef = useRef(null);
    const storesRef = useRef(null);

    const scroll = (direction, ref) => {
        const container = ref.current;
        const scrollAmount = 300;
        container.scrollBy({
            left: direction === 'left' ? -scrollAmount : scrollAmount,
            behavior: 'smooth'
        });
    };

    // Add to cart function
    const handleAddToCart = (productId, quantity) => {
        console.log(`Added product ${productId} with quantity ${quantity} to cart`);
        setActiveProduct(null);
    };

    // Quantity change handler
    const handleQuantityChange = (productId, newQuantity) => {
        setQuantities(prev => ({
            ...prev,
            [productId]: Math.max(1, Math.min(99, newQuantity))
        }));
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
            </div>
        );
    }

    return (
        <PageTransition>
            <div className="space-y-12">
                {/* Hero and Stats Section */}
                <div className="relative mb-16">
                    {/* Hero Section */}
                    <div className="relative h-[400px] rounded-3xl overflow-hidden">
                        <img 
                            src="https://images.unsplash.com/photo-1534723452862-4c874018d66d?q=80&w=1000&auto=format&fit=crop" 
                            alt="Supermarket" 
                            className="w-full h-full object-cover"
                        />
                        <div className="absolute text-start inset-0 bg-gradient-to-r from-black/70 to-black/50 flex flex-col justify-center px-8 md:px-16">
                            <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                                The best products in<br />our stores
                            </h1>
                            <p className="text-lg text-gray-200 mb-8">
                                It's easy to buy widgets safely and reliably, of course at a low price.
                            </p>
                            <div className="flex space-x-4">
                                <Link 
                                    to="/user/products"
                                    className="bg-white text-gray-800 px-6 py-2 rounded-lg font-medium hover:bg-gray-100 transition-colors"
                                >
                                    Learn More
                                </Link>
                                <Link 
                                    to="/user/products"
                                    className="bg-purple-700 text-white px-6 py-2 rounded-lg font-medium hover:bg-purple-800 transition-colors"
                                >
                                    Get Started
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Stats Section */}
                    <div className="absolute left-1/2 -translate-x-1/2 bottom-0 translate-y-1/2 w-full max-w-4xl">
                        <div className="bg-purple-700 rounded-2xl p-6 mx-4 shadow-lg">
                            <div className="grid grid-cols-3 gap-4">
                                {stats.map((stat, index) => (
                                    <div key={index} className="text-center text-white">
                                        <div className="text-2xl font-bold mb-1">{stat.number}</div>
                                        <div className="text-sm opacity-80">{stat.label}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Fields Section */}
                {fields.length > 0 && (
                    <div className="pt-10 ">
                        <div className="flex justify-between items-center items-center mb-6">
                            <div className="space-y-1 text-center">
                                <h2 className="text-2xl text-start font-bold">Fields</h2>
                                <p className="text-gray-500">Explore our store categories</p>
                            </div>
                            <Link to="/user/fields" className="text-purple-700 hover:text-purple-800 text-sm">
                                View All
                            </Link>
                        </div>
                        
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
                            {fields.map((field) => (
                                <div 
                                    key={field.id} 
                                    className="bg-gradient-to-br from-pink-50 to-purple-50 rounded-2xl overflow-hidden hover:shadow-md transition-all cursor-pointer group"
                                >
                                    <div className="relative h-36">
                                        <img 
                                            src={field.image} 
                                            alt={field.name}
                                            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                                        />
                                        <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                                        <div className="absolute bottom-4 left-4 text-white">
                                            <h3 className="font-medium text-lg mb-1">{field.name}</h3>
                                            <p className="text-sm text-gray-200">{field.count}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Featured Products Section */}
                {products.length > 0 && (
                    <div className="pt-8">
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-8">
                            <div>
                                <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">Featured Products</h2>
                                <p className="text-gray-500">Explore our top picks for you</p>
                            </div>
                            <div className="flex gap-2">
                                <button 
                                    onClick={() => scroll('left', featuredRef)}
                                    className="p-2 rounded-full bg-gray-100 hover:bg-purple-100 text-gray-600 hover:text-purple-600 transition-colors"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                    </svg>
                                </button>
                                <button 
                                    onClick={() => scroll('right', featuredRef)}
                                    className="p-2 rounded-full bg-gray-100 hover:bg-purple-100 text-gray-600 hover:text-purple-600 transition-colors"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        
                        <div ref={featuredRef} className="flex gap-6 overflow-x-auto scrollbar-hide scroll-smooth pb-6">
                            {products.map((product) => (
                                <div 
                                    key={product.id}
                                    className="flex-none w-[300px] group bg-[#F5E8FF] rounded-[32px] p-6 hover:shadow-xl transition-all duration-300 border-2 border-purple-100"
                                >
                                    {/* Image */}
                                    <div className="relative mb-4">
                                        <img 
                                            src={product.image} 
                                            alt={product.name}
                                            className="w-full aspect-square object-cover rounded-3xl"
                                        />
                                        {/* Discount Badge */}
                                        <div className="absolute top-4 left-4">
                                            <div className="flex items-center gap-1 bg-red-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                                                <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                                <span>{product.discount}% OFF</span>
                                            </div>
                                        </div>
                                        {/* Quick Add */}
                                        <div className="absolute -bottom-3 right-3">
                                            <div className="relative">
                                                <button 
                                                    onClick={() => setActiveProduct(activeProduct === product.id ? null : product.id)}
                                                    className="w-12 h-12 rounded-2xl bg-purple-600 text-white shadow-lg flex items-center justify-center hover:bg-purple-700 transition-colors"
                                                >
                                                    {activeProduct === product.id ? (
                                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    ) : (
                                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                                        </svg>
                                                    )}
                                                </button>
                                                
                                                {/* Quantity Selector Popover */}
                                                <div 
                                                    className={`absolute bottom-full right-0 mb-2 transition-all duration-300 ${
                                                        activeProduct === product.id ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible translate-y-2'
                                                    }`}
                                                >
                                                    <div className="bg-white rounded-2xl shadow-xl p-3 flex items-center gap-2">
                                                        <button 
                                                            onClick={() => handleQuantityChange(product.id, (quantities[product.id] || 1) - 1)}
                                                            className="w-8 h-8 rounded-xl bg-purple-50 text-purple-700 flex items-center justify-center hover:bg-purple-100 transition-colors"
                                                        >
                                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                                            </svg>
                                                        </button>
                                                        <span className="w-8 text-center font-semibold">
                                                            {quantities[product.id] || 1}
                                                        </span>
                                                        <button 
                                                            onClick={() => handleQuantityChange(product.id, (quantities[product.id] || 1) + 1)}
                                                            className="w-8 h-8 rounded-xl bg-purple-50 text-purple-700 flex items-center justify-center hover:bg-purple-100 transition-colors"
                                                        >
                                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                                            </svg>
                                                        </button>
                                                        <button 
                                                            onClick={() => handleAddToCart(product.id, quantities[product.id] || 1)}
                                                            className="w-8 h-8 rounded-xl bg-purple-600 text-white flex items-center justify-center hover:bg-purple-700 transition-colors ml-1"
                                                        >
                                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Product Info */}
                                    <div className="space-y-3">
                                        {/* Location */}
                                        <div className="flex items-center gap-2 text-gray-500">
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            <span className="text-sm">{product.location}</span>
                                        </div>

                                        {/* Title */}
                                        <h3 className="font-medium text-gray-900 text-lg">{product.name}</h3>

                                        {/* Rating */}
                                        <div className="flex items-center gap-2">
                                            <div className="flex items-center">
                                                <svg className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                </svg>
                                                <span className="text-sm font-medium ml-1">{product.rating}</span>
                                            </div>
                                            <span className="text-sm text-gray-500">({product.reviews} reviews)</span>
                                        </div>

                                        {/* Price */}
                                        <div className="flex items-end gap-2">
                                            <span className="text-2xl font-bold">${product.price}</span>
                                            <div className="flex items-center gap-1 text-red-500">
                                                <span className="text-sm line-through text-gray-400">${product.originalPrice}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {/* Featured Stores Section */}
                {stores.length > 0 && (
                    <div className="pt-16">
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-8">
                            <div>
                                <h2 className="text-3xl font-bold text-gray-900">Featured Stores</h2>
                                <p className="mt-1 text-gray-500">Shop from your favorite local stores</p>
                            </div>
                            <div className="flex gap-2">
                                <button 
                                    onClick={() => scroll('left', storesRef)}
                                    className="p-2 rounded-full bg-gray-100 hover:bg-purple-100 text-gray-600 hover:text-purple-600 transition-colors"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                    </svg>
                                </button>
                                <button 
                                    onClick={() => scroll('right', storesRef)}
                                    className="p-2 rounded-full bg-gray-100 hover:bg-purple-100 text-gray-600 hover:text-purple-600 transition-colors"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        {/* Stores Grid */}
                        <div ref={storesRef} className="flex gap-6 overflow-x-auto scrollbar-hide scroll-smooth pb-6">
                            {stores.map((store) => (
                                <Link 
                                    key={store.id}
                                    to={`/user/store/${store.id}`}
                                    className="group flex-none w-[300px]"
                                >
                                    <div className="relative bg-[#F5E8FF] rounded-2xl overflow-hidden">
                                        {/* Store Cover */}
                                        <div className="h-28 sm:h-32 bg-purple-50">
                                            <img 
                                                src={store.image} 
                                                alt={store.name}
                                                className="w-full h-full object-cover opacity-90 group-hover:opacity-100 transition-opacity"
                                            />
                                        </div>

                                        {/* Store Logo */}
                                        <div className="absolute top-16 sm:top-20 left-4">
                                            <div className="w-16 h-16 sm:w-20 sm:h-20 rounded-2xl overflow-hidden ring-4 ring-white shadow-md">
                                                <img 
                                                    src={store.image} 
                                                    alt={store.name}
                                                    className="w-full h-full object-cover"
                                                />
                                            </div>
                                        </div>

                                        {/* Store Info */}
                                        <div className="pt-12 sm:pt-14 p-4">
                                            <div className="flex items-center justify-between mb-2">
                                                <h3 className="text-base sm:text-lg font-semibold text-gray-900 group-hover:text-purple-600 transition-colors line-clamp-1">
                                                    {store.name}
                                                </h3>
                                                <div className="flex items-center gap-1 text-sm">
                                                    <svg className="w-4 h-4 text-yellow-400" fill="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                                    </svg>
                                                    <span className="font-medium">{store.rating}</span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-500 mb-3">{store.category}</p>
                                            
                                            {/* Visit Button */}
                                            <button className="w-full py-2 sm:py-2.5 rounded-xl bg-gray-50 text-gray-600 text-sm font-medium group-hover:bg-purple-600 group-hover:text-white transition-all">
                                                Visit Store
                                            </button>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}

                {/* Contact Section */}
                <div className="grid md:grid-cols-2 gap-10 items-start text-start pt-16">
                    <div className="space-y-6">
                        <div>
                            <h2 className="text-2xl font-bold mb-2">Get in Touch</h2>
                            <p className="text-gray-500">We'd love to hear from you. Our team is always here to help.</p>
                        </div>

                        <div className="space-y-4">
                            <div className="flex items-center gap-4 p-4 rounded-2xl bg-white border-2 border-purple-100">
                                <div className="w-12 h-12 rounded-xl bg-purple-50 text-purple-600 flex items-center justify-center flex-shrink-0">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                </div>
                                <div>
                                    <h3 className="font-medium mb-1">Email Us</h3>
                                    <p className="text-gray-500 text-sm">Our friendly team is here to help.</p>
                                    <a href="mailto:support@ajeeb.com" className="text-purple-600 font-medium hover:text-purple-700">support@ajeeb.com</a>
                                </div>
                            </div>

                            <div className="flex items-center gap-4 p-4 rounded-2xl bg-white border-2 border-purple-100">
                                <div className="w-12 h-12 rounded-xl bg-purple-50 text-purple-600 flex items-center justify-center flex-shrink-0">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                <div>
                                    <h3 className="font-medium mb-1">Office</h3>
                                    <p className="text-gray-500 text-sm">Come say hello at our office HQ.</p>
                                    <p className="text-purple-600 font-medium">100 Smith Street, Melbourne VIC 3000</p>
                                </div>
                            </div>

                            <div className="flex items-center gap-4 p-4 rounded-2xl bg-white border-2 border-purple-100">
                                <div className="w-12 h-12 rounded-xl bg-purple-50 text-purple-600 flex items-center justify-center flex-shrink-0">
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                    </svg>
                                </div>
                                <div>
                                    <h3 className="font-medium mb-1">Phone</h3>
                                    <p className="text-gray-500 text-sm">Mon-Fri from 8am to 5pm.</p>
                                    <a href="tel:+1234567890" className="text-purple-600 font-medium hover:text-purple-700">+1 (234) 567-890</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white p-6 rounded-[32px] border-2 border-purple-100">
                        <form className="space-y-6">
                            <div className="grid md:grid-cols-2 gap-6">
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                                        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="First Name"
                                        className="w-full pl-12 pr-4 py-3 bg-white rounded-2xl border-2 border-purple-100 focus:border-purple-500 focus:outline-none transition-colors"
                                    />
                                </div>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                                        <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        placeholder="Last Name"
                                        className="w-full pl-12 pr-4 py-3 bg-white rounded-2xl border-2 border-purple-100 focus:border-purple-500 focus:outline-none transition-colors"
                                    />
                                </div>
                            </div>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                                    <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                </div>
                                <input
                                    type="email"
                                    placeholder="Email Address"
                                    className="w-full pl-12 pr-4 py-3 bg-white rounded-2xl border-2 border-purple-100 focus:border-purple-500 focus:outline-none transition-colors"
                                />
                            </div>
                            <div className="relative">
                                <div className="absolute top-4 left-0 pl-4 flex items-start pointer-events-none">
                                    <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                                    </svg>
                                </div>
                                <textarea
                                    placeholder="Your Message"
                                    rows="4"
                                    className="w-full pl-12 pr-4 py-3 bg-white rounded-2xl border-2 border-purple-100 focus:border-purple-500 focus:outline-none transition-colors"
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-purple-600 text-white py-3 rounded-2xl hover:bg-purple-700 transition-colors flex items-center justify-center gap-2"
                            >
                                <span>Send Message</span>
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </button>
                        </form>
                    </div>
                </div>

                {/* Footer */}
                <footer className="mt-16 py-8 border-t border-gray-100">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div>
                            <h3 className="text-lg font-semibold mb-4">About Us</h3>
                            <p className="text-gray-500 text-sm leading-relaxed">
                                We connect you with the best local stores and products in your area.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                            <ul className="space-y-2">
                                <li><a href="#" className="text-gray-500 hover:text-purple-600 text-sm">Home</a></li>
                                <li><a href="#" className="text-gray-500 hover:text-purple-600 text-sm">Stores</a></li>
                                <li><a href="#" className="text-gray-500 hover:text-purple-600 text-sm">Products</a></li>
                                <li><a href="#" className="text-gray-500 hover:text-purple-600 text-sm">Contact</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Contact</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center gap-2 text-sm text-gray-500">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                    </svg>
                                    support@example.com
                                </li>
                                <li className="flex items-center gap-2 text-sm text-gray-500">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                    +1 234 567 890
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
                            <div className="flex items-center gap-4">
                                <a href="#" className="p-2 rounded-full bg-gray-50 text-gray-400 hover:bg-purple-600 hover:text-white transition-colors">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                                    </svg>
                                </a>
                                <a href="#" className="p-2 rounded-full bg-gray-50 text-gray-400 hover:bg-purple-600 hover:text-white transition-colors">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
                                    </svg>
                                </a>
                                <a href="#" className="p-2 rounded-full bg-gray-50 text-gray-400 hover:bg-purple-600 hover:text-white transition-colors">
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-1.38-.898.165-.42.359-1.065.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 pt-8 border-t border-gray-100">
                        <p className="text-center text-sm text-gray-500">
                            &copy; 2024 Your Company. All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        </PageTransition>
    );
};

export default Home;