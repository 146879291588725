import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import { API_CONFIG } from '../constants/config';
import Modal from '../components/Modal';
import toast from '../components/Toast';
import { Toaster } from 'react-hot-toast';
import MarketRegistration from '../components/MarketRegistration';

const getImageUrl = (imagePath) => {
    if (!imagePath) return '../images/store.jpg';
    if (imagePath.startsWith('http')) return imagePath;
    return `${API_CONFIG.BASE_URL}${imagePath}`;
};

export default function Stors() {
    const { t } = useTranslation();
    const [markets, setMarkets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [modalType, setModalType] = useState(null);
    const [selectedMarket, setSelectedMarket] = useState(null);

    useEffect(() => {
        fetchMarkets();
    }, [currentPage, searchTerm]);

    const fetchMarkets = async () => {
        try {
            setLoading(true);
            const queryParams = {
                PageNumber: currentPage,
                PageSize: pageSize,
            };

            if (searchTerm.trim()) {
                queryParams.Search = searchTerm.trim();
            }

            const response = await api.get('/api/market-management', { 
                params: queryParams,
                headers: {
                    'Accept': 'application/json'
                }
            });
            
            if (response.data?.status) {
                setMarkets(response.data.data.markets || []);
                setTotalCount(response.data.data.pagination.totalCount || 0);
                setError('');
            } else {
                throw new Error(response.data?.message || 'Failed to fetch markets');
            }
        } catch (error) {
            console.error('Error fetching markets:', error);
            console.error('Error response:', error.response?.data);
            setError(error.response?.data?.message || error.message || 'An error occurred');
            setMarkets([]);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleOpenCreateModal = () => {
        setModalType('create');
    };

    const handleCloseModal = () => {
        setModalType(null);
        setSelectedMarket(null);
        fetchMarkets();
    };

    const handleEdit = (market) => {
        setSelectedMarket(market);
        setModalType('edit');
    };

    const handleDelete = async (id) => {
        try {
            setLoading(true);
            const response = await api.delete(`/api/market-management/${id}`);
            
                fetchMarkets();
                setModalType(null);
       
        } catch (error) {
            console.error('Error deleting market:', error);
            console.error('Error response:', error.response?.data);
            setError(error.response?.data?.message || error.message || 'Failed to delete market');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto px-4 py-6">
            <div className="bg-white rounded-lg shadow-sm p-4 mb-6 transition-all duration-300 hover:shadow-md">
                <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
                    <h1 className="text-xl font-semibold text-gray-800">{t('stores.title')}</h1>
                    <div className="flex flex-col md:flex-row gap-3 md:items-center">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder={t('stores.search.placeholder')}
                                value={searchTerm}
                                onChange={handleSearch}
                                className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                            />
                            <svg
                                className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                />
                            </svg>
                        </div>
                        <button
                            onClick={handleOpenCreateModal}
                            className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105 space-x-2"
                        >
                            <svg
                                className="w-5 h-5 animate-pulse"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                />
                            </svg>
                            <span>{t('stores.actions.add')}</span>
                        </button>
                    </div>
                </div>

                {error && (
                    <div className="bg-red-50 border-l-4 border-red-400 p-3 mb-4 animate-fadeIn">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-red-600">{error}</p>
                            </div>
                        </div>
                    </div>
                )}

                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
                    </div>
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        {markets.map((market) => (
                            <div
                                key={market.id}
                                className="bg-white rounded-2xl border border-gray-100 hover:shadow-xl transition-all duration-300 group"
                            >
                                <div className="relative aspect-[4/3] overflow-hidden rounded-t-2xl">
                                    {market.imageLogo ? (
                                        <img
                                            src={getImageUrl(market.imageLogo)}
                                            alt={market.titleEng}
                                            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                                        />
                                    ) : (
                                        <div className="w-full h-full bg-gradient-to-br from-purple-500 via-purple-600 to-purple-700 flex items-center justify-center">
                                            <span className="text-5xl font-bold text-white">
                                                {market.titleEng?.charAt(0).toUpperCase() || '?'}
                                            </span>
                                        </div>
                                    )}
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent"></div>
                                    <div className="absolute bottom-0 left-0 right-0 p-4">
                                        <h3 className="text-xl font-bold text-white mb-1 truncate">
                                            {market.titleEng}
                                        </h3>
                                        <p className="text-sm text-gray-200 truncate">{market.titleAr}</p>
                                    </div>
                                    {market.discount > 0 && (
                                        <div className="absolute top-4 right-4 bg-red-500 text-white px-3 py-1.5 rounded-lg font-medium shadow-lg">
                                            {market.discount}% OFF
                                        </div>
                                    )}
                                    <div className="absolute top-4 left-4 flex items-center bg-black/30 backdrop-blur-sm px-2.5 py-1.5 rounded-lg">
                                        <svg className="w-4 h-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                                            <path 
                                                strokeLinecap="round" 
                                                strokeLinejoin="round" 
                                                strokeWidth="2" 
                                                d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.3.921 1.205 1.688 1.54 1.118l1.07-3.292a1 1 0 00-.95-.69h-3.462c-.969 0-1.371-1.24-.588-1.81l2.8-2.034z" 
                                            />
                                        </svg>
                                        <span className="ml-1 text-sm font-medium text-white">{market.rate}</span>
                                    </div>
                                </div>

                                <div className="p-4">
                                    <div className="flex items-center justify-between mb-4">
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900 mb-1">
                                                {market.titleEng}
                                            </h3>
                                            <p className="text-sm text-gray-500">{market.titleAr}</p>
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 rounded-xl p-3 mb-4">
                                        <div className="text-sm text-gray-500 mb-1">{t('stores.status.balance')}</div>
                                        <div className="flex items-center text-green-600 font-semibold">
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            ${market.wallet.toLocaleString()}
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 rounded-xl p-3 mb-4">
                                        <div className="text-sm text-gray-500 mb-1">{t('stores.status.location')}</div>
                                        <div className="flex items-center text-gray-900">
                                            <svg className="w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                            <span className="truncate">{market.addressName}</span>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-5 gap-2">
                                        <button
                                            onClick={() => handleEdit(market)}
                                            className="col-span-4 flex items-center justify-center px-4 py-2 text-sm font-medium text-purple-700 bg-purple-50 rounded-xl hover:bg-purple-100 transition-colors"
                                        >
                                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg>
                                            {t('stores.actions.edit')}
                                        </button>
                                        <button
                                            onClick={() => {
                                                setSelectedMarket(market);
                                                setModalType('delete');
                                            }}
                                            className="flex items-center justify-center px-3 py-2 text-sm font-medium text-red-700 bg-red-50 rounded-xl hover:bg-red-100 transition-colors"
                                        >
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {modalType === 'create' && (
                    <Modal
                        isOpen={true}
                        onClose={handleCloseModal}
                        title={t('stores.createMarket')}
                        size="lg"
                    >
                        <MarketRegistration onClose={handleCloseModal} />
                    </Modal>
                )}
                {modalType === 'delete' && (
                    <Modal
                        isOpen={true}
                        title={t('stores.delete.title')}
                        onClose={() => setModalType(null)}
                    >
                        <div className="p-6">
                            <p className="mb-6">{t('stores.delete.message')}</p>
                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => setModalType(null)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                                >
                                    {t('stores.actions.cancel')}
                                </button>
                                <button
                                    onClick={() => handleDelete(selectedMarket.id)}
                                    disabled={loading}
                                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
                                >
                                    {loading ? t('stores.delete.processing') : t('stores.delete.confirm')}
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}
                {modalType === 'edit' && (
                    <Modal
                        isOpen={true}
                        title={t('stores.editMarket')}
                        onClose={() => setModalType(null)}
                    >
                        <div className="p-6">
                            <p className="mb-6">{t('stores.edit.message')}</p>
                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => setModalType(null)}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
                                >
                                    {t('stores.actions.cancel')}
                                </button>
                                <button
                                    onClick={() => handleDelete(selectedMarket.id)}
                                    disabled={loading}
                                    className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
                                >
                                    {loading ? t('stores.delete.processing') : t('stores.delete.confirm')}
                                </button>
                            </div>
                        </div>
                    </Modal>
                )}

                {/* Pagination */}
                {totalCount > pageSize && (
                    <div className="mt-6 flex justify-center">
                        <nav className="flex items-center space-x-2">
                            <button
                                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className="px-3 py-1 rounded-md bg-gray-100 text-gray-700 disabled:opacity-50"
                            >
                                {t('stores.pagination.previous')}
                            </button>
                            <span className="text-gray-600">
                                {t('stores.pagination.page', { current: currentPage, total: Math.ceil(totalCount / pageSize) })}
                            </span>
                            <button
                                onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(totalCount / pageSize)))}
                                disabled={currentPage >= Math.ceil(totalCount / pageSize)}
                                className="px-3 py-1 rounded-md bg-gray-100 text-gray-700 disabled:opacity-50"
                            >
                                {t('stores.pagination.next')}
                            </button>
                        </nav>
                    </div>
                )}
            </div>
        </div>
    );
}