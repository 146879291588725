import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import { API_CONFIG } from '../../constants/config';
import toast from '../../components/Toast';

export default function MarketDrivers() {
  const { t } = useTranslation();
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 12;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [formData, setFormData] = useState({
    phoneNumber: '',
    password: '',
    fullName: '',
    addressName: '',
    lat: '',
    lng: '',
    area: '',
  });
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const getCurrentMarketId = () => {
    const marketData = localStorage.getItem('market');
    if (marketData) {
      const market = JSON.parse(marketData);
      return market.id;
    }
    return null;
  };

  const fetchDrivers = async () => {
    try {
      setLoading(true);
      const marketId = getCurrentMarketId();
      if (!marketId) {
        throw new Error('Market ID not found');
      }

      const response = await api.get(`/api/driver-management`, {
        params: {
          page: currentPage,
          limit: pageSize,
          search: searchTerm,
          marketId: marketId
        }
      });

      if (response.data?.status) {
        setDrivers(response.data.data.items || []);
        setTotalCount(response.data.data.totalCount || 0);
      } else {
        throw new Error(response.data?.message || 'Failed to fetch drivers');
      }
    } catch (error) {
      console.error('Error fetching drivers:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDrivers();
  }, [currentPage, searchTerm]);

  const handleAddDriver = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const marketId = getCurrentMarketId();
      if (!marketId) {
        throw new Error('Market ID not found');
      }

      const payload = {
        ...formData,
        marketId: marketId
      };

      const response = await api.post('/api/driver-management', payload);

      if (response.data?.status) {
        toast.success(t('drivers.addSuccess'));
        setIsModalOpen(false);
        fetchDrivers();
        setFormData({
          phoneNumber: '',
          password: '',
          fullName: '',
          addressName: '',
          lat: '',
          lng: '',
          area: '',
        });
      } else {
        throw new Error(response.data?.message || 'Failed to add driver');
      }
    } catch (error) {
      console.error('Error adding driver:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEditDriver = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await api.put(`/api/driver-management/${selectedDriver.id}`, formData);

      if (response.data?.status) {
        toast.success(t('drivers.editSuccess'));
        setIsModalOpen(false);
        fetchDrivers();
      } else {
        throw new Error(response.data?.message || 'Failed to update driver');
      }
    } catch (error) {
      console.error('Error updating driver:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDriver = async (id) => {
    try {
      setLoading(true);
      const response = await api.delete(`/api/driver-management/${id}`);

      if (response.data?.status) {
        toast.success(t('drivers.deleteSuccess'));
        fetchDrivers();
      } else {
        throw new Error(response.data?.message || 'Failed to delete driver');
      }
    } catch (error) {
      console.error('Error deleting driver:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (driver) => {
    setSelectedDriver(driver);
    setFormData({
      phoneNumber: driver.phoneNumber,
      fullName: driver.fullName,
      addressName: driver.addressName,
      lat: driver.lat,
      lng: driver.lng,
      area: driver.area,
    });
    setModalType('edit');
    setIsModalOpen(true);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">{t('drivers.title')}</h1>
        <button
          onClick={() => {
            setModalType('add');
            setIsModalOpen(true);
            setSelectedDriver(null);
            setFormData({
              phoneNumber: '',
              password: '',
              fullName: '',
              addressName: '',
              lat: '',
              lng: '',
              area: '',
            });
          }}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
        >
          {t('drivers.addNew')}
        </button>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder={t('drivers.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : error ? (
        <div className="text-red-500 text-center">{error}</div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {drivers.map((driver) => (
              <div key={driver.id} className="bg-white rounded-lg shadow-md p-4">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-semibold">{driver.fullName}</h3>
                    <p className="text-gray-600">{driver.phoneNumber}</p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(driver)}
                      className="text-blue-600 hover:text-blue-700"
                    >
                      {t('common.edit')}
                    </button>
                    <button
                      onClick={() => {
                        setSelectedDriver(driver);
                        setModalType('delete');
                        setIsModalOpen(true);
                      }}
                      className="text-red-600 hover:text-red-700"
                    >
                      {t('common.delete')}
                    </button>
                  </div>
                </div>
                <div className="text-sm text-gray-500">
                  <p>{driver.addressName}</p>
                  <p>{driver.area}</p>
                </div>
              </div>
            ))}
          </div>

          {totalCount > pageSize && (
            <div className="mt-6">
              <Pagination
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
              />
            </div>
          )}
        </>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t(modalType === 'add' ? 'drivers.addDriver' : modalType === 'edit' ? 'drivers.editDriver' : 'drivers.deleteDriver')}
      >
        {modalType === 'delete' ? (
          <div className="p-6">
            <p className="mb-6">{t('drivers.deleteConfirmation')}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-700"
              >
                {t('common.cancel')}
              </button>
              <button
                onClick={() => {
                  handleDeleteDriver(selectedDriver.id);
                  setIsModalOpen(false);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                {t('common.delete')}
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={modalType === 'add' ? handleAddDriver : handleEditDriver} className="p-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('drivers.form.fullName')}
                </label>
                <input
                  type="text"
                  value={formData.fullName}
                  onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('drivers.form.phoneNumber')}
                </label>
                <input
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              {modalType === 'add' && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('drivers.form.password')}
                  </label>
                  <input
                    type="password"
                    value={formData.password}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
              )}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('drivers.form.addressName')}
                </label>
                <input
                  type="text"
                  value={formData.addressName}
                  onChange={(e) => setFormData({ ...formData, addressName: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('drivers.form.area')}
                </label>
                <input
                  type="text"
                  value={formData.area}
                  onChange={(e) => setFormData({ ...formData, area: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('drivers.form.latitude')}
                  </label>
                  <input
                    type="number"
                    step="any"
                    value={formData.lat}
                    onChange={(e) => setFormData({ ...formData, lat: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {t('drivers.form.longitude')}
                  </label>
                  <input
                    type="number"
                    step="any"
                    value={formData.lng}
                    onChange={(e) => setFormData({ ...formData, lng: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-700"
              >
                {t('common.cancel')}
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                {modalType === 'add' ? t('common.add') : t('common.save')}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
}
