import PageTransition from '../../components/PageTransition';

const Carts = () => {
    return (
        <PageTransition>
            <div>
                <h1>Cart Page</h1>
            </div>
        </PageTransition>
    );
};

export default Carts;