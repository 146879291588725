import PageTransition from '../../components/PageTransition';

const Products = () => {
    return (
        <PageTransition>
            <div>
                <h1>Products Page</h1>
            </div>
        </PageTransition>
    );
};

export default Products;