import './App.css';
import './i18n';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Home from './Pages/Home';
import Categories from './Pages/Categories';
import Drivers from './Pages/Drivers';
import Fields from './Pages/Fields';
import Orders from './Pages/Orders';
import Products from './Pages/Products';
import Roles from './Pages/Roles';
import Stors from './Pages/Stors';
import Users from './Pages/Users';
import Admins from './Pages/Admins';
import Settings from './Pages/Settings';
import CustomersPage from './Pages/Customers';
import MarketDashboard from './Pages/Market/MarketDashboard';
import MarketHome from './Pages/Market/MarketHome';
import AddProduct from './Pages/Market/AddProduct'; // Assuming AddProduct is a new component
import MarketOrders from './Pages/Market/MarketOrders';
import MarketDrivers from './Pages/Market/MarketDrivers';
import UserMain from './Pages/User/Main';
import UserHome from './Pages/User/Home';
import UserAbout from './Pages/User/About';
import UserAccount from './Pages/User/Account';
import UserAddresses from './Pages/User/Addresses';
import UserCategories from './Pages/User/Categories';
import UserOrderDetail from './Pages/User/OrderDetail';
import UserOrders from './Pages/User/Orders';
import UserOtp from './Pages/User/Otp';
import UserProductDetail from './Pages/User/ProductDetail';
import UserProducts from './Pages/User/Products';
import UserSplash from './Pages/User/Splash';
import UserLogin from './Pages/User/UserLogin';
import Cart from './Pages/User/Carts';
import { AnimatePresence } from 'framer-motion';
import UserRegister from './Pages/User/UserRegister';
import MarketProducts from './Pages/Market/MarketProducts';
import MarketSettings from './Pages/Market/MarketSettings';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Set initial direction based on language
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
}

function AnimatedRoutes() {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="users" element={<CustomersPage />} />

        <Route path="admin" element={<Dashboard />}>
          <Route path="" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="admins" element={<Admins />} />
          <Route path="roles" element={<Roles />} />
          <Route path="categories" element={<Categories />} />
          <Route path="drivers" element={<Drivers />} />
          <Route path="fields" element={<Fields />} />
          <Route path="orders" element={<Orders />} />
          <Route path="products" element={<Products />} />
          <Route path="stors" element={<Stors />} />
          <Route path="users" element={<CustomersPage />} />
          <Route path="settings" element={<Settings />} />
        </Route>

        <Route path="market" element={<MarketDashboard />}>
          <Route path="home" element={<MarketHome />} />
          <Route path="orders" element={<MarketOrders />} />
          <Route path="drivers" element={<MarketDrivers />} />
          <Route path="products" element={<MarketProducts />} />
          <Route path="add-product" element={<AddProduct />} />
          <Route path="settings" element={<MarketSettings />} />
        </Route>
        <Route path="user-login" element={<UserLogin />} />
        <Route path="user-register" element={<UserRegister />} />
        <Route path="user-otp" element={<UserOtp />} />

        //user routs
        <Route path="user" element={<UserMain />}>
          <Route path="" element={<UserHome />} />
          <Route path="home" element={<UserHome />} />
          <Route path="about" element={<UserAbout />} />
          <Route path="account" element={<UserAccount />} />
          <Route path="addresses" element={<UserAddresses />} />
          <Route path="cart" element={<Cart />} />
          <Route path="categories" element={<UserCategories />} />
          <Route path="order/:id" element={<UserOrderDetail />} />
          <Route path="orders" element={<UserOrders />} />
          <Route path="product/:id" element={<UserProductDetail />} />
          <Route path="products" element={<UserProducts />} />
          <Route path="splash" element={<UserSplash />} />
        </Route>

      </Routes>
    </AnimatePresence>
  );
}

export default App;
