import { NavLink, Outlet, useNavigate, useLocation, useMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './menu.css';
import { useState, useEffect } from 'react';
import { STORAGE_KEYS } from '../constants/config';
import { API_CONFIG } from '../constants/config';
import AnimatedLogo from '../components/AnimatedLogo';
import ProfilePlaceholder from '../components/ProfilePlaceholder';
import EnhancedLanguageSwitcher from '../components/EnhancedLanguageSwitcher';

const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    if (imagePath.startsWith('http')) return imagePath;
    return `${API_CONFIG.BASE_URL}/images/${imagePath}`;
};

const MenuItem = ({ title, icon, to, onClick }) => {
    return (
        <NavLink
            to={to}
            onClick={onClick}
            className={({ isActive }) => `
                flex flex-row items-center mb-2 p-3 rounded-lg transition-all duration-200 ease-in-out
                ${isActive 
                    ? 'bg-white/20 text-white shadow-lg backdrop-blur-sm' 
                    : 'text-white/80 hover:bg-white/10 hover:text-white hover:translate-x-1'
                }
            `}
        >
            {({ isActive }) => (
                <>
                    <div className={`w-10 h-10 rounded-lg flex items-center justify-center ${isActive ? 'bg-white/20' : 'bg-white/5'}`}>
                        <img className="w-5 h-5 object-contain" src={`../images/${icon}`} alt={title} />
                    </div>
                    <p className="font-medium mx-3 text-sm lg:text-base">{title}</p>
                </>
            )}
        </NavLink>
    );
};

const Header = ({ onMenuClick, isMobileMenuOpen }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState('');
    const [userData, setUserData] = useState(null);
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
    const [notifications, setNotifications] = useState([
        { id: 1, title: t('dashboard.notifications.newOrder'), message: t('dashboard.notifications.newOrderMessage', { id: '123' }), time: t('dashboard.notifications.timeAgo', { time: '5m' }), isRead: false },
        { id: 2, title: t('dashboard.notifications.systemUpdate'), message: t('dashboard.notifications.systemUpdateMessage'), time: t('dashboard.notifications.timeAgo', { time: '1h' }), isRead: true },
    ]);

    useEffect(() => {
        const userDataStr = localStorage.getItem(STORAGE_KEYS.USER);
        if (userDataStr) {
            setUserData(JSON.parse(userDataStr));
        }

        const pathSegments = location.pathname.split('/').filter(Boolean);
        const title = pathSegments[pathSegments.length - 1];
        setPageTitle(title.charAt(0).toUpperCase() + title.slice(1));
    }, [location]);

    const handleLogout = () => {
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
        localStorage.removeItem(STORAGE_KEYS.USER);
        navigate('/login');
    };

    const handleNotificationClick = (notificationId) => {
        setNotifications(notifications.map(notification => 
            notification.id === notificationId 
                ? { ...notification, isRead: true }
                : notification
        ));
    };

    const unreadNotificationsCount = notifications.filter(n => !n.isRead).length;

    return (
        <div className="fixed top-0 right-0 left-0 md:left-[280px] bg-white/80 backdrop-blur-xl border-b border-gray-200/50 h-16 z-20 shadow-sm">
            <div className="h-full px-4 md:px-6 flex justify-between items-center">
                {/* Left Section */}
                <div className="flex items-center space-x-4">
                    <button 
                        onClick={onMenuClick}
                        className="p-2 -ml-2 md:hidden rounded-lg hover:bg-gray-100/80 transition-colors duration-200"
                        aria-label={t('dashboard.menu.toggle')}
                    >
                        {isMobileMenuOpen ? (
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        ) : (
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        )}
                    </button>
                    
                    {/* Breadcrumb */}
                    <div className="flex items-center space-x-2 text-sm">
                        <span className="text-gray-500">{t('dashboard.breadcrumb.admin')}</span>
                        <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                        </svg>
                        <span className="font-medium text-gray-900">{t(`dashboard.pages.${pageTitle.toLowerCase()}`)}</span>
                    </div>
                </div>

                {/* Right Section */}
                <div className="flex items-center gap-3">
                    <EnhancedLanguageSwitcher />
                    
                    {/* Profile */}
                    <div className="relative">
                        <button
                            onClick={() => setIsProfileDropdownOpen(!isProfileDropdownOpen)}
                            className="flex items-center space-x-3 p-2 rounded-xl hover:bg-gray-100/80 transition-all duration-200"
                        >
                            <div className="w-8 h-8 rounded-lg bg-purple-100 flex items-center justify-center overflow-hidden">
                                {userData?.profileImage ? (
                                    <img 
                                        src={getImageUrl(userData.profileImage)} 
                                        alt={userData?.userName || t('dashboard.profile.defaultName')}
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <ProfilePlaceholder />
                                )}
                            </div>
                            <div className="hidden md:block text-left">
                                <p className="text-sm font-medium text-gray-900">
                                    {userData?.userName || t('dashboard.profile.defaultName')}
                                </p>
                                <p className="text-xs text-gray-500">{t('dashboard.profile.role')}</p>
                            </div>
                        </button>

                        {/* Profile Dropdown */}
                        {isProfileDropdownOpen && (
                            <div className="absolute right-0 mt-2 w-48 bg-white/90 backdrop-blur-xl rounded-xl shadow-xl border border-gray-200/50 py-1 z-30">
                                <button
                                    onClick={() => {
                                        setIsProfileDropdownOpen(false);
                                        navigate('/admin/profile');
                                    }}
                                    className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50/80 transition-colors duration-200"
                                >
                                    {t('dashboard.profile.settings')}
                                </button>
                                <button
                                    onClick={handleLogout}
                                    className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50/80 transition-colors duration-200"
                                >
                                    {t('dashboard.profile.logout')}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Dashboard() {
    const { t, i18n } = useTranslation();
    const navigateTo = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const isRTL = i18n.language === 'ar';
    const menuItems = [
        { title: t('dashboard.menu.dashboard'), icon: "dashboard.png", path: '/admin/home' },
        { title: t('dashboard.menu.users'), icon: "users.png", path: '/admin/users' },
        { title: t('dashboard.menu.stores'), icon: "stors.png", path: '/admin/stors' },
        { title: t('dashboard.menu.drivers'), icon: "users.png", path: '/admin/drivers' },
        { title: t('dashboard.menu.adminManagement'), icon: "roles.png", path: '/admin/admins' },
        { title: t('dashboard.menu.roleManagement'), icon: "roles.png", path: '/admin/roles' },
        { title: t('dashboard.menu.fields'), icon: "category.png", path: '/admin/fields' },
        { title: t('dashboard.menu.categories'), icon: "category.png", path: '/admin/categories' },
        { title: t('dashboard.menu.productStock'), icon: "products.png", path: '/admin/products' },
        { title: t('dashboard.menu.orderManagement'), icon: "orders.png", path: '/admin/orders' },
        { title: t('dashboard.menu.settings'), icon: "settings.png", path: '/admin/settings' }
    ];

    return (
        <div className="min-h-screen flex bg-gray-50/50">
            {/* Sidebar */}
            <div className={`fixed top-0 ${isRTL ? 'right-0' : 'left-0'} h-screen w-[280px] bg-gradient-to-b from-[#1a237e] to-[#283593] transition-transform duration-300 ease-in-out z-30 
                ${isMobileMenuOpen ? 'translate-x-0' : `${isRTL ? 'translate-x-full' : '-translate-x-full'} md:translate-x-0`}`}>
                <div className="flex flex-col h-full">
                    <div className="p-4 mb-6">
                        <AnimatedLogo />
                    </div>
                    <div className="flex-1 px-4 overflow-y-auto menu-container">
                        {menuItems.map((item, index) => (
                            <MenuItem
                                key={index}
                                title={item.title}
                                icon={item.icon}
                                to={item.path}
                                onClick={() => setIsMobileMenuOpen(false)}
                            />
                        ))}
                    </div>
                    <div className="p-4">
                        <button
                            onClick={() => {
                                setIsMobileMenuOpen(false);
                                navigateTo('/login');
                            }}
                            className="w-full flex items-center p-3 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-all duration-200"
                        >
                            <div className="w-10 h-10 rounded-lg bg-white/5 flex items-center justify-center">
                                <img className="w-5 h-5" src="../images/logout.png" alt="Logout" />
                            </div>
                            <span className={`${isRTL ? 'mr-3' : 'ml-3'} font-medium`}>{t('dashboard.menu.logout')}</span>
                        </button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className={`flex-1 ${isRTL ? 'md:mr-[280px]' : 'md:ml-[280px]'}`}>
                <div className="fixed top-0 right-0 left-0 z-10">
                    <Header onMenuClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} isMobileMenuOpen={isMobileMenuOpen} />
                </div>
                <div className="mt-16 min-h-[calc(100vh-4rem)] p-4 md:p-6">
                    <Outlet />
                </div>
            </div>

            {/* Mobile menu overlay */}
            {isMobileMenuOpen && (
                <div
                    className="fixed inset-0 bg-black/50 backdrop-blur-sm z-20 md:hidden"
                    onClick={() => setIsMobileMenuOpen(false)}
                />
            )}
        </div>
    );
}