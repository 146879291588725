import { useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_CONFIG } from '../../constants/config';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const UserRegister = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        userName: '',
        profilePicture: null
    });
    const [errors, setErrors] = useState({});
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);

    const validateForm = () => {
        const newErrors = {};
        
        // Validate full name
        if (!formData.fullName.trim()) {
            newErrors.fullName = 'Full name is required';
        } else if (formData.fullName.trim().length < 3) {
            newErrors.fullName = 'Full name must be at least 3 characters';
        }

        // Validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        // Validate phone number (Saudi format)
        const phoneRegex = /^(5)[0-9]{8}$/; // Saudi mobile format starting with 5
        const phoneWithoutPrefix = formData.phoneNumber.replace('966', '');
        if (!formData.phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
        } else if (!phoneRegex.test(phoneWithoutPrefix)) {
            newErrors.phoneNumber = 'Please enter a valid Saudi mobile number starting with 5';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profilePicture' && files[0]) {
            setFormData(prev => ({
                ...prev,
                [name]: files[0]
            }));
            // Create preview URL
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(files[0]);
        } else if (name === 'phoneNumber') {
            // Only allow numbers and limit to 9 digits
            const value = e.target.value.replace(/\D/g, '').slice(0, 9);
            // Validate Saudi phone format (must start with 5)
            if (!value || value.startsWith('5')) {
                const formattedValue = `966${value}`;
                setFormData(prev => ({
                    ...prev,
                    phoneNumber: formattedValue,
                    userName: formattedValue
                }));
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            toast.error('Please fix the errors in the form');
            return;
        }

        
        setIsLoading(true);

        try {
            const submitFormData = new FormData();
            Object.keys(formData).forEach(key => {
                if (formData[key] !== null && key !== 'userName') {
                    submitFormData.append(key, formData[key]);
                }
            });
            // Add userName separately to ensure it's the same as phoneNumber
            submitFormData.append('userName', formData.phoneNumber);
            submitFormData.append('role', 'client');
            console.log(submitFormData);
            
            const response = await axios.post(`${API_CONFIG.BASE_URL}/signup`, submitFormData);

            if (response.data.status) {
                toast.success('Registration successful!');
                navigate('/user-otp', { 
                    state: { 
                        phone: formData.phoneNumber 
                    } 
                });
            } else {
                const errorMessages = response.data.errors || [response.data.message];
                errorMessages.forEach(error => toast.error(error));
            }
        } catch (error) {
            console.error('Registration error:', error);
            toast.error(error.response?.data?.message || 'Registration failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="h-screen flex">
            {/* Left Side - Illustration */}
            <div className="hidden lg:block lg:w-[40%] bg-purple-700 relative h-full">
                {/* Back Button */}
                <button 
                    onClick={() => navigate(-1)}
                    className="absolute top-8 left-8 text-white hover:text-purple-200 transition-colors flex items-center gap-2"
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <span>Back</span>
                </button>
                
                <div className="h-full flex items-center justify-center">
                    <img 
                        src="/images/login_back_user.png" 
                        alt="Shopping Cart Illustration"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>

            {/* Right Side - Registration Form */}
            <div className="w-full lg:w-[60%] flex flex-col justify-center px-6 sm:px-12 lg:px-20 bg-[#FAF3FF] relative h-full overflow-y-auto">
                {/* Mobile Back Button */}
                <button 
                    onClick={() => navigate(-1)}
                    className="lg:hidden absolute top-8 left-6 text-gray-600 hover:text-purple-600 transition-colors flex items-center gap-2"
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <span>Back</span>
                </button>

                <div className="max-w-md w-full mx-auto">
                    {/* Page Title */}
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold text-gray-900">Create Account</h1>
                        <p className="mt-2 text-gray-600">Join AJEEB and start shopping</p>
                    </div>

                    {/* Profile Picture Upload */}
                    <div className="mb-8 text-center">
                        <div 
                            onClick={() => fileInputRef.current.click()}
                            className="relative w-32 h-32 mx-auto cursor-pointer group"
                        >
                            <div className={`w-full h-full rounded-full overflow-hidden border-4 border-purple-200 ${!previewUrl ? 'bg-purple-50' : ''}`}>
                                {previewUrl ? (
                                    <img 
                                        src={previewUrl} 
                                        alt="Profile Preview" 
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center">
                                        <svg className="w-12 h-12 text-purple-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                            <div className="absolute inset-0 rounded-full bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                                <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            </div>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            id="profilePicture"
                            name="profilePicture"
                            onChange={handleChange}
                            accept="image/*"
                            className="hidden"
                        />
                        <p className="mt-2 text-sm text-gray-600">Click to upload profile picture</p>
                    </div>

                    {/* Registration Form */}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Phone Number Field */}
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                            </div>
                            <div className="absolute inset-y-0 left-12 flex items-center pointer-events-none text-gray-500">
                                +966
                            </div>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="5X XXX XXXX"
                                value={formData.phoneNumber.replace('966', '')}
                                onChange={handleChange}
                                required
                                className={`w-full pl-24 pr-4 py-3 rounded-xl border-2 ${
                                    errors.phoneNumber 
                                        ? 'border-red-300 focus:border-red-600' 
                                        : 'border-gray-100 focus:border-purple-600'
                                } focus:outline-none transition-colors bg-white`}
                            />
                            {errors.phoneNumber && (
                                <p className="mt-1 text-sm text-red-600">{errors.phoneNumber}</p>
                            )}
                        </div>

                        {/* Email Field */}
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </div>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email Address"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className={`w-full pl-12 pr-4 py-3 rounded-xl border-2 ${
                                    errors.email 
                                        ? 'border-red-300 focus:border-red-600' 
                                        : 'border-gray-100 focus:border-purple-600'
                                } focus:outline-none transition-colors bg-white`}
                            />
                            {errors.email && (
                                <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                            )}
                        </div>

                        {/* Full Name Field */}
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </div>
                            <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                placeholder="Full Name"
                                value={formData.fullName}
                                onChange={handleChange}
                                required
                                className={`w-full pl-12 pr-4 py-3 rounded-xl border-2 ${
                                    errors.fullName 
                                        ? 'border-red-300 focus:border-red-600' 
                                        : 'border-gray-100 focus:border-purple-600'
                                } focus:outline-none transition-colors bg-white`}
                            />
                            {errors.fullName && (
                                <p className="mt-1 text-sm text-red-600">{errors.fullName}</p>
                            )}
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full bg-purple-600 text-white rounded-xl px-6 py-3 font-medium ${
                                isLoading ? 'opacity-70 cursor-not-allowed' : 'hover:bg-purple-700'
                            } transition-colors`}
                        >
                            {isLoading ? 'Creating Account...' : 'Create Account'}
                        </button>

                        {/* Login Link */}
                        <p className="text-center text-sm text-gray-600">
                            Already have an account?{' '}
                            <Link to="/user-login" className="text-purple-600 hover:text-purple-700 font-medium">
                                Sign in
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserRegister;