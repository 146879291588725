import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import { ENDPOINTS, STORAGE_KEYS, ROLES, GENDER_OPTIONS } from '../constants/config';
import { motion, AnimatePresence } from 'framer-motion';
import MarketRegistration from '../components/MarketRegistration';
import EnhancedLanguageSwitcher from '../components/EnhancedLanguageSwitcher';

const AnimatedText = () => {
    const [isAnimating, setIsAnimating] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsAnimating(prev => !prev);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    const letterVariants = {
        initial: { y: 0, opacity: 0 },
        animate: i => ({
            y: [0, -20, 0],
            opacity: 1,
            transition: {
                duration: 2,
                delay: i * 0.1,
                repeat: Infinity,
                repeatDelay: 2
            }
        })
    };

    const letters = "AJEEB".split("");

    return (
        <motion.div 
            className="flex justify-center items-center mb-8"
            initial="initial"
            animate="animate"
            style={{ direction: 'ltr' }}
        >
            {letters.map((letter, i) => (
                <motion.span
                    key={i}
                    custom={i}
                    variants={letterVariants}
                    className="text-9xl font-bold text-white/90 drop-shadow-2xl mx-2"
                    style={{ textShadow: '0 0 20px rgba(255,255,255,0.3)' }}
                >
                    {letter}
                </motion.span>
            ))}
        </motion.div>
    );
};

export default function Login() {
    const navigateTo = useNavigate();
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        userName: '',
        password: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showMarketRegistration, setShowMarketRegistration] = useState(false);
    const [registerData, setRegisterData] = useState({
        fullName: '',
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        gender: '',
        city: '',
        birth: '',
        profileImage: null,
        role: ROLES.ADMIN
    });
    const [registerError, setRegisterError] = useState('');
    const [registerLoading, setRegisterLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setError('');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const formDataObj = new FormData();
            formDataObj.append('userName', formData.userName);
            formDataObj.append('password', formData.password);

            // Determine login endpoint based on user type (market or admin)
            const endpoint = showMarketRegistration ? ENDPOINTS.MARKET_LOGIN : ENDPOINTS.ADMIN_LOGIN;
            
            const response = await api.post(endpoint, formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                localStorage.setItem(STORAGE_KEYS.TOKEN, response.data.token);
                localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(response.data.user));
                localStorage.setItem(STORAGE_KEYS.MARKET, JSON.stringify(response.data.market));
                localStorage.setItem(STORAGE_KEYS.TOKEN_EXPIRATION, response.data.expiration);
                localStorage.setItem(STORAGE_KEYS.USER_ROLE, response.data.user.role);

                // Redirect based on user role
                if (response.data.user.role === ROLES.ADMIN) {
                    navigateTo('/admin/home');
                } else if (response.data.user.role === ROLES.MARKET) {
                    navigateTo('/market/home');
                }
            }
        } catch (err) {
            setError(err.response?.status === 401 
                ? 'Invalid username or password' 
                : 'An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleRegisterInputChange = (e) => {
        const { name, value } = e.target;
        setRegisterData(prev => ({
            ...prev,
            [name]: value
        }));
        setRegisterError('');
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        setRegisterLoading(true);
        setRegisterError('');

        try {
            const formDataObj = new FormData();
            formDataObj.append('fullName', registerData.fullName);
            formDataObj.append('userName', registerData.userName);
            formDataObj.append('email', registerData.email);
            formDataObj.append('password', registerData.password);
            formDataObj.append('confirmPassword', registerData.confirmPassword);
            formDataObj.append('gender', registerData.gender);
            formDataObj.append('city', registerData.city);
            formDataObj.append('birth', registerData.birth);
            formDataObj.append('profileImage', registerData.profileImage);
            formDataObj.append('role', registerData.role);

            const response = await api.post(ENDPOINTS.ADMIN_REGISTER, formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                localStorage.setItem(STORAGE_KEYS.TOKEN, response.data.token);
                localStorage.setItem(STORAGE_KEYS.USER, JSON.stringify(response.data.user));
                localStorage.setItem(STORAGE_KEYS.TOKEN_EXPIRATION, response.data.expiration);
                navigateTo('/admin/home');
            }
        } catch (err) {
            setRegisterError(err.response?.status === 401 
                ? 'Invalid username or password' 
                : 'An error occurred. Please try again.');
        } finally {
            setRegisterLoading(false);
        }
    };

    return (
        <div className="flex h-screen">
            {/* Left Panel - Image Side */}
            <div className="hidden lg:flex lg:w-1/2 relative">
                <img
                    src="https://images.unsplash.com/photo-1578916171728-46686eac8d58?q=80&w=1974"
                    alt="Supermarket"
                    className="absolute inset-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-purple-900/90 to-indigo-900/70">
                    <div className="flex flex-col justify-center items-center h-full">
                        <AnimatedText />
                    </div>
                </div>
            </div>

            {/* Right Panel - Login/Register Forms */}
            <div className="w-full lg:w-1/2 flex items-center justify-center p-8 bg-gray-50">
                <div className="absolute top-4 right-4 z-10">
                    <EnhancedLanguageSwitcher />
                </div>
                <div className="w-full max-w-md space-y-8">
                    {showMarketRegistration ? (
                        <div>
                            <div className="flex justify-between items-center mb-6">
                                <h2 className="text-3xl font-bold text-gray-900">{t('login.marketRegister')}</h2>
                                <button
                                    onClick={() => setShowMarketRegistration(false)}
                                    className="text-gray-400 hover:text-gray-500"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <MarketRegistration onClose={() => setShowMarketRegistration(false)} />
                        </div>
                    ) : !showRegister ? (
                        <>
                            <div className="text-center">
                                <h2 className="text-4xl font-bold text-gray-900 mb-2">{t('login.signIn')}</h2>
                                <p className="text-gray-600">{t('login.welcomeBack')}</p>
                            </div>

                            <form onSubmit={handleLogin} className="mt-8 space-y-6">
                                {error && (
                                    <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <div className="ml-3">
                                                <p className="text-sm text-red-600">{error}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="space-y-5">
                                    <div>
                                        <label className="block text-start mb-2 text-sm font-medium text-gray-700 mb-1">
                                            {t('login.username')}
                                        </label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                                </svg>
                                            </div>
                                            <input
                                                name="userName"
                                                type="text"
                                                value={formData.userName}
                                                onChange={handleInputChange}
                                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                                placeholder={t('login.enterUsername')}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label className="block mb-2 text-start mb-2 text-sm font-medium text-gray-700 mb-1">
                                            {t('login.password')}
                                        </label>
                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <input
                                                name="password"
                                                type="password"
                                                value={formData.password}
                                                onChange={handleInputChange}
                                                className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent bg-white"
                                                placeholder="••••••••"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-600"
                                        />
                                        <label className="ml-2 block text-sm text-gray-700">
                                            {t('login.rememberMe')}
                                        </label>
                                    </div>
                                </div>

                                <div className="flex flex-col space-y-4">
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className={`group relative w-full flex justify-center py-3 px-4 border border-transparent rounded-lg text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-200 ${loading ? 'opacity-75 cursor-not-allowed' : ''}`}
                                    >
                                        {loading ? t('login.signingIn') : t('login.signIn')}
                                    </button>
                                    
                                    <button
                                        type="button"
                                        onClick={() => setShowMarketRegistration(true)}
                                        className="group relative w-full flex justify-center py-3 px-4 border border-purple-600 rounded-lg text-purple-600 hover:bg-purple-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors duration-200"
                                    >
                                        {t('login.marketRegister')}
                                    </button>
                                </div>
                            </form>
                        </>
                    ) : (
                        <form onSubmit={handleRegister} className="bg-white/10 backdrop-blur-lg rounded-lg shadow-xl p-8">
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="fullName">
                                    {t('login.fullName')}
                                </label>
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    value={registerData.fullName}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="userName">
                                    {t('login.username')}
                                </label>
                                <input
                                    type="text"
                                    id="userName"
                                    name="userName"
                                    value={registerData.userName}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
                                    {t('login.email')}
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={registerData.email}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="password">
                                    {t('login.password')}
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={registerData.password}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="confirmPassword">
                                    {t('login.confirmPassword')}
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={registerData.confirmPassword}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="gender">
                                    {t('login.gender')}
                                </label>
                                <select
                                    id="gender"
                                    name="gender"
                                    value={registerData.gender}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                >
                                    <option value="">{t('login.selectGender')}</option>
                                    {GENDER_OPTIONS.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="city">
                                    {t('login.city')}
                                </label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={registerData.city}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="birth">
                                    {t('login.birthDate')}
                                </label>
                                <input
                                    type="date"
                                    id="birth"
                                    name="birth"
                                    value={registerData.birth}
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-white text-sm font-bold mb-2" htmlFor="profileImage">
                                    {t('login.profileImage')}
                                </label>
                                <input
                                    type="file"
                                    id="profileImage"
                                    name="profileImage"
                                    onChange={handleRegisterInputChange}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                />
                            </div>
                            {registerError && <div className="mb-4 text-red-500 text-sm">{registerError}</div>}
                            <div className="flex flex-col gap-4">
                                <button
                                    type="submit"
                                    disabled={registerLoading}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full transition-colors"
                                >
                                    {registerLoading ? '...' : t('login.register')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowRegister(false)}
                                    className="text-purple-600 hover:text-purple-800 font-medium"
                                >
                                    {t('login.alreadyHaveAccount')}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}