import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { API_CONFIG } from "../../constants/config";

const Header = () => {
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            const parsedUser = JSON.parse(userData);
            console.log('User Data:', parsedUser);
            console.log('Profile Image URL:', parsedUser.user.profileImage ? API_CONFIG.BASE_URL + "/images/" + parsedUser.profileImage : 'No profile image');
            setUser(parsedUser);
        } else {
            console.log('No user data found in localStorage');
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setUser(null);
        navigate('/user-login');
    };

    // Close menus when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.profile-menu')) {
                setIsProfileOpen(false);
            }
            if (!event.target.closest('.mobile-menu')) {
                setIsMobileMenuOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <>
            {/* Top Header */}
            <header className="bg-white shadow-sm sticky top-0 z-50">
                <div className="container mx-auto px-4">
                    <div className="flex items-center justify-between h-20">
                        {/* Left Section */}
                        <div className="flex items-center space-x-4">
                            <button 
                                className="p-2 hover:bg-gray-100 rounded-lg md:hidden mobile-menu"
                                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                            >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            </button>
                            <NavLink to="/user/home" className="text-purple-600 text-3xl md:text-4xl lg:text-5xl font-bold flex items-center space-x-2 group">
                                <span className="hidden sm:inline relative overflow-hidden">
                                    <span className="inline-block animate-slideIn animate-glowPulse font-extrabold" style={{ animationDelay: '0s, 0.5s' }}>A</span>
                                    <span className="inline-block animate-slideIn animate-float font-extrabold" style={{ animationDelay: '0.1s, 1s' }}>J</span>
                                    <span className="inline-block animate-slideIn animate-glowPulse font-extrabold" style={{ animationDelay: '0.2s, 1.5s' }}>E</span>
                                    <span className="inline-block animate-slideIn animate-float font-extrabold" style={{ animationDelay: '0.3s, 2s' }}>E</span>
                                    <span className="inline-block animate-slideIn animate-glowPulse font-extrabold" style={{ animationDelay: '0.4s, 2.5s' }}>B</span>
                                    <div className="absolute bottom-0 left-0 w-full h-1 bg-purple-600 transform scale-x-100 transition-transform duration-300 opacity-50"></div>
                                </span>
                            </NavLink>
                        </div>

                        {/* Search Bar */}
                        <div className="hidden md:flex flex-1 max-w-lg relative group">
                            <div className="relative flex w-full items-center">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <svg className="h-4 w-4 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search products..."
                                    className="w-full py-2 pl-10 pr-12 text-sm bg-white shadow-sm border-2 border-gray-100 rounded-xl focus:border-purple-500 focus:ring-0 focus:shadow-md transition-all duration-200"
                                />
                                <div className="absolute right-0 pr-3 flex items-center space-x-2">
                                    <kbd className="hidden group-focus-within:inline-block px-2 py-0.5 text-xs text-gray-400 bg-gray-100 border border-gray-200 rounded">
                                        ⌘K
                                    </kbd>
                                    <span className="text-gray-300">|</span>
                                    <button 
                                        type="button"
                                        className="text-gray-400 hover:text-purple-500 focus:outline-none"
                                    >
                                        <svg className="h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Right Section - Actions and Auth */}
                        <div className="flex items-center space-x-4">
                            <button className="p-2 hover:bg-gray-100 rounded-lg hidden sm:block">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                                </svg>
                            </button>
                            <NavLink to="/user/cart" className="relative p-2 hover:bg-gray-100 rounded-lg">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                                    2
                                </span>
                            </NavLink>
                            <button className="p-2 hover:bg-gray-100 rounded-lg hidden sm:block">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                            <button className="p-2 hover:bg-gray-100 rounded-lg relative hidden sm:block">
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                                    1
                                </span>
                            </button>

                            {user ? (
                                <div className="relative profile-menu">
                                    <button
                                        onClick={() => setIsProfileOpen(!isProfileOpen)}
                                        className="flex items-center space-x-3 focus:outline-none"
                                    >
                                        <div className="flex items-center space-x-3">
                                            {user.user.profileImage ? (
                                                <img
                                                    src={API_CONFIG.BASE_URL + "/images/"+user.user.profileImage}
                                                    alt="Profile"
                                                    className="w-10 h-10 rounded-full object-cover border-2 border-purple-500"
                                                />
                                            ) : (
                                                <div className="w-10 h-10 rounded-full border-2 border-purple-500 bg-purple-100 flex items-center justify-center">
                                                    <span className="text-lg font-semibold text-purple-600">
                                                        {user.user.fullName ? user.user.fullName.charAt(0).toUpperCase() : 'U'}
                                                    </span>
                                                </div>
                                            )}
                                            <span className="hidden md:block text-sm font-medium text-gray-700">
                                                {user.user.fullName || 'User'}
                                            </span>
                                        </div>
                                        <svg
                                            className={`w-5 h-5 text-gray-400 transform transition-transform duration-200 ${isProfileOpen ? 'rotate-180' : ''}`}
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>

                                    {/* Dropdown Menu */}
                                    {isProfileOpen && (
                                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                                            <button
                                                onClick={() => navigate('/user/account')}
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-purple-50"
                                            >
                                                My Account
                                            </button>
                                            <button
                                                onClick={() => navigate('/user/orders')}
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-purple-50"
                                            >
                                                My Orders
                                            </button>
                                            <button
                                                onClick={() => navigate('/user/settings')}
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-purple-50"
                                            >
                                                Settings
                                            </button>
                                            <hr className="my-1" />
                                            <button
                                                onClick={handleLogout}
                                                className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-red-50"
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <NavLink
                                        to="/user/login"
                                        className="hidden md:inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-purple-600 bg-purple-50 hover:bg-purple-100"
                                    >
                                        Login
                                    </NavLink>
                                    <NavLink
                                        to="/user/register"
                                        className="hidden md:inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700"
                                    >
                                        Register
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {/* Mobile Search */}
                <div className="md:hidden px-4 pb-4">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Search something here"
                            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
                        />
                        <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
                            <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </header>

            {/* Navigation Menu */}
            <nav className="bg-purple-50/50 border-b border-gray-200">
                <div className="container mx-auto px-4">
                    {/* Desktop Menu */}
                    <div className="hidden md:flex items-center justify-between h-12">
                        <div className="flex items-center space-x-8">
                            <NavLink 
                                to="/user/home" 
                                className={({ isActive }) => 
                                    `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600 hover:text-purple-600'}`
                                }
                            >
                                Home
                            </NavLink>
                            <div className="text-gray-400">|</div>
                            <NavLink 
                                to="/user/about" 
                                className={({ isActive }) => 
                                    `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600 hover:text-purple-600'}`
                                }
                            >
                                About Us
                            </NavLink>
                            <div className="text-gray-400">|</div>
                            <NavLink 
                                to="/user/products" 
                                className={({ isActive }) => 
                                    `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600 hover:text-purple-600'}`
                                }
                            >
                                Products
                            </NavLink>
                            <div className="text-gray-400">|</div>
                            <NavLink 
                                to="/user/cart" 
                                className={({ isActive }) => 
                                    `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600 hover:text-purple-600'}`
                                }
                            >
                                Cart
                            </NavLink>
                            <div className="text-gray-400">|</div>
                            <NavLink 
                                to="/user/categories" 
                                className={({ isActive }) => 
                                    `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600 hover:text-purple-600'}`
                                }
                            >
                                Categories
                            </NavLink>
                            <div className="text-gray-400">|</div>
                            <NavLink 
                                to="/user/contact" 
                                className={({ isActive }) => 
                                    `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600 hover:text-purple-600'}`
                                }
                            >
                                Contact
                            </NavLink>
                        </div>
                        
                    </div>

                    {/* Mobile Menu */}
                    {isMobileMenuOpen && (
                        <div className="md:hidden py-4">
                            <div className="flex flex-col space-y-4">
                                <NavLink 
                                    to="/user/home" 
                                    className={({ isActive }) => 
                                        `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600'}`
                                    }
                                >
                                    Home
                                </NavLink>
                                <NavLink 
                                    to="/user/about" 
                                    className={({ isActive }) => 
                                        `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600'}`
                                    }
                                >
                                    About Us
                                </NavLink>
                                <NavLink 
                                    to="/user/products" 
                                    className={({ isActive }) => 
                                        `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600'}`
                                    }
                                >
                                    Products
                                </NavLink>
                                <NavLink 
                                    to="/user/cart" 
                                    className={({ isActive }) => 
                                        `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600'}`
                                    }
                                >
                                    Cart
                                </NavLink>
                                <NavLink 
                                    to="/user/categories" 
                                    className={({ isActive }) => 
                                        `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600'}`
                                    }
                                >
                                    Categories
                                </NavLink>
                                <NavLink 
                                    to="/user/contact" 
                                    className={({ isActive }) => 
                                        `text-sm font-medium ${isActive ? 'text-purple-600' : 'text-gray-600'}`
                                    }
                                >
                                    Contact
                                </NavLink>
                                <div className="flex flex-col space-y-2 pt-4">
                                    <NavLink 
                                        to="/user-login" 
                                        className="px-6 py-1.5 text-sm font-medium bg-purple-50 text-gray-600 text-center"
                                    >
                                        Login
                                    </NavLink>
                                    <NavLink 
                                        to="/user/registration" 
                                        className="px-6 py-1.5 text-sm font-medium bg-purple-700 text-white text-center"
                                    >
                                        Registration
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </nav>
        </>
    );
};

const Footer = () => {
    return (
        <footer className="bg-[#F5E8FF] py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
                    {/* Logo and Description */}
                    <div className="col-span-1 md:col-span-3">
                        <h2 className="text-4xl md:text-6xl font-bold text-purple-700 mb-3">AJEEB</h2>
                        <p className="text-gray-600 text-sm leading-relaxed">
                            Our vision is to provide convenience and help increase your sales business.
                        </p>
                    </div>
                    
                    {/* Pages */}
                    <div className="col-span-1 md:col-span-3">
                        <h3 className="text-gray-900 font-medium mb-3 text-sm">Pages</h3>
                        <ul className="space-y-2">
                            <li>
                                <NavLink to="/user/home" className="text-gray-600 hover:text-purple-600 text-sm">
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/user/about" className="text-gray-600 hover:text-purple-600 text-sm">
                                    About us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/user/products" className="text-gray-600 hover:text-purple-600 text-sm">
                                    products
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/user/cart" className="text-gray-600 hover:text-purple-600 text-sm">
                                    cart
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* Community */}
                    <div className="col-span-1 md:col-span-3">
                        <h3 className="text-gray-900 font-medium mb-3 text-sm">Community</h3>
                        <ul className="space-y-2">
                            <li>
                                <NavLink to="/user/favorite" className="text-gray-600 hover:text-purple-600 text-sm">
                                    Favorite
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/user/offers" className="text-gray-600 hover:text-purple-600 text-sm">
                                    offers
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/user/my-order" className="text-gray-600 hover:text-purple-600 text-sm">
                                    my order
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/user/setting" className="text-gray-600 hover:text-purple-600 text-sm">
                                    Setting
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* Socials */}
                    <div className="col-span-1 md:col-span-3">
                        <h3 className="text-gray-900 font-medium mb-3 text-sm">Socials</h3>
                        <ul className="space-y-2">
                            <li>
                                <a href="https://discord.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-purple-600 text-sm">
                                    Discord
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-purple-600 text-sm">
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-purple-600 text-sm">
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-purple-600 text-sm">
                                    Facebook
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Contact Email */}
                <div className="mt-8 text-gray-600 text-sm">
                    <a href="mailto:Ajeebexamble@gmail.com" className="hover:text-purple-600">
                        Ajeebexamble@gmail.com
                    </a>
                </div>
            </div>
        </footer>
    );
};

const UserMain = () => {
    const [showHeader, setShowHeader] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const controlHeader = () => {
            const currentScrollY = window.scrollY;
            
            if (currentScrollY > lastScrollY && currentScrollY > 100) { // Scrolling down & past 100px
                setShowHeader(false);
            } else { // Scrolling up or at top
                setShowHeader(true);
            }
            
            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', controlHeader);
        
        return () => {
            window.removeEventListener('scroll', controlHeader);
        };
    }, [lastScrollY]);

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <header className={`fixed top-0 left-0 right-0 bg-white z-50 transition-transform duration-300 ${showHeader ? 'translate-y-0' : '-translate-y-full'} shadow-sm`}>
                <Header />
            </header>
            <main className="flex-grow container mx-auto px-4 py-8 mt-[150px]">
                <Outlet />
            </main>
            <Footer />
        </div>
    );
};

export default UserMain;