import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_CONFIG } from '../../constants/config';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const UserLogin = () => {
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const validatePhone = (value) => {
        const phoneRegex = /^(5)[0-9]{8}$/; // Saudi mobile format starting with 5
        return phoneRegex.test(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validatePhone(phone)) {
            setError('Please enter a valid Saudi mobile number starting with 5');
            return;
        }

        setIsLoading(true);

        try {
            const formattedPhone = `966${phone}`;

            const formData = new FormData();
            formData.append('userName', formattedPhone);
            formData.append('role', 'client');

            const response = await axios.post(`${API_CONFIG.BASE_URL}/check-username`, formData);

            if (response.data.status) {
                navigate('/user-otp', { 
                    state: { 
                        phone: formattedPhone 
                    } 
                });
            } else {
                console.log("error");
                navigate('/user-register');
                toast.error('Phone number is not registered. Please create an account.');
            }
        } catch (error) {
            console.error('Login error:', error);
            toast.error(error.response?.data?.message || 'Login failed');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/\D/g, '').slice(0, 9);
        if (!value || value.startsWith('5')) {
            setPhone(value);
            setError('');
        }
    };

    return (
        <div className="h-screen flex">
            {/* Left Side - Illustration */}
            <div className="hidden lg:block lg:w-[40%] bg-purple-700 relative h-full">
                {/* Back Button */}
                <button 
                    onClick={() => navigate(-1)}
                    className="absolute top-8 left-8 text-white hover:text-purple-200 transition-colors flex items-center gap-2"
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <span>Back</span>
                </button>
                
                <div className="h-full flex items-center justify-center">
                    <img 
                        src="/images/login_back_user.png" 
                        alt="Shopping Cart Illustration"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>

            {/* Right Side - Login Form */}
            <div className="w-full lg:w-[60%] flex flex-col justify-center px-6 sm:px-12 lg:px-20 bg-[#FAF3FF] relative h-full overflow-y-auto">
                {/* Mobile Back Button */}
                <button 
                    onClick={() => navigate(-1)}
                    className="lg:hidden absolute top-8 left-6 text-gray-600 hover:text-purple-600 transition-colors flex items-center gap-2"
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <span>Back</span>
                </button>

                <div className="max-w-md w-full mx-auto">
                    {/* Animated Logo */}
                    <Link to="/" className="inline-block mb-12">
                        <div className="float-animation">
                            <h1 className="text-6xl font-bold tracking-wider gradient-text" style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}>
                                AJEEB
                            </h1>
                        </div>
                    </Link>

                    {/* Welcome Text */}
                    <div className="mb-10">
                        <h2 className="text-sm text-gray-600 mb-2">Welcome to AJEEB</h2>
                        <h1 className="text-4xl font-bold text-gray-900">Sign in</h1>
                    </div>

                    {/* Login Form */}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* Phone Input */}
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                            </div>
                            <div className="absolute inset-y-0 left-12 flex items-center pointer-events-none text-gray-500">
                                +966
                            </div>
                            <input
                                type="tel"
                                placeholder="5X XXX XXXX"
                                value={phone}
                                onChange={handlePhoneChange}
                                className={`w-full pl-24 pr-4 py-3 rounded-xl border-2 ${
                                    error ? 'border-red-300 focus:border-red-600' : 'border-gray-100 focus:border-purple-600'
                                } focus:outline-none transition-colors bg-white`}
                                required
                            />
                            {error && (
                                <p className="mt-1 text-sm text-red-600">{error}</p>
                            )}
                        </div>

                        {/* Login Button */}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full bg-purple-600 text-white rounded-xl px-6 py-3 font-medium ${
                                isLoading ? 'opacity-70 cursor-not-allowed' : 'hover:bg-purple-700'
                            } transition-colors`}
                        >
                            {isLoading ? 'Checking...' : 'Continue'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserLogin;