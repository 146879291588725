import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_CONFIG } from '../../constants/config';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const UserOtp = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [otp, setOtp] = useState(['', '', '', '']);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [timeLeft, setTimeLeft] = useState(60);
    const phone = location.state?.phone;

    useEffect(() => {
        if (!phone) {
            navigate('/user-login');
            return;
        }

        let timer;
        if (timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft(prev => prev - 1);
            }, 1000);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [timeLeft, phone, navigate]);

    const handleResendOtp = async () => {
        if (timeLeft > 0) return;
        
        try {
            const formData = new FormData();
            formData.append('userName', phone);
            formData.append('role', 'client');

            await axios.post(`${API_CONFIG.BASE_URL}/check-username`, formData);
            setTimeLeft(60);
            toast.success('OTP resent successfully');
        } catch (error) {
            toast.error('Failed to resend OTP');
        }
    };

    const handleInputChange = (index, value) => {
        if (value.length > 1) return;
        
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        setError('');

        // Auto-focus next input
        if (value && index < 3) {
            const nextInput = document.getElementById(`otp-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const otpValue = otp.join('');
        
        if (otpValue.length !== 4) {
            setError('Please enter the complete OTP');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            const formData = new FormData();
            formData.append('userName', phone);
            formData.append('code', otpValue);
            
            // Log FormData contents
            const formDataObject = {};
            for (let [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }
            console.log('Form Data:', formDataObject);

            const response = await axios.post(`${API_CONFIG.BASE_URL}/user-login`, formData);
            console.log('API Response:', {
                data: response.data,
                
            });
               
            if (response.data.status) {
                console.log(response.data.status);

                // Save user details to localStorage
                localStorage.setItem('user', JSON.stringify(response.data.userResponse));
                localStorage.setItem('token', response.data.token);
                
                toast.success('Login successful');
                navigate('/user');
            } else {
                setError('Invalid OTP. Please try again.');
                toast.error(response.data.message);
            }
        } catch (error) {
            setError('Invalid OTP. Please try again.');
            toast.error(error.response?.data?.message || 'Login failed');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="h-screen flex">
            {/* Left Side - Illustration */}
            <div className="hidden lg:block lg:w-[40%] bg-purple-700 relative h-full">
                <button 
                    onClick={() => navigate(-1)}
                    className="absolute top-8 left-8 text-white hover:text-purple-200 transition-colors flex items-center gap-2"
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <span>Back</span>
                </button>
                
                <div className="h-full flex items-center justify-center">
                    <img 
                        src="/images/login_back_user.png" 
                        alt="Shopping Cart Illustration"
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>

            {/* Right Side - OTP Form */}
            <div className="w-full lg:w-[60%] flex flex-col justify-center px-6 sm:px-12 lg:px-20 bg-[#FAF3FF] relative">
                {/* Mobile Back Button */}
                <button 
                    onClick={() => navigate(-1)}
                    className="lg:hidden absolute top-8 left-6 text-gray-600 hover:text-purple-600 transition-colors flex items-center gap-2"
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    </svg>
                    <span>Back</span>
                </button>

                <div className="max-w-md w-full mx-auto">
                    {/* Title */}
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold text-gray-900">Enter OTP</h1>
                        <p className="mt-2 text-gray-600">
                            We've sent a code to {phone?.replace('966', '+966 ')}
                        </p>
                    </div>

                    {/* OTP Form */}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        {/* OTP Input */}
                        <div className="flex justify-center gap-4">
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    id={`otp-${index}`}
                                    value={digit}
                                    onChange={(e) => handleInputChange(index, e.target.value.replace(/\D/g, ''))}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    className={`w-14 h-14 text-center text-2xl font-bold rounded-xl border-2 ${
                                        error ? 'border-red-300 focus:border-red-600' : 'border-gray-100 focus:border-purple-600'
                                    } focus:outline-none transition-colors bg-white`}
                                    maxLength={1}
                                    autoFocus={index === 0}
                                />
                            ))}
                        </div>

                        {/* Error Message */}
                        {error && (
                            <p className="text-center text-sm text-red-600">{error}</p>
                        )}

                        {/* Submit Button */}
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`w-full bg-purple-600 text-white rounded-xl px-6 py-3 font-medium ${
                                isLoading ? 'opacity-70 cursor-not-allowed' : 'hover:bg-purple-700'
                            } transition-colors`}
                        >
                            {isLoading ? 'Verifying...' : 'Verify OTP'}
                        </button>

                        {/* Resend OTP */}
                        <div className="text-center">
                            <button
                                type="button"
                                onClick={handleResendOtp}
                                disabled={timeLeft > 0}
                                className={`text-sm ${
                                    timeLeft > 0 ? 'text-gray-400' : 'text-purple-600 hover:text-purple-700'
                                }`}
                            >
                                {timeLeft > 0 ? (
                                    `Resend OTP in ${timeLeft}s`
                                ) : (
                                    'Resend OTP'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserOtp;