import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import { API_CONFIG } from '../constants/config';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import Modal from '../components/Modal';

const ORDER_STATUS = {
  0: { label: 'Pending', color: 'bg-amber-100 text-amber-800 border border-amber-200' },
  1: { label: 'Confirmed', color: 'bg-sky-100 text-sky-800 border border-sky-200' },
  2: { label: 'In Progress', color: 'bg-indigo-100 text-indigo-800 border border-indigo-200' },
  3: { label: 'Delivered', color: 'bg-emerald-100 text-emerald-800 border border-emerald-200' },
  4: { label: 'Cancelled', color: 'bg-rose-100 text-rose-800 border border-rose-200' }
};

const PAYMENT_METHODS = {
  0: 'Cash',
  1: 'Card',
  2: 'Wallet'
};

const ImageWithFallback = ({ src, alt, className }) => {
  const [error, setError] = useState(false);

  const getInitials = (name) => {
    if (!name) return '?';
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  if (error || !src) {
    return (
      <div className={`${className} bg-gray-200 flex items-center justify-center text-gray-500 font-semibold`}>
        {getInitials(alt)}
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={() => setError(true)}
    />
  );
};

const OrderCardAlternative = ({ order, onStatusChange, onAssignDriver, onEntityClick, getImageUrl, onDelete }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
    <div className="bg-gradient-to-r from-gray-50 to-white p-4 border-b">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <span className="text-lg font-semibold text-gray-700">#{order.id}</span>
          <span className={`px-3 py-1 text-xs font-medium rounded-full ${ORDER_STATUS[order.status]?.color} shadow-sm`}>
            {ORDER_STATUS[order.status]?.label}
          </span>
        </div>
        <div className="text-sm text-gray-500 font-medium">
          {new Date(order.createdAt).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })}
        </div>
      </div>
    </div>

    <div className="p-4">
      <div className="flex items-start gap-4">
        <div className="flex-1">
          {/* Customer Section */}
          <div 
            className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 cursor-pointer transition-all duration-200 border border-transparent hover:border-gray-200"
            onClick={() => onEntityClick(order.user, 'user')}
          >
            <ImageWithFallback
              src={getImageUrl(order.user?.profileImage)}
              alt={order.user?.fullName}
              className="w-12 h-12 rounded-full ring-2 ring-purple-100"
            />
            <div>
              <p className="font-medium text-gray-800">{order.user?.fullName}</p>
              <p className="text-sm text-gray-500">{order.user?.phoneNumber}</p>
            </div>
          </div>

          {/* Market Section */}
          <div 
            className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 cursor-pointer transition-all duration-200 mt-3 border border-transparent hover:border-gray-200"
            onClick={() => onEntityClick(order.market, 'market')}
          >
            <ImageWithFallback
              src={getImageUrl(order.market?.imageLogo)}
              alt={order.market?.titleEng}
              className="w-12 h-12 rounded-full ring-2 ring-blue-100"
            />
            <div>
              <p className="font-medium text-gray-800">{order.market?.titleEng}</p>
              <p className="text-sm text-gray-500">{order.market?.addressName}</p>
            </div>
          </div>
        </div>

        <div className="flex-1">
          {/* Cost Breakdown */}
          <div className="bg-gradient-to-br from-gray-50 to-white rounded-lg p-4 border border-gray-100 shadow-sm">
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Products</span>
                <span className="font-medium text-gray-800">${order.productsCost.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Delivery</span>
                <span className="font-medium text-gray-800">${order.deliveryCost.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-600">Tax</span>
                <span className="font-medium text-gray-800">${order.tax.toFixed(2)}</span>
              </div>
              <div className="border-t pt-2 mt-2">
                <div className="flex justify-between font-semibold text-gray-900">
                  <span>Total</span>
                  <span className="text-lg">${order.totalCost.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Payment Method */}
          <div className="mt-3 flex justify-between items-center p-3 bg-gradient-to-r from-gray-50 to-white rounded-lg border border-gray-100">
            <span className="text-gray-600">Payment Method</span>
            <span className="font-medium text-gray-800 bg-white px-3 py-1 rounded-full shadow-sm border border-gray-200">
              {PAYMENT_METHODS[order.payment]}
            </span>
          </div>
        </div>
      </div>

      {/* Driver Section */}
      <div className="mt-4 border-t pt-4">
        {order.driver ? (
          <div 
            className="flex items-center justify-between p-3 rounded-lg hover:bg-gray-50 cursor-pointer transition-all duration-200 border border-transparent hover:border-gray-200"
            onClick={() => onEntityClick(order.driver, 'driver')}
          >
            <div className="flex items-center gap-3">
              <ImageWithFallback
                src={getImageUrl(order.driver.user?.profileImage)}
                alt={order.driver.user?.fullName}
                className="w-12 h-12 rounded-full ring-2 ring-green-100"
              />
              <div>
                <p className="font-medium text-gray-800">{order.driver.user?.fullName}</p>
                <p className="text-sm text-gray-500">{order.driver.user?.phoneNumber}</p>
              </div>
            </div>
            <div className="flex items-center gap-2 bg-white px-3 py-1.5 rounded-full shadow-sm border border-gray-200">
              <div className={`w-2 h-2 rounded-full ${order.driver.status === 1 ? 'bg-emerald-500' : 'bg-rose-500'}`}></div>
              <span className="text-sm font-medium text-gray-700">{order.driver.status === 1 ? 'Active' : 'Inactive'}</span>
            </div>
          </div>
        ) : (
          <button
            onClick={() => onAssignDriver(order)}
            className="w-full bg-blue-50 text-blue-600 hover:bg-blue-100 py-3 px-4 rounded-lg text-sm font-medium transition-colors border border-blue-200 hover:border-blue-300 shadow-sm"
          >
            Assign Driver
          </button>
        )}
      </div>

      {/* Status and Actions */}
      <div className="mt-4 flex justify-between items-center">
        <button
          onClick={() => onDelete(order.id)}
          className="text-sm px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg font-medium transition-colors border border-red-200 hover:border-red-300"
        >
          Delete Order
        </button>
        <select
          value={order.status}
          onChange={(e) => onStatusChange(order.id, parseInt(e.target.value))}
          className="text-sm border rounded-lg px-4 py-2 bg-white focus:ring-2 focus:ring-purple-500 focus:border-purple-500 shadow-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50 transition-colors"
        >
          {Object.entries(ORDER_STATUS).map(([value, { label }]) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

export default function Orders() {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [marketFilter, setMarketFilter] = useState('');
  const [driverFilter, setDriverFilter] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [markets, setMarkets] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [loadingEntity, setLoadingEntity] = useState(false);
  const [entityDetails, setEntityDetails] = useState(null);
  const [entityError, setEntityError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}/images/${imagePath}`;
  };

  const fetchMarkets = async () => {
    try {
      const response = await api.get('/api/market-management');
      if (response.data?.status) {
        setMarkets(response.data.data.markets || []);
      }
    } catch (error) {
      console.error('Error fetching markets:', error);
    }
  };

  const fetchDrivers = async () => {
    try {
      const response = await api.get('/api/driver-management');
      if (response.data?.status) {
        setDrivers(response.data.data.drivers || []);
      }
    } catch (error) {
      console.error('Error fetching drivers:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const queryParams = {
        pageNumber: currentPage,
        pageSize,
        sortBy: "createdAt",
      };

      if (searchTerm.trim()) {
        queryParams.searchTerm = searchTerm.trim();
      }

      if (statusFilter !== '') {
        queryParams.status = parseInt(statusFilter);
      }

      if (marketFilter) {
        queryParams.marketId = parseInt(marketFilter);
      }

      if (driverFilter) {
        queryParams.driverId = parseInt(driverFilter);
      }

      console.log('Orders API Request Parameters:', queryParams);

      const response = await api.get('/api/order-management', { params: queryParams });

      if (response.data) {
        setOrders(response.data.data.orders || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error('Failed to fetch orders');
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError(error.response?.data?.message || error.message || 'An error occurred');
      setOrders([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchEntityDetails = async (entity, type) => {
    try {
      setLoadingEntity(true);
      setEntityError('');
      let response;

      switch (type) {
        case 'user':
          response = await api.get(`/api/user-management/${entity.id}`);
          break;
        case 'driver':
          response = await api.get(`/api/driver-management/${entity.id}`);
          break;
        case 'market':
          response = await api.get(`/api/market-management/${entity.id}`);
          break;
        default:
          throw new Error('Invalid entity type');
      }

      if (response.data?.status) {
        setEntityDetails({ ...response.data.data, type });
        setIsModalOpen(true);
        setModalType(type);
      } else {
        throw new Error(response.data?.message || 'Failed to fetch details');
      }
    } catch (error) {
      console.error(`Error fetching ${type} details:`, error);
      setEntityError(error.response?.data?.message || error.message || `Failed to fetch ${type} details`);
    } finally {
      setLoadingEntity(false);
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      setLoading(true);
      const response = await api.put(`/api/order-management/${orderId}/status`, newStatus);

      if (response.data?.status) {
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order.id === orderId ? response.data.data : order
          )
        );
        setError('');
      } else {
        throw new Error(response.data?.message || 'Failed to update order status');
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      setError(error.response?.data?.message || error.message || 'Failed to update status');
    } finally {
      setLoading(false);
    }
  };

  const handleAssignDriver = async (orderId, driverId) => {
    try {
      setLoading(true);
      const response = await api.put(`/api/order-management/${orderId}/assign-driver`, driverId);

      if (response.data?.status) {
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order.id === orderId ? response.data.data : order
          )
        );
        setIsModalOpen(false);
        setError('');
      } else {
        throw new Error(response.data?.message || 'Failed to assign driver');
      }
    } catch (error) {
      console.error('Error assigning driver:', error);
      setError(error.response?.data?.message || error.message || 'Failed to assign driver');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
      const response = await api.delete(`/api/order-management/${orderToDelete}`);
      if (response.data.status) {
        // Remove the deleted order from the state
        setOrders(orders.filter(order => order.id !== orderToDelete));
        setShowDeleteModal(false);
        setOrderToDelete(null);
      }
  
  };

  const confirmDelete = (orderId) => {
    setOrderToDelete(orderId);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchMarkets();
    fetchDrivers();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize, statusFilter, searchTerm, marketFilter, driverFilter]);

  const renderEntityDetails = () => {
    if (!entityDetails) return null;

    switch (entityDetails.type) {
      case 'user':
        return (
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <ImageWithFallback
                src={getImageUrl(entityDetails.profileImage)}
                alt={entityDetails.fullName}
                className="h-20 w-20 rounded-full"
              />
              <div>
                <h3 className="text-lg font-semibold">{entityDetails.fullName}</h3>
                <p className="text-gray-600">{entityDetails.phoneNumber}</p>
                <p className="text-gray-600">{entityDetails.email}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold">Role</p>
                <p className="capitalize">{entityDetails.role}</p>
              </div>
              <div>
                <p className="font-semibold">Status</p>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${entityDetails.status === 'ACTIVE' ? 'bg-green-100 text-green-800' : 
                    entityDetails.status === 'INACTIVE' ? 'bg-yellow-100 text-yellow-800' : 
                    'bg-red-100 text-red-800'}`}>
                  {entityDetails.status}
                </span>
              </div>
              <div>
                <p className="font-semibold">Joined</p>
                <p>{new Date(entityDetails.createdAt).toLocaleDateString()}</p>
              </div>
            </div>

            {entityDetails.addresses && entityDetails.addresses.length > 0 && (
              <div>
                <h4 className="font-semibold mb-2">Addresses</h4>
                <div className="space-y-2">
                  {entityDetails.addresses.map(address => (
                    <div key={address.id} className="bg-gray-50 p-3 rounded-lg">
                      <div className="flex justify-between">
                        <span className="font-medium">{address.title}</span>
                        {address.isDefault && (
                          <span className="text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full">Default</span>
                        )}
                      </div>
                      <p className="text-gray-600 text-sm mt-1">{address.addressName}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );

      case 'driver':
        return (
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <ImageWithFallback
                src={getImageUrl(entityDetails.user?.profileImage)}
                alt={entityDetails.user?.fullName}
                className="h-20 w-20 rounded-full"
              />
              <div>
                <h3 className="text-lg font-semibold">{entityDetails.user?.fullName}</h3>
                <p className="text-gray-600">{entityDetails.user?.phoneNumber}</p>
                <p className="text-gray-600">{entityDetails.user?.email}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold">Status</p>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${entityDetails.status === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                  {entityDetails.status === 1 ? 'Active' : 'Inactive'}
                </span>
              </div>
              <div>
                <p className="font-semibold">Rating</p>
                <p className="flex items-center">
                  {entityDetails.rating}
                  <svg className="w-4 h-4 text-yellow-400 ml-1" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </p>
              </div>
              <div>
                <p className="font-semibold">Area</p>
                <p>{entityDetails.area} KM</p>
              </div>
              <div>
                <p className="font-semibold">Address</p>
                <p className="text-sm">{entityDetails.addressName}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold mb-2">ID Card</p>
                <ImageWithFallback
                  src={getImageUrl(entityDetails.imageId)}
                  alt="ID Card"
                  className="w-full h-32 object-cover rounded-lg"
                />
              </div>
              <div>
                <p className="font-semibold mb-2">Driving License</p>
                <ImageWithFallback
                  src={getImageUrl(entityDetails.drivingLicense)}
                  alt="Driving License"
                  className="w-full h-32 object-cover rounded-lg"
                />
              </div>
              <div className="col-span-2">
                <p className="font-semibold mb-2">Vehicle License</p>
                <ImageWithFallback
                  src={getImageUrl(entityDetails.vehicleLicense)}
                  alt="Vehicle License"
                  className="w-full h-32 object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        );

      case 'market':
        return (
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <ImageWithFallback
                src={getImageUrl(entityDetails.imageLogo)}
                alt={entityDetails.titleEng}
                className="h-20 w-20 rounded-full"
              />
              <div>
                <h3 className="text-lg font-semibold">{entityDetails.titleEng}</h3>
                <p className="text-gray-600">{entityDetails.titleAr}</p>
                <p className="text-gray-600">Owner: {entityDetails.nameOwner}</p>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold">Status</p>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${entityDetails.status === 1 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                  {entityDetails.status === 1 ? 'Active' : 'Inactive'}
                </span>
              </div>
              <div>
                <p className="font-semibold">Rating</p>
                <p className="flex items-center">
                  {entityDetails.rate}
                  <svg className="w-4 h-4 text-yellow-400 ml-1" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </p>
              </div>
              <div>
                <p className="font-semibold">Wallet Balance</p>
                <p>${entityDetails.walet?.toFixed(2)}</p>
              </div>
              <div>
                <p className="font-semibold">Current Discount</p>
                <p>{entityDetails.discount}%</p>
              </div>
            </div>

            <div>
              <p className="font-semibold mb-2">Description</p>
              <p className="text-gray-600">{entityDetails.descEng}</p>
              <p className="text-gray-600 mt-1">{entityDetails.descAr}</p>
            </div>

            <div>
              <p className="font-semibold mb-2">Commercial Registration</p>
              <ImageWithFallback
                src={getImageUrl(entityDetails.imageCommercial)}
                alt="Commercial Registration"
                className="w-full h-40 object-cover rounded-lg"
              />
            </div>

            {entityDetails.categories?.length > 0 && (
              <div>
                <h4 className="font-semibold mb-2">Categories</h4>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                  {entityDetails.categories.map(category => (
                    <div key={category.id} className="bg-gray-50 p-3 rounded-lg text-center">
                      <ImageWithFallback
                        src={getImageUrl(category.image)}
                        alt={category.titleEng}
                        className="w-12 h-12 mx-auto rounded-full mb-2"
                      />
                      <p className="font-medium text-sm">{category.titleEng}</p>
                      <p className="text-xs text-gray-500">{category.titleAr}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {entityDetails.products?.length > 0 && (
              <div>
                <h4 className="font-semibold mb-2">Products</h4>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                  {entityDetails.products.map(product => (
                    <div key={product.id} className="bg-gray-50 p-3 rounded-lg">
                      <ImageWithFallback
                        src={getImageUrl(product.image)}
                        alt={product.titleEng}
                        className="w-full h-24 object-cover rounded-lg mb-2"
                      />
                      <p className="font-medium text-sm">{product.titleEng}</p>
                      <p className="text-xs text-gray-500">{product.titleAr}</p>
                      <div className="flex justify-between items-center mt-2">
                        <p className="text-sm font-semibold">${product.price}</p>
                        {product.discount > 0 && (
                          <span className="text-xs bg-green-100 text-green-800 px-2 py-0.5 rounded-full">
                            -{product.discount}%
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  if (loading) return <Spinner />;

  return (
    <div className="p-6 bg-white rounded-lg">
      <div className="flex flex-col gap-4 mb-6">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-semibold">{t('orders.title')}</h1>
          <div className="flex items-center gap-4">
            <input
              type="text"
              placeholder={t('orders.search.placeholder')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="border rounded-md px-3 py-1"
            />
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          <select
            className="border rounded-md px-3 py-1"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">{t('orders.status.all')}</option>
            {Object.entries(ORDER_STATUS).map(([value, { label }]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
          <select
            className="border rounded-md px-3 py-1"
            value={marketFilter}
            onChange={(e) => setMarketFilter(e.target.value)}
          >
            <option value="">{t('orders.filters.store')}</option>
            {markets.map(market => (
              <option key={market.id} value={market.id}>{market.titleEng}</option>
            ))}
          </select>
          <select
            className="border rounded-md px-3 py-1"
            value={driverFilter}
            onChange={(e) => setDriverFilter(e.target.value)}
          >
            <option value="">{t('orders.filters.driver')}</option>
            {drivers.map(driver => (
              <option key={driver.id} value={driver.id}>{driver.user?.fullName}</option>
            ))}
          </select>
          <button 
            className="text-red-500 flex items-center gap-1"
            onClick={() => {
              setSearchTerm('');
              setStatusFilter('');
              setMarketFilter('');
              setDriverFilter('');
            }}
          >
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
            {t('orders.filters.reset')}
          </button>
        </div>
      </div>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {orders.map((order) => (
          <OrderCardAlternative
            key={order.id}
            order={order}
            onStatusChange={handleStatusChange}
            onAssignDriver={(order) => {
              setSelectedOrder(order);
              setModalType('assign-driver');
              setIsModalOpen(true);
            }}
            onEntityClick={(entity, type) => {
              fetchEntityDetails(entity, type);
            }}
            getImageUrl={getImageUrl}
            onDelete={confirmDelete}
          />
        ))}
      </div>

      <div className="mt-6">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
        />
      </div>

      {/* Entity Details Modal */}
      <Modal
        isOpen={isModalOpen && ['user', 'market', 'driver'].includes(modalType)}
        onClose={() => {
          setIsModalOpen(false);
          setEntityDetails(null);
          setEntityError('');
        }}
        title={
          modalType === 'user' ? t('orders.entityDetails.customer') : 
          modalType === 'market' ? t('orders.entityDetails.market') : 
          t('orders.entityDetails.driver')
        }
      >
        {loadingEntity ? (
          <div className="flex justify-center items-center py-8">
            <Spinner />
          </div>
        ) : entityError ? (
          <div className="text-red-500 text-center py-4">{entityError}</div>
        ) : (
          renderEntityDetails()
        )}
      </Modal>

      {/* Assign Driver Modal */}
      <Modal
        isOpen={isModalOpen && modalType === 'assign-driver'}
        onClose={() => setIsModalOpen(false)}
        title={t('orders.assignDriver.title')}
      >
        {selectedOrder && (
          <div>
            <select
              value={selectedDriver}
              onChange={(e) => setSelectedDriver(e.target.value)}
              className="w-full border rounded-md px-3 py-2 mb-4"
            >
              <option value="">{t('orders.assignDriver.select')}</option>
              {drivers.map(driver => (
                <option key={driver.id} value={driver.id}>
                  {driver.user?.fullName}
                </option>
              ))}
            </select>
            <button
              onClick={() => handleAssignDriver(selectedOrder.id, selectedDriver)}
              disabled={!selectedDriver}
              className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 disabled:opacity-50"
            >
              {t('orders.assignDriver.assign')}
            </button>
          </div>
        )}
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setOrderToDelete(null);
        }}
      >
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('orders.delete.title')}</h3>
          <p className="text-gray-600 mb-6">{t('orders.delete.message')}</p>
          <div className="flex justify-end gap-4">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setOrderToDelete(null);
              }}
              className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
            >
              {t('orders.actions.cancel')}
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-md transition-colors"
            >
              {t('orders.actions.delete')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}