import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import SearchableSelect from '../../components/SearchableSelect';
import { API_CONFIG, STORAGE_KEYS } from '../../constants/config';

export default function MarketProducts() {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 12;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    nameEng: '',
    nameAr: '',
    descEng: '',
    descAr: '',
    quantity: '',
    price: '',
    fieldId: '',
    categoryId: '',
    status: '1',
    rate: 0,
    discount: 0
  });
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [fields, setFields] = useState([]);
  const [filters, setFilters] = useState({
    categoryId: '',
    fieldId: ''
  });

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}${imagePath}`;
  };

  // Get current market ID from localStorage
  const getCurrentMarketId = () => {
    const marketData = localStorage.getItem(STORAGE_KEYS.MARKET);
    if (marketData) {
      const market = JSON.parse(marketData);
      return market.id;
    }
    return null;
  };

  // Fetch categories
  const fetchCategories = async () => {
    try {
      const response = await api.get('/api/category-management', {
        params: {
          pageSize: 50
        },
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setCategories(response.data.data.categories || []);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Fetch fields
  const fetchFields = async () => {
    try {
      const response = await api.get('/api/field-management', {
        params: {
          pageSize: 50
        },
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setFields(response.data.data.fields || []);
      }
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  // Fetch products with filters and pagination
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const marketId = getCurrentMarketId();
      if (!marketId) {
        setError(t('marketProducts.error.noMarket'));
        return;
      }

      const queryParams = {
        pageNumber: currentPage,
        pageSize: pageSize,
        search: searchTerm.trim(),
        marketId: marketId,
        ...filters
      };

      const response = await api.get('/api/product-management', {
        params: queryParams,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(STORAGE_KEYS.TOKEN)}`
        }
      });

      if (response.data?.status) {
        setProducts(response.data.data.products || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error(response.data?.message || t('marketProducts.error.loading'));
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      setError(t('marketProducts.error.loading'));
    } finally {
      setLoading(false);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError(t('marketProducts.error.imageSize'));
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Reset form
  const resetForm = () => {
    setFormData({
      nameEng: '',
      nameAr: '',
      descEng: '',
      descAr: '',
      quantity: '',
      price: '',
      fieldId: '',
      categoryId: '',
      status: '1',
      rate: 0,
      discount: 0
    });
    setSelectedImage(null);
    setImagePreview(null);
    setIsModalOpen(false);
  };

  // Open create modal
  const openCreateModal = () => {
    setModalType('add');
    resetForm();
    setIsModalOpen(true);
  };

  // Open update modal
  const openUpdateModal = (product) => {
    setModalType('update');
    setSelectedProduct(product);
    setFormData({
      nameEng: product.nameEng || '',
      nameAr: product.nameAr || '',
      descEng: product.descEng || '',
      descAr: product.descAr || '',
      quantity: product.quantity || '',
      price: product.price || '',
      fieldId: product.fieldId || '',
      categoryId: product.categoryId || '',
      status: product.status || '1',
      rate: product.rate || 0,
      discount: product.discount || 0
    });
    setImagePreview(getImageUrl(product.image));
    setIsModalOpen(true);
  };

  // Handle create/update product
  const handleSubmit = async (e) => {
    e.preventDefault();
    const marketId = getCurrentMarketId();
    if (!marketId) {
      setError(t('marketProducts.error.noMarket'));
      return;
    }

    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach(key => {
        formDataToSend.append(key, formData[key]);
      });
      formDataToSend.append('marketId', marketId);
      
      if (selectedImage) {
        formDataToSend.append('image', selectedImage);
      }

      if (modalType === 'add') {
        const response = await api.post('/api/product-management', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          }
        });

        if (response.data?.status) {
          resetForm();
          fetchProducts();
        } else {
          throw new Error(response.data?.message || t('marketProducts.error.create'));
        }
      } else if (modalType === 'update' && selectedProduct) {
        const response = await api.post(`/api/product-management/${selectedProduct.id}`, formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
          }
        });

        if (response.data?.status) {
          resetForm();
          fetchProducts();
        } else {
          throw new Error(response.data?.message || t('marketProducts.error.update'));
        }
      }
    } catch (error) {
      console.error('Error submitting product:', error);
      setError(error.message || t('marketProducts.error.submit'));
    }
  };

  // Handle delete product
  const handleDelete = async () => {
    if (!productToDelete) return;

    setDeleteLoading(true);
    try {
      const response = await api.delete(`/api/product-management/${productToDelete.id}`);
      if (response.data?.status) {
        setDeleteDialogOpen(false);
        setProductToDelete(null);
        fetchProducts();
      } else {
        throw new Error(response.data?.message || t('marketProducts.error.delete'));
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      setError(error.message || t('marketProducts.error.delete'));
    } finally {
      setDeleteLoading(false);
    }
  };

  // Handle filter changes
  const handleFilterChange = (name, value) => {
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setCurrentPage(1);
  };

  // Effects
  useEffect(() => {
    fetchCategories();
    fetchFields();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [currentPage, searchTerm, filters]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">{t('marketProducts.title')}</h1>
        <button
          onClick={openCreateModal}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {t('marketProducts.actions.add')}
        </button>
      </div>

      {/* Filters */}
      <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
        <input
          type="text"
          placeholder={t('marketProducts.filters.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded"
        />
        <SearchableSelect
          options={categories.map(cat => ({ value: cat.id, label: cat.nameEng }))}
          value={filters.categoryId}
          onChange={(value) => handleFilterChange('categoryId', value)}
          placeholder={t('marketProducts.filters.category')}
        />
        <SearchableSelect
          options={fields.map(field => ({ value: field.id, label: field.nameEng }))}
          value={filters.fieldId}
          onChange={(value) => handleFilterChange('fieldId', value)}
          placeholder={t('marketProducts.filters.field')}
        />
      </div>

      {/* Products Grid */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : error ? (
        <div className="text-red-500 text-center">{error}</div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {products.map(product => (
              <div key={product.id} className="border rounded-lg p-4 shadow">
                <img
                  src={getImageUrl(product.imageUrl)}
                  alt={product.nameEng}
                  className="w-full h-48 object-cover rounded mb-4"
                />
                <h3 className="font-semibold mb-2">{product.nameEng}</h3>
                <p className="text-gray-600 mb-2">{product.descEng}</p>
                <div className="flex justify-between items-center mb-2">
                  <span className="font-bold">${product.price}</span>
                  <span className="text-sm text-gray-500">{t('marketProducts.product.stock')}: {product.quantity}</span>
                </div>
                <div className="flex justify-between gap-2">
                  <button
                    onClick={() => openUpdateModal(product)}
                    className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 flex-1"
                  >
                    {t('marketProducts.actions.edit')}
                  </button>
                  <button
                    onClick={() => {
                      setProductToDelete(product);
                      setDeleteDialogOpen(true);
                    }}
                    className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 flex-1"
                  >
                    {t('marketProducts.actions.delete')}
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          {totalCount > pageSize && (
            <div className="mt-6">
              <Pagination
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
              />
            </div>
          )}
        </>
      )}

      {/* Create/Update Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <form onSubmit={handleSubmit} className="space-y-4">
          <h2 className="text-xl font-semibold mb-4">
            {modalType === 'add' ? t('marketProducts.modal.add.title') : t('marketProducts.modal.edit.title')}
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              name="nameEng"
              value={formData.nameEng}
              onChange={handleInputChange}
              placeholder={t('marketProducts.form.nameEng')}
              className="border p-2 rounded"
              required
            />
            <input
              type="text"
              name="nameAr"
              value={formData.nameAr}
              onChange={handleInputChange}
              placeholder={t('marketProducts.form.nameAr')}
              className="border p-2 rounded"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <textarea
              name="descEng"
              value={formData.descEng}
              onChange={handleInputChange}
              placeholder={t('marketProducts.form.descEng')}
              className="border p-2 rounded"
              required
            />
            <textarea
              name="descAr"
              value={formData.descAr}
              onChange={handleInputChange}
              placeholder={t('marketProducts.form.descAr')}
              className="border p-2 rounded"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleInputChange}
              placeholder={t('marketProducts.form.quantity')}
              className="border p-2 rounded"
              required
            />
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              placeholder={t('marketProducts.form.price')}
              className="border p-2 rounded"
              required
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <select
              name="categoryId"
              value={formData.categoryId}
              onChange={handleInputChange}
              className="border p-2 rounded"
              required
            >
              <option value="">{t('marketProducts.form.selectCategory')}</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.nameEng}
                </option>
              ))}
            </select>
            <select
              name="fieldId"
              value={formData.fieldId}
              onChange={handleInputChange}
              className="border p-2 rounded"
              required
            >
              <option value="">{t('marketProducts.form.selectField')}</option>
              {fields.map(field => (
                <option key={field.id} value={field.id}>
                  {field.nameEng}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="number"
              name="discount"
              value={formData.discount}
              onChange={handleInputChange}
              placeholder={t('marketProducts.form.discount')}
              className="border p-2 rounded"
            />
            <select
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="border p-2 rounded"
            >
              <option value="1">{t('marketProducts.form.status.active')}</option>
              <option value="0">{t('marketProducts.form.status.inactive')}</option>
            </select>
          </div>

          <div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="mb-2"
            />
            {imagePreview && (
              <img
                src={imagePreview}
                alt={t('marketProducts.form.preview')}
                className="w-32 h-32 object-cover rounded"
              />
            )}
          </div>

          {error && <div className="text-red-500">{error}</div>}

          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              {t('marketProducts.actions.cancel')}
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              {modalType === 'add' ? t('marketProducts.actions.create') : t('marketProducts.actions.update')}
            </button>
          </div>
        </form>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-4">{t('marketProducts.modal.delete.title')}</h2>
          <p>{t('marketProducts.modal.delete.message')}</p>
          <div className="flex justify-end gap-4 mt-6">
            <button
              onClick={() => setDeleteDialogOpen(false)}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
              disabled={deleteLoading}
            >
              {t('marketProducts.actions.cancel')}
            </button>
            <button
              onClick={handleDelete}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              disabled={deleteLoading}
            >
              {deleteLoading ? t('marketProducts.actions.deleting') : t('marketProducts.actions.delete')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}