import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { STORAGE_KEYS } from '../../constants/config';
import { API_CONFIG } from '../../constants/config';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { motion } from 'framer-motion';

const StatCard = ({ title, amount, change, comparisonText, icon, color }) => {
    const { t } = useTranslation();
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            whileHover={{ y: -5, transition: { duration: 0.2 } }}
            className={`bg-gradient-to-br ${color} rounded-2xl p-6 shadow-lg relative overflow-hidden`}
        >
            <div className="absolute top-0 right-0 w-32 h-32 -mr-8 -mt-8 opacity-20">
                <svg className="w-full h-full" viewBox="0 0 24 24" fill="currentColor">
                    {icon}
                </svg>
            </div>
            <div className="relative z-10">
                <h3 className="text-white/80 text-sm mb-2 font-medium">{t(title)}</h3>
                <p className="text-3xl font-bold text-white mb-4">
                    ${amount.toLocaleString()}
                </p>
                <div className="flex items-center space-x-1 text-sm">
                    <motion.span
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        className={`${change >= 0 ? 'text-green-200' : 'text-red-200'} font-medium`}
                    >
                        {change >= 0 ? '↑' : '↓'} {Math.abs(change)}%
                    </motion.span>
                    <span className="text-white/60">{t('marketHome.stats.vs')}</span>
                </div>
            </div>
        </motion.div>
    );
};

export default function MarketHome() {
    const { t } = useTranslation();
    const [marketData, setMarketData] = useState({
        stats: {
            totalSales: { amount: 0, percentageChange: 0, comparisonText: '' },
            totalOrder: { amount: 0, percentageChange: 0, comparisonText: '' },
            totalPending: { amount: 0, percentageChange: 0, comparisonText: '' }
        },
        salesDetails: [],
        dealsDetails: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchMarketData = async () => {
        try {
            setLoading(true);
            const userDataStr = localStorage.getItem(STORAGE_KEYS.USER);
            const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
            
            if (!token) {
                throw new Error(t('marketHome.error.loginAgain'));
            }

            const userData = JSON.parse(userDataStr);
            const marketId = userData?.id;

            if (!marketId) {
                throw new Error(t('marketHome.error.loginAgain'));
            }

            if (!/^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(marketId)) {
                throw new Error(t('marketHome.error.invalidMarketId'));
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/api/dashboard/market/${marketId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error(t('marketHome.error.unauthorized'));
                } else if (response.status === 403) {
                    throw new Error(t('marketHome.error.forbidden'));
                } else if (response.status === 404) {
                    throw new Error(t('marketHome.error.notFound'));
                }
                throw new Error(t('marketHome.error.fetchFailed'));
            }

            const data = await response.json();
            
            if (!data) {
                throw new Error(t('marketHome.error.noData'));
            }

            setMarketData({
                stats: {
                    totalSales: data.stats?.totalSales || { amount: 0, percentageChange: 0, comparisonText: '' },
                    totalOrder: data.stats?.totalOrder || { amount: 0, percentageChange: 0, comparisonText: '' },
                    totalPending: data.stats?.totalPending || { amount: 0, percentageChange: 0, comparisonText: '' }
                },
                salesDetails: data.salesDetails || [],
                dealsDetails: data.dealsDetails || []
            });
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMarketData();
    }, []);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'delivered':
                return 'bg-emerald-500 text-white';
            case 'pending':
                return 'bg-yellow-500 text-white';
            case 'rejected':
                return 'bg-red-500 text-white';
            default:
                return 'bg-gray-500 text-white';
        }
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-blue-50 flex items-center justify-center">
                <motion.div
                    animate={{ 
                        scale: [1, 1.2, 1],
                        rotate: [0, 360],
                    }}
                    transition={{ 
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                    }}
                    className="text-purple-600 text-xl font-medium"
                >
                    <svg className="w-12 h-12 animate-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                </motion.div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-purple-50 via-white to-blue-50 flex items-center justify-center">
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="bg-white/80 backdrop-blur-md rounded-2xl p-8 shadow-lg border border-red-100 max-w-md w-full mx-4"
                >
                    <div className="text-red-500 flex items-center justify-center mb-4">
                        <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                        </svg>
                    </div>
                    <p className="text-center text-gray-800 font-medium">{t('marketHome.error.title')}: {error}</p>
                </motion.div>
            </div>
        );
    }

    return (
        <div className="p-6 bg-gradient-to-br from-purple-50 via-white to-blue-50 min-h-screen">
            {/* Stats Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                <StatCard
                    title="marketHome.stats.totalSales"
                    amount={marketData.stats.totalSales.amount}
                    change={marketData.stats.totalSales.percentageChange}
                    color="from-blue-600 to-blue-800"
                    icon={<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />}
                />
                <StatCard
                    title="marketHome.stats.totalOrders"
                    amount={marketData.stats.totalOrder.amount}
                    change={marketData.stats.totalOrder.percentageChange}
                    color="from-emerald-600 to-emerald-800"
                    icon={<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />}
                />
                <StatCard
                    title="marketHome.stats.totalPending"
                    amount={marketData.stats.totalPending.amount}
                    change={marketData.stats.totalPending.percentageChange}
                    color="from-orange-600 to-orange-800"
                    icon={<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />}
                />
            </div>

            {/* Charts */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
                {/* Sales Overview */}
                <div className="bg-white/80 backdrop-blur-md rounded-2xl p-6 shadow-lg">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">{t('marketHome.charts.salesOverview')}</h3>
                    <div className="h-80">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={marketData.salesDetails}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="amount" stroke="#4f46e5" strokeWidth={2} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                {/* Recent Orders */}
                <div className="bg-white/80 backdrop-blur-md rounded-2xl p-6 shadow-lg">
                    <h3 className="text-lg font-medium text-gray-900 mb-4">{t('marketHome.recentOrders.title')}</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('marketHome.recentOrders.orderNumber')}
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('marketHome.recentOrders.customer')}
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('marketHome.recentOrders.amount')}
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {t('marketHome.recentOrders.status')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white/50 divide-y divide-gray-200">
                                {marketData.dealsDetails.map((order, index) => (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            #{order.id}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {order.customer}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            ${order.amount}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(order.status)}`}>
                                                {t(`marketHome.status.${order.status.toLowerCase()}`)}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}