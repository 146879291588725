import { toast as hotToast } from 'react-hot-toast';

const toast = {
    success: (message) => {
        hotToast.success(message, {
            style: {
                background: '#10B981',
                color: '#fff',
                padding: '16px',
                borderRadius: '8px',
            },
            iconTheme: {
                primary: '#fff',
                secondary: '#10B981',
            },
        });
    },
    error: (message) => {
        hotToast.error(message, {
            style: {
                background: '#EF4444',
                color: '#fff',
                padding: '16px',
                borderRadius: '8px',
            },
            iconTheme: {
                primary: '#fff',
                secondary: '#EF4444',
            },
        });
    },
};

export default toast;
