import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import { API_CONFIG } from '../constants/config';

export default function Fields() {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 12; 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedFieldDetails, setSelectedFieldDetails] = useState(null);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}${imagePath}`;
  };

  useEffect(() => {
    fetchFields();
  }, [currentPage, searchTerm]);

  const fetchFields = async () => {
    try {
      setLoading(true);
      const queryParams = {
        PageNumber: currentPage,
        PageSize: pageSize
      };

      if (searchTerm.trim()) {
        queryParams.Search = searchTerm.trim();
      }

      const response = await api.get('/api/field-management', {
        params: queryParams,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setFields(response.data.data.fields || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error(response.data?.message || 'Failed to fetch fields');
      }
    } catch (error) {
      console.error('Error fetching fields:', error);
      console.error('Error response:', error.response?.data);
      setError(error.response?.data?.message || error.message || 'An error occurred');
      setFields([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { 
        setError('Image size should be less than 5MB');
        return;
      }
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetForm = () => {
    setIsModalOpen(false);
    setModalType(null);
    setFormData({});
    setError('');
    setSelectedField(null);
    setSelectedImage(null);
    setImagePreview(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      
      // Validate required fields
      const requiredFields = ['nameAr', 'nameEng'];
      const missingFields = requiredFields.filter(field => !formData[field]);
      
      if (missingFields.length > 0) {
        throw new Error(`Please fill in all required fields: ${missingFields.join(', ')}`);
      }

      if (modalType === 'add') {
        // Create form data for multipart/form-data
        const formDataToSend = new FormData();
        
        formDataToSend.append('nameAr', formData.nameAr);
        formDataToSend.append('nameEng', formData.nameEng);
        formDataToSend.append('status', formData.status || 1);

        if (selectedImage) {
          formDataToSend.append('image', selectedImage);
        }

        const response = await api.post('/api/field-management', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data?.status) {
          resetForm();
          await fetchFields();
        } else {
          throw new Error(response.data?.message || 'Failed to add field');
        }
      } else if (modalType === 'edit' && selectedField) {
        // For update, we'll send JSON if there's no new image, otherwise FormData
        if (selectedImage) {
          const formDataToSend = new FormData();
          formDataToSend.append('nameAr', formData.nameAr);
          formDataToSend.append('nameEng', formData.nameEng);
          formDataToSend.append('status', formData.status || 1);
          formDataToSend.append('image', selectedImage);

          const response = await api.put(`/api/field-management/${selectedField.id}`, formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 200) {
            resetForm();
            await fetchFields();
          } else {
            throw new Error(response.data?.message || 'Failed to update field');
          }
        } else {
          // Send JSON data when no new image
          const jsonData = {
            nameAr: formData.nameAr,
            nameEng: formData.nameEng,
            status: formData.status || 1
          };

          const response = await api.put(`/api/field-management/${selectedField.id}`, jsonData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.status === 200) {
            resetForm();
            await fetchFields();
          } else {
            throw new Error(response.data?.message || 'Failed to update field');
          }
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      
      if (error.response) {
        const { status, data } = error.response;
        
        switch (status) {
          case 400:
            if (data.errors) {
              const errorMessages = Object.entries(data.errors)
                .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
                .join('\n');
              setError(`Validation errors:\n${errorMessages}`);
            } else {
              setError('Invalid form data. Please check your input.');
            }
            break;
            
          case 401:
            setError('You need to be logged in to perform this action.');
            // Optionally redirect to login
            break;
            
          case 403:
            setError('You do not have permission to perform this action.');
            break;
            
          case 404:
            setError('Field not found. It may have been deleted.');
            break;
            
          default:
            setError(error.response?.data?.message || error.message || 'An error occurred');
        }
      } else {
        setError(error.message || 'An error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const openDeleteDialog = (field) => {
    setFieldToDelete(field);
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setFieldToDelete(null);
    setDeleteDialogOpen(false);
    setDeleteLoading(false);
  };

  const handleDelete = async () => {
    if (!fieldToDelete) return;
    
    try {
      setDeleteLoading(true);
      const response = await api.delete(`/api/field-management/${fieldToDelete.id}`);
      
      if (response.status === 204 || response.data?.status) {
        setError('');
        closeDeleteDialog();
        await fetchFields();
      } else {
        throw new Error(response.data?.message || 'Failed to delete field');
      }
    } catch (error) {
      console.error('Error deleting field:', error);
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 401:
            setError('You need to be logged in to delete this field.');
            break;
          case 403:
            setError('You do not have permission to delete this field.');
            break;
          case 404:
            setError('Field not found. It may have already been deleted.');
            break;
          default:
            setError(error.response?.data?.message || 'Failed to delete field');
        }
      } else {
        setError(error.message || 'An error occurred while deleting the field');
      }
    } finally {
      setDeleteLoading(false);
    }
  };

  const openModal = (type, field = null) => {
    setModalType(type);
    setSelectedField(field);
    setIsModalOpen(true);
    if (field) {
      setFormData({
        nameAr: field.nameAr || '',
        nameEng: field.nameEng || '',
        status: field.status || 1
      });
      setImagePreview(field.imageUrl ? getImageUrl(field.imageUrl) : null);
    } else {
      setFormData({
        nameAr: '',
        nameEng: '',
        status: 1
      });
      setImagePreview(null);
    }
    setSelectedImage(null);
    setError('');
  };

  const openDetailsModal = (field) => {
    setSelectedFieldDetails(field);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setSelectedFieldDetails(null);
    setDetailsModalOpen(false);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6 transition-all duration-300 hover:shadow-md">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
          <h1 className="text-xl font-semibold text-gray-800">{t('fields.title')}</h1>
          <div className="flex flex-col md:flex-row gap-3 md:items-center">
            <div className="relative">
              <input
                type="text"
                placeholder={t('fields.search.placeholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
              />
              <svg
                className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <button
              onClick={() => openModal('add')}
              className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105 space-x-2"
            >
              <svg
                className="w-5 h-5 animate-pulse"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <span>{t('fields.actions.add')}</span>
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-3 mb-4 animate-fadeIn">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-600">{error}</p>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {fields.map((field) => (
              <div
                key={field.id}
                className="bg-white rounded-xl border border-gray-100 hover:shadow-lg transition-all duration-300"
              >
                <div className="aspect-w-16 aspect-h-9 rounded-t-xl overflow-hidden bg-gray-100">
                  {field.imageUrl ? (
                    <img
                      src={getImageUrl(field.imageUrl)}
                      alt={getImageUrl(field.imageUrl)}
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gradient-to-r from-purple-600 to-purple-800">
                      <svg className="w-12 h-12 text-white opacity-50" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                
                <div className="p-4">
                  <div className="mb-2">
                    <h3 className="text-lg font-semibold text-gray-900">{field.nameEng}</h3>
                    <p className="text-sm text-gray-600">{field.nameAr}</p>
                  </div>
                  
                  <div className="flex items-center justify-between mt-4">
                    <span className={`px-3 py-1 text-xs font-medium rounded-full ${
                      field.status === 1 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-red-100 text-red-800'
                    }`}>
                      {field.status === 1 ? t('fields.active') : t('fields.inactive')}
                    </span>
                    
                    <div className="flex space-x-2">
                      <button
                        onClick={() => openDetailsModal(field)}
                        className="p-2 text-purple-600 hover:bg-purple-50 rounded-lg transition-colors"
                        title={t('fields.actions.viewDetails')}
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => openModal('edit', field)}
                        className="p-2 text-purple-600 hover:bg-purple-50 rounded-lg transition-colors"
                        title={t('fields.actions.edit')}
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                      </button>
                      <button
                        onClick={() => openDeleteDialog(field)}
                        className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                        title={t('fields.actions.delete')}
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-6">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />
        </div>

        {deleteDialogOpen && (
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/* Background overlay */}
              <div 
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
                aria-hidden="true"
                onClick={closeDeleteDialog}
              />

              {/* Center modal */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

              <div 
                className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full animate-modal"
                style={{
                  animation: 'modal-appear 0.3s ease-out'
                }}
              >
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg 
                        className="h-6 w-6 text-red-600" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth="2" 
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {t('fields.delete.title')}
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t('fields.delete.message')}{' '}
                          <span className="font-semibold text-gray-700">
                            {fieldToDelete?.nameEng}
                          </span>
                          ? {t('fields.delete.confirmation')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm ${
                      deleteLoading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    onClick={handleDelete}
                    disabled={deleteLoading}
                  >
                    {deleteLoading ? (
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    ) : null}
                    {deleteLoading ? t('fields.delete.deleting') : t('fields.delete.delete')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeDeleteDialog}
                    disabled={deleteLoading}
                  >
                    {t('fields.actions.cancel')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Details Modal */}
        {detailsModalOpen && (
          <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div 
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity animate-overlay" 
                aria-hidden="true"
                onClick={closeDetailsModal}
              />

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full animate-modal">
                <div className="absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    onClick={closeDetailsModal}
                  >
                    <span className="sr-only">{t('fields.actions.close')}</span>
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <h3 className="text-2xl leading-6 font-semibold text-gray-900 mb-4">
                        {t('fields.details.title')}
                      </h3>

                      {selectedFieldDetails?.imageUrl && (
                        <div className="mb-6">
                          <img
                            src={getImageUrl(selectedFieldDetails.imageUrl)}
                            alt={selectedFieldDetails.nameEng}
                            className="w-full h-48 object-cover rounded-lg shadow-lg"
                          />
                        </div>
                      )}

                      <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">{t('fields.details.nameEn')}</h4>
                            <p className="mt-1 text-md text-gray-900">{selectedFieldDetails?.nameEng}</p>
                          </div>
                          <div>
                            <h4 className="text-sm font-medium text-gray-500">{t('fields.details.nameAr')}</h4>
                            <p className="mt-1 text-md text-gray-900" dir="rtl">{selectedFieldDetails?.nameAr}</p>
                          </div>
                        </div>

                        <div>
                          <h4 className="text-sm font-medium text-gray-500">{t('fields.details.status')}</h4>
                          <span className={`mt-1 inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                            selectedFieldDetails?.status === 1 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-red-100 text-red-800'
                          }`}>
                            {selectedFieldDetails?.status === 1 ? t('fields.active') : t('fields.inactive')}
                          </span>
                        </div>

                        <div>
                          <h4 className="text-sm font-medium text-gray-500">{t('fields.details.createdAt')}</h4>
                          <p className="mt-1 text-md text-gray-900">
                            {formatDate(selectedFieldDetails?.createdAt)}
                          </p>
                        </div>

                        <div>
                          <h4 className="text-sm font-medium text-gray-500">{t('fields.details.id')}</h4>
                          <p className="mt-1 text-md text-gray-900">{selectedFieldDetails?.id}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      closeDetailsModal();
                      openModal('edit', selectedFieldDetails);
                    }}
                  >
                    {t('fields.actions.edit')}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeDetailsModal}
                  >
                    {t('fields.actions.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <Modal
          isOpen={isModalOpen}
          onClose={resetForm}
          title={modalType === 'edit' ? t('fields.form.editTitle') : t('fields.form.addTitle')}
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('fields.form.fields.nameEn')}
              </label>
              <input
                type="text"
                name="nameEng"
                value={formData.nameEng || ''}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('fields.form.fields.nameAr')}
              </label>
              <input
                type="text"
                name="nameAr"
                value={formData.nameAr || ''}
                onChange={handleInputChange}
                className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('fields.form.fields.image')}
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg hover:border-purple-500/50 transition-colors">
                <div className="space-y-1 text-center">
                  {imagePreview ? (
                    <div className="relative">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="mx-auto h-32 w-32 object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedImage(null);
                          setImagePreview(null);
                        }}
                        className="absolute top-0 right-0 -mr-2 -mt-2 bg-red-100 text-red-600 rounded-full p-1 hover:bg-red-200"
                      >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>
                  ) : (
                    <>
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        viewBox="0 0 48 48"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md font-medium text-purple-600 hover:text-purple-500"
                        >
                          <span>{t('fields.form.fields.uploadImage')}</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            accept="image/*"
                            className="sr-only"
                            onChange={handleImageChange}
                          />
                        </label>
                        <p className="pl-1">{t('fields.form.fields.orDragAndDrop')}</p>
                      </div>
                      <p className="text-xs text-gray-500">{t('fields.form.fields.imageSizeLimit')}</p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center">
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={formData.status === 1}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    status: e.target.checked ? 1 : 2
                  }))}
                  className="form-checkbox h-5 w-5 text-purple-600 rounded focus:ring-purple-500"
                />
                <span className="ml-2 text-gray-700">{t('fields.form.fields.active')}</span>
              </label>
            </div>

            {error && (
              <div className="p-4 bg-red-100 border border-red-400 text-red-700 rounded-lg">
                {error}
              </div>
            )}

            <div className="flex justify-end gap-3 mt-6">
              <button
                type="button"
                onClick={resetForm}
                className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
              >
                {t('fields.actions.cancel')}
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? t('fields.form.submitting') : (modalType === 'edit' ? t('fields.form.update') : t('fields.form.create'))}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}