import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTransition from '../../components/PageTransition';

const ProductDetail = () => {
    const { id } = useParams();
    const [quantity, setQuantity] = useState(1);

    // Mock product data
    const product = {
        id: id,
        name: 'Lemon',
        rating: 4.8,
        price: 299.43,
        originalPrice: 934.33,
        discount: 24,
        availability: 'In stock',
        category: 'Accessories',
        details: 'beautiful description fkdxncdlkrnlsdcn',
        image: '/images/product1.jpg'
    };

    // Similar products data
    const similarProducts = [
        { id: 1, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product2.jpg' },
        { id: 2, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product3.jpg' },
        { id: 3, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product4.jpg' },
        { id: 4, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product5.jpg' },
        { id: 5, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product6.jpg' },
        { id: 6, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product7.jpg' },
        { id: 7, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product8.jpg' },
        { id: 8, name: 'Mushroom salad', price: 650, originalPrice: 1099, discount: 60, rating: 4.8, seller: 'Av Damar.Ryade', image: '/images/product9.jpg' }
    ];

    const handleQuantityChange = (value) => {
        const newQuantity = Math.max(1, quantity + value);
        setQuantity(newQuantity);
    };

    return (
        <PageTransition>
            <div className="container mx-auto px-4 py-8">
                {/* Product Detail Section */}
                <div className="flex flex-col lg:flex-row gap-8 mb-16">
                    {/* Product Image */}
                    <div className="lg:w-1/2">
                        <img 
                            src={product.image} 
                            alt={product.name}
                            className="w-full rounded-3xl object-cover"
                        />
                    </div>

                    {/* Product Info */}
                    <div className="lg:w-1/2 space-y-6">
                        <h1 className="text-4xl font-bold text-gray-900">{product.name}</h1>
                        
                        {/* Rating */}
                        <div className="flex items-center gap-2">
                            <div className="flex items-center">
                                {[...Array(3)].map((_, i) => (
                                    <svg key={i} className="w-6 h-6 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                ))}
                            </div>
                            <span className="text-xl font-semibold">{product.rating}</span>
                        </div>

                        {/* Price */}
                        <div className="flex items-center gap-4">
                            <span className="text-3xl font-bold text-purple-600">${product.price}</span>
                            <div className="flex items-center gap-2">
                                <span className="text-lg line-through text-gray-400">${product.originalPrice}</span>
                                <span className="text-red-500 font-medium">{product.discount}% off</span>
                            </div>
                        </div>

                        {/* Details */}
                        <div className="space-y-4">
                            <div className="flex items-center gap-4">
                                <span className="text-gray-600">Availability:</span>
                                <span className="text-green-600 font-medium">{product.availability}</span>
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="text-gray-600">Category:</span>
                                <span className="font-medium">{product.category}</span>
                            </div>
                            <div className="space-y-2">
                                <span className="text-gray-600">Details:</span>
                                <p className="font-medium">{product.details}</p>
                            </div>
                        </div>

                        {/* Quantity and Add to Cart */}
                        <div className="flex items-center gap-6">
                            <div className="flex items-center gap-3 bg-gray-100 rounded-xl p-2">
                                <button 
                                    onClick={() => handleQuantityChange(-1)}
                                    className="w-8 h-8 flex items-center justify-center text-gray-600 hover:text-purple-600"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                    </svg>
                                </button>
                                <span className="w-8 text-center font-medium">{quantity}</span>
                                <button 
                                    onClick={() => handleQuantityChange(1)}
                                    className="w-8 h-8 flex items-center justify-center text-gray-600 hover:text-purple-600"
                                >
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                    </svg>
                                </button>
                            </div>
                            <button className="flex-1 bg-purple-600 text-white py-3 px-6 rounded-xl font-medium hover:bg-purple-700 transition-colors">
                                Add To Cart
                            </button>
                            <button className="w-12 h-12 flex items-center justify-center rounded-xl border-2 border-gray-200 text-gray-600 hover:text-red-500 hover:border-red-500 transition-colors">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Similar Products Section */}
                <div>
                    <h2 className="text-2xl font-bold text-gray-900 mb-6">Similar Products</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                        {similarProducts.map((item) => (
                            <div 
                                key={item.id}
                                className="bg-[#F5E8FF] rounded-2xl p-4 hover:shadow-lg transition-shadow"
                            >
                                <img 
                                    src={item.image} 
                                    alt={item.name}
                                    className="w-full aspect-square object-cover rounded-xl mb-4"
                                />
                                <div className="space-y-2">
                                    <h3 className="font-medium text-gray-900">{item.name}</h3>
                                    <div className="flex items-center gap-1">
                                        <span className="text-sm text-gray-500">By</span>
                                        <span className="text-sm font-medium">{item.seller}</span>
                                    </div>
                                    <div className="flex items-center gap-1">
                                        <svg className="w-4 h-4 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                        <span className="text-sm font-medium">{item.rating}</span>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="font-bold">${item.price}</span>
                                        <span className="text-sm text-red-500">{item.discount}% off</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Pagination */}
                    <div className="flex justify-center mt-8 gap-2">
                        {[1, 2, 3, 4, 5].map((page) => (
                            <button
                                key={page}
                                className={`w-8 h-8 rounded-lg flex items-center justify-center ${
                                    page === 3
                                        ? 'bg-purple-600 text-white'
                                        : 'text-gray-600 hover:bg-purple-100'
                                }`}
                            >
                                {page}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </PageTransition>
    );
};

export default ProductDetail;