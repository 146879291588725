import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { API_CONFIG } from '../../constants/config';
import Pagination from '../../components/Pagination';
import Spinner from '../../components/Spinner';
import Modal from '../../components/Modal';

const ORDER_STATUS = {
  0: { label: 'marketOrders.status.pending', color: 'bg-amber-100 text-amber-800 border border-amber-200' },
  1: { label: 'marketOrders.status.confirmed', color: 'bg-sky-100 text-sky-800 border border-sky-200' },
  2: { label: 'marketOrders.status.inProgress', color: 'bg-indigo-100 text-indigo-800 border border-indigo-200' },
  3: { label: 'marketOrders.status.delivered', color: 'bg-emerald-100 text-emerald-800 border border-emerald-200' },
  4: { label: 'marketOrders.status.cancelled', color: 'bg-rose-100 text-rose-800 border border-rose-200' }
};

const PAYMENT_METHODS = {
  0: 'marketOrders.payment.cash',
  1: 'marketOrders.payment.card',
  2: 'marketOrders.payment.wallet'
};

const ImageWithFallback = ({ src, alt, className }) => {
  const [error, setError] = useState(false);

  const getInitials = (name) => {
    if (!name) return '?';
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  if (error || !src) {
    return (
      <div className={`${className} bg-gray-200 flex items-center justify-center text-gray-500 font-semibold`}>
        {getInitials(alt)}
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={() => setError(true)}
    />
  );
};

const OrderCardAlternative = ({ order, onStatusChange, onEntityClick, getImageUrl, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <div className="bg-gradient-to-r from-gray-50 to-white p-4 border-b">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <span className="text-lg font-semibold text-gray-700">#{order.id}</span>
            <span className={`px-3 py-1 text-xs font-medium rounded-full ${ORDER_STATUS[order.status]?.color} shadow-sm`}>
              {t(ORDER_STATUS[order.status]?.label)}
            </span>
          </div>
          <div className="text-sm text-gray-500 font-medium">
            {new Date(order.createdAt).toLocaleString('en-US', {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            })}
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="flex items-start gap-4">
          <div className="flex-1">
            {/* Customer Section */}
            <div 
              className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 cursor-pointer transition-all duration-200 border border-transparent hover:border-gray-200"
              onClick={() => onEntityClick(order.user, 'user')}
            >
              <ImageWithFallback
                src={getImageUrl(order.user?.profileImage)}
                alt={order.user?.fullName}
                className="w-12 h-12 rounded-full ring-2 ring-purple-100"
              />
              <div>
                <p className="font-medium text-gray-800">{order.user?.fullName}</p>
                <p className="text-sm text-gray-500">{order.user?.phoneNumber}</p>
              </div>
            </div>

            {/* Driver Section */}
            {order.driver && (
              <div 
                className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 cursor-pointer transition-all duration-200 mt-3 border border-transparent hover:border-gray-200"
                onClick={() => onEntityClick(order.driver, 'driver')}
              >
                <ImageWithFallback
                  src={getImageUrl(order.driver?.profileImage)}
                  alt={order.driver?.fullName}
                  className="w-12 h-12 rounded-full ring-2 ring-green-100"
                />
                <div>
                  <p className="font-medium text-gray-800">{order.driver?.fullName}</p>
                  <p className="text-sm text-gray-500">{order.driver?.phoneNumber}</p>
                </div>
              </div>
            )}
          </div>

          <div className="flex-1">
            {/* Cost Breakdown */}
            <div className="bg-gradient-to-br from-gray-50 to-white rounded-lg p-4 border border-gray-100 shadow-sm">
              <div className="space-y-2">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">{t('marketOrders.order.costs.products')}</span>
                  <span className="font-medium text-gray-800">${order.productsCost.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">{t('marketOrders.order.costs.delivery')}</span>
                  <span className="font-medium text-gray-800">${order.deliveryCost.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">{t('marketOrders.order.costs.tax')}</span>
                  <span className="font-medium text-gray-800">${order.tax.toFixed(2)}</span>
                </div>
                <div className="border-t pt-2 mt-2">
                  <div className="flex justify-between font-semibold text-gray-900">
                    <span>{t('marketOrders.order.costs.total')}</span>
                    <span className="text-lg">${order.totalCost.toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Payment Method */}
            <div className="mt-3 flex justify-between items-center p-3 bg-gradient-to-r from-gray-50 to-white rounded-lg border border-gray-100">
              <span className="text-gray-600">{t('marketOrders.order.paymentMethod')}</span>
              <span className="font-medium text-gray-800 bg-white px-3 py-1 rounded-full shadow-sm border border-gray-200">
                {t(PAYMENT_METHODS[order.payment])}
              </span>
            </div>
          </div>
        </div>

        {/* Status Change Section */}
        <div className="mt-4 border-t pt-4">
          <div className="flex items-center justify-between">
            <button
              onClick={() => onDelete(order.id)}
              className="text-sm px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg font-medium transition-colors border border-red-200 hover:border-red-300"
            >
              {t('marketOrders.order.delete')}
            </button>
            <div className="flex items-center gap-2">
              <span className="text-gray-600">{t('marketOrders.order.status')}</span>
              <select
                value={order.status}
                onChange={(e) => onStatusChange(order.id, parseInt(e.target.value))}
                className="form-select text-sm border-gray-300 rounded-lg focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              >
                {Object.entries(ORDER_STATUS).map(([value, { label }]) => (
                  <option key={value} value={value}>
                    {t(label)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function MarketOrders() {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [loadingEntity, setLoadingEntity] = useState(false);
  const [entityDetails, setEntityDetails] = useState(null);
  const [entityError, setEntityError] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}${imagePath}`;
  };

  const getCurrentMarketId = () => {
    const marketData = localStorage.getItem('market');
    if (marketData) {
      const market = JSON.parse(marketData);
      return market.id;
    }
    return null;
  };

  const fetchOrders = async () => {
    try {
      setLoading(true);
      const marketId = getCurrentMarketId();
      if (!marketId) {
        setError(t('marketOrders.error.loading'));
        return;
      }

      const queryParams = {
        pageNumber: currentPage,
        pageSize,
        marketId
      };

      if (searchTerm.trim()) {
        queryParams.searchTerm = searchTerm.trim();
      }

      if (statusFilter !== '') {
        queryParams.status = parseInt(statusFilter);
      }

      const response = await api.get('/api/order-management', { 
        params: queryParams,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setOrders(response.data.data.orders || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error(response.data?.message || t('marketOrders.error.loading'));
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message || t('marketOrders.error.loading'));
      setOrders([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchEntityDetails = async (entity, type) => {
    try {
      setLoadingEntity(true);
      setEntityError('');
      let response;

      switch (type) {
        case 'user':
          response = await api.get(`/api/user-management/${entity.id}`);
          break;
        case 'driver':
          response = await api.get(`/api/driver-management/${entity.id}`);
          break;
        default:
          throw new Error(t('marketOrders.error.details'));
      }

      if (response.data?.status) {
        setEntityDetails({ ...response.data.data, type });
        setIsModalOpen(true);
        setModalType(type);
      } else {
        throw new Error(response.data?.message || t('marketOrders.error.details'));
      }
    } catch (error) {
      setEntityError(error.response?.data?.message || error.message || t('marketOrders.error.details'));
    } finally {
      setLoadingEntity(false);
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try {
      setLoading(true);
      const response = await api.put(`/api/order-management/${orderId}/status`, newStatus);

      if (response.data?.status) {
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order.id === orderId ? response.data.data : order
          )
        );
        setError('');
      } else {
        throw new Error(response.data?.message || t('marketOrders.error.update'));
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message || t('marketOrders.error.update'));
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await api.delete(`/api/order-management/${orderToDelete}`);
      if (response.data.status) {
        setOrders(orders.filter(order => order.id !== orderToDelete));
        setShowDeleteModal(false);
        setOrderToDelete(null);
      }
    } catch (error) {
      setError(error.response?.data?.message || t('marketOrders.error.delete'));
    }
  };

  const confirmDelete = (orderId) => {
    setOrderToDelete(orderId);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, pageSize, statusFilter, searchTerm]);

  const renderEntityDetails = () => {
    if (!entityDetails) return null;

    switch (entityDetails.type) {
      case 'user':
        return (
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <ImageWithFallback
                src={getImageUrl(entityDetails.profileImage)}
                alt={entityDetails.fullName}
                className="h-20 w-20 rounded-full"
              />
              <div>
                <h3 className="text-lg font-semibold">{entityDetails.fullName}</h3>
                <p className="text-gray-600">{entityDetails.phoneNumber}</p>
                <p className="text-gray-600">{entityDetails.email}</p>
              </div>
            </div>
          </div>
        );

      case 'driver':
        return (
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <ImageWithFallback
                src={getImageUrl(entityDetails.user?.profileImage)}
                alt={entityDetails.user?.fullName}
                className="h-20 w-20 rounded-full"
              />
              <div>
                <h3 className="text-lg font-semibold">{entityDetails.user?.fullName}</h3>
                <p className="text-gray-600">{entityDetails.user?.phoneNumber}</p>
                <p className="text-gray-600">{entityDetails.user?.email}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="font-semibold">{t('marketOrders.order.status')}</p>
                <p className={`inline-flex items-center gap-1.5 ${
                  entityDetails.status === 1 ? 'text-green-600' : 'text-red-600'
                }`}>
                  <span className={`w-2 h-2 rounded-full ${
                    entityDetails.status === 1 ? 'bg-green-600' : 'bg-red-600'
                  }`}></span>
                  {entityDetails.status === 1 ? t('marketOrders.status.confirmed') : t('marketOrders.status.cancelled')}
                </p>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold mb-4">{t('marketDashboard.menu.orderManagement')}</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <input
            type="text"
            placeholder={t('marketOrders.filters.search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="border p-2 rounded"
          />
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="border p-2 rounded"
          >
            <option value="">{t('marketOrders.filters.all')}</option>
            {Object.entries(ORDER_STATUS).map(([value, { label }]) => (
              <option key={value} value={value}>{t(label)}</option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Spinner />
        </div>
      ) : error ? (
        <div className="text-red-500 text-center">{error}</div>
      ) : (
        <>
          <div className="space-y-4">
            {orders.map(order => (
              <OrderCardAlternative
                key={order.id}
                order={order}
                onStatusChange={handleStatusChange}
                onEntityClick={fetchEntityDetails}
                getImageUrl={getImageUrl}
                onDelete={confirmDelete}
              />
            ))}
          </div>

          {totalCount > pageSize && (
            <div className="mt-6">
              <Pagination
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={setCurrentPage}
              />
            </div>
          )}
        </>
      )}

      {/* Entity Details Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="p-6">
          <h2 className="text-xl font-semibold mb-4 capitalize">
            {t(`marketOrders.modal.${modalType}.title`)}
          </h2>
          {loadingEntity ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : entityError ? (
            <div className="text-red-500">{entityError}</div>
          ) : (
            renderEntityDetails()
          )}
        </div>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setOrderToDelete(null);
        }}
      >
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">{t('marketOrders.modal.delete.title')}</h3>
          <p className="text-gray-600 mb-6">{t('marketOrders.modal.delete.message')}</p>
          <div className="flex justify-end gap-4">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setOrderToDelete(null);
              }}
              className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
            >
              {t('marketOrders.modal.delete.cancel')}
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-lg transition-colors"
            >
              {t('marketOrders.modal.delete.confirm')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}