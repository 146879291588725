import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';
import { API_CONFIG, STORAGE_KEYS } from '../../constants/config';
import Spinner from '../../components/Spinner';

const ImageUpload = ({ label, imageUrl, onImageChange, className }) => {
  const { t } = useTranslation();
  const [dragActive, setDragActive] = useState(false);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (imageUrl) {
      setPreview(`${API_CONFIG.BASE_URL}${imageUrl}`);
    }
  }, [imageUrl]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = (file) => {
    if (file.size > 5 * 1024 * 1024) {
      alert(t('marketSettings.error.imageSize'));
      return;
    }
    
    onImageChange(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className={className}>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div
        className={`relative h-64 rounded-lg border-2 border-dashed transition-colors ${
          dragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
        } hover:border-blue-500 cursor-pointer overflow-hidden`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={() => document.getElementById(label).click()}
      >
        {preview ? (
          <div className="h-full group relative">
            <img
              src={preview}
              alt={label}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
              <p className="text-white text-sm">{t('marketSettings.form.changeImage')}</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full px-4 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="mt-2 text-sm text-gray-500">
              {t('marketSettings.form.dropImage')}
            </p>
            <p className="mt-1 text-xs text-gray-500">
              {t('marketSettings.form.imageFormat')}
            </p>
          </div>
        )}
        <input
          id={label}
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default function MarketSettings() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [marketData, setMarketData] = useState({
    titleAr: '',
    fieldId: '',
    titleEng: '',
    descAr: '',
    descEng: '',
    nameOwner: '',
    addressName: '',
    lat: 0,
    lng: 0,
    discount: 0,
    minimum: 0,
    dlivaryCost: 0,
    timeDelvary: 0,
    status: 1,
    logo: '',
    commercialRecord: ''
  });
  const [logoFile, setLogoFile] = useState(null);
  const [commercialFile, setCommercialFile] = useState(null);

  // Get current market ID from localStorage
  const getCurrentMarketId = () => {
    const marketData = localStorage.getItem(STORAGE_KEYS.MARKET);
    if (marketData) {
      const market = JSON.parse(marketData);
      return market.id;
    }
    return null;
  };

  useEffect(() => {
    fetchMarketDetails();
  }, []);

  const fetchMarketDetails = async () => {
    const marketId = getCurrentMarketId();
    if (!marketId) {
      setError(t('marketSettings.error.noMarket'));
      setLoading(false);
      return;
    }

    try {
      const response = await api.get(`/api/market-management/${marketId}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem(STORAGE_KEYS.TOKEN)}`
        }
      });
      setMarketData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.response?.data?.detail || t('marketSettings.error.loading'));
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMarketData(prev => ({
      ...prev,
      [name]: name.match(/lat|lng|discount|minimum|dlivaryCost|timeDelvary/) 
        ? parseFloat(value) || 0 
        : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError('');
    setSuccess('');

    const marketId = getCurrentMarketId();
    if (!marketId) {
      setError(t('marketSettings.error.noMarket'));
      setSaving(false);
      return;
    }

    try {
      const formData = new FormData();
      
      // Append all market data
      Object.keys(marketData).forEach(key => {
        formData.append(key, marketData[key]);
      });

      formData.append('marketId', marketId);
      formData.append('fieldId', marketData.fieldId);

      // Append files if changed
      if (logoFile) {
        formData.append('imageLogo', logoFile);
      }
      if (commercialFile) {
        formData.append('imageCommercial', commercialFile);
      }

      await api.put(`/api/market-management/${marketId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem(STORAGE_KEYS.TOKEN)}`
        },
      });

      setSuccess(t('marketSettings.success.update'));
    } catch (error) {
      setError(error.response?.data?.detail || t('marketSettings.error.update'));
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">{t('marketSettings.title')}</h1>

        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {success && (
          <div className="mb-4 p-4 bg-green-50 border border-green-200 text-green-700 rounded-lg">
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Images Section */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('marketSettings.sections.images')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <ImageUpload
                label={t('marketSettings.form.logoImage')}
                imageUrl={marketData.logo ? `/images/${marketData.logo}` : null}
                onImageChange={setLogoFile}
                className="col-span-1"
              />
              <ImageUpload
                label={t('marketSettings.form.commercialLicense')}
                imageUrl={marketData.commercialRecord ? `/images/${marketData.commercialRecord}` : null}
                onImageChange={setCommercialFile}
                className="col-span-1"
              />
            </div>
          </div>

          {/* Basic Information */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('marketSettings.sections.basicInfo')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.nameEng')}
                </label>
                <input
                  type="text"
                  name="titleEng"
                  value={marketData.titleEng}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.nameAr')}
                </label>
                <input
                  type="text"
                  name="titleAr"
                  value={marketData.titleAr}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                  dir="rtl"
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.descEng')}
                </label>
                <textarea
                  name="descEng"
                  value={marketData.descEng}
                  onChange={handleInputChange}
                  rows="3"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.descAr')}
                </label>
                <textarea
                  name="descAr"
                  value={marketData.descAr}
                  onChange={handleInputChange}
                  rows="3"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                  dir="rtl"
                />
              </div>
            </div>
          </div>

          {/* Location Information */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('marketSettings.sections.location')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.ownerName')}
                </label>
                <input
                  type="text"
                  name="nameOwner"
                  value={marketData.nameOwner}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.address')}
                </label>
                <input
                  type="text"
                  name="addressName"
                  value={marketData.addressName}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.latitude')}
                </label>
                <input
                  type="number"
                  name="lat"
                  value={marketData.lat}
                  onChange={handleInputChange}
                  step="any"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.longitude')}
                </label>
                <input
                  type="number"
                  name="lng"
                  value={marketData.lng}
                  onChange={handleInputChange}
                  step="any"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
            </div>
          </div>

          {/* Business Settings */}
          <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('marketSettings.sections.business')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.discount')}
                </label>
                <input
                  type="number"
                  name="discount"
                  value={marketData.discount}
                  onChange={handleInputChange}
                  min="0"
                  max="100"
                  step="0.1"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.minimumOrder')}
                </label>
                <input
                  type="number"
                  name="minimum"
                  value={marketData.minimum}
                  onChange={handleInputChange}
                  min="0"
                  step="0.01"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.deliveryCost')}
                </label>
                <input
                  type="number"
                  name="dlivaryCost"
                  value={marketData.dlivaryCost}
                  onChange={handleInputChange}
                  min="0"
                  step="0.01"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.deliveryTime')}
                </label>
                <input
                  type="number"
                  name="timeDelvary"
                  value={marketData.timeDelvary}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {t('marketSettings.form.status')}
                </label>
                <select
                  name="status"
                  value={marketData.status}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  required
                >
                  <option value={1}>{t('marketSettings.form.status.active')}</option>
                  <option value={0}>{t('marketSettings.form.status.inactive')}</option>
                </select>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={saving}
              className={`px-6 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
                saving ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {saving ? t('marketSettings.actions.saving') : t('marketSettings.actions.save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}