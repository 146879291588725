import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../services/api';
import Modal from '../components/Modal';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import toast from '../components/Toast';
import { API_CONFIG } from '../constants/config';

export default function Categories() {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 12;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `${API_CONFIG.BASE_URL}${imagePath}`;
  };

  useEffect(() => {
    fetchCategories();
  }, [currentPage, searchTerm]);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const queryParams = {
        pageNumber: currentPage,
        pageSize: pageSize
      };

      if (searchTerm.trim()) {
        queryParams.search = searchTerm.trim();
      }

      const response = await api.get('/api/category-management', {
        params: queryParams,
        headers: {
          'Accept': 'application/json'
        }
      });

      if (response.data?.status) {
        setCategories(response.data.data.categories || []);
        setTotalCount(response.data.data.pagination.totalCount || 0);
        setError('');
      } else {
        throw new Error(response.data?.message || 'Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError(error.response?.data?.message || error.message || 'An error occurred');
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError('');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size should be less than 5MB');
        return;
      }
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetForm = () => {
    setIsModalOpen(false);
    setModalType(null);
    setSelectedCategory(null);
    setFormData({});
    setSelectedImage(null);
    setImagePreview(null);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (modalType === 'add') {
        const formDataToSend = new FormData();
        formDataToSend.append('nameEn', formData.nameEn);
        formDataToSend.append('nameAr', formData.nameAr);
        formDataToSend.append('descriptionEn', formData.descriptionEn);
        formDataToSend.append('descriptionAr', formData.descriptionAr);
        formDataToSend.append('parentId', formData.parentId);
        formDataToSend.append('image', selectedImage);

        const response = await api.post('/api/category-management', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data) {
          resetForm();
          fetchCategories();
        }
      } else {
        const response = await api.put(`/api/category-management/${selectedCategory.id}`, {
          nameEn: formData.nameEn,
          nameAr: formData.nameAr,
          descriptionEn: formData.descriptionEn,
          descriptionAr: formData.descriptionAr,
          parentId: formData.parentId
        });

        if (response.data) {
          resetForm();
          fetchCategories();
        }
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to create/update category');
    }
  };

  const handleDelete = async () => {
    if (!categoryToDelete) return;
    
    try {
      setDeleteLoading(true);
      await api.delete(`/api/category-management/${categoryToDelete.id}`);
      setDeleteDialogOpen(false);
      setCategoryToDelete(null);
      fetchCategories();
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to delete category');
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <div className="bg-white rounded-lg shadow-sm p-4 mb-6 transition-all duration-300 hover:shadow-md">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
          <h1 className="text-xl font-semibold text-gray-800">{t('categories.title')}</h1>
          <div className="flex flex-col md:flex-row gap-3 md:items-center">
            <div className="relative">
              <input
                type="text"
                placeholder={t('categories.search.placeholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full md:w-64 pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
              />
              <svg
                className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <button
              onClick={() => {
                setModalType('add');
                setIsModalOpen(true);
              }}
              className="inline-flex items-center justify-center px-4 py-2 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg hover:from-indigo-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105 space-x-2"
            >
              <svg
                className="w-5 h-5 animate-pulse"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
              <span>{t('categories.actions.add')}</span>
            </button>
          </div>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-400 p-3 mb-4">
            <p className="text-red-700">{error}</p>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <Spinner />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('categories.table.image')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('categories.table.name')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('categories.table.description')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('categories.table.parent')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('categories.table.date')}</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">{t('categories.table.action')}</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {categories.map((category) => (
                  <tr key={category.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="w-10 h-10 rounded-full overflow-hidden">
                        <img
                          src={getImageUrl(category.imageUrl)}
                          alt={category.nameEn}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{category.nameEn}</td>
                    <td className="px-6 py-4">{category.descriptionEn}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{category.parent?.nameEn || '-'}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {new Date(category.createdAt).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      })}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => {
                          setSelectedCategory(category);
                          setFormData({
                            ...category,
                            parentId: category.parent?.id || ''
                          });
                          setModalType('edit');
                          setIsModalOpen(true);
                        }}
                        className="text-gray-600 hover:text-gray-900 mr-3"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          setCategoryToDelete(category);
                          setDeleteDialogOpen(true);
                        }}
                        className="text-red-600 hover:text-red-900"
                      >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="mt-4">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
          />
        </div>

        <Modal
          isOpen={isModalOpen}
          onClose={resetForm}
          title={t(modalType === 'add' ? 'categories.form.addTitle' : 'categories.form.editTitle')}
        >
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('categories.form.fields.name.en')}</label>
              <input
                type="text"
                name="nameEn"
                value={formData.nameEn}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('categories.form.fields.name.ar')}</label>
              <input
                type="text"
                name="nameAr"
                value={formData.nameAr}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('categories.form.fields.description.en')}</label>
              <textarea
                name="descriptionEn"
                value={formData.descriptionEn}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                rows="3"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('categories.form.fields.description.ar')}</label>
              <textarea
                name="descriptionAr"
                value={formData.descriptionAr}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
                rows="3"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">{t('categories.form.fields.parent')}</label>
              <select
                name="parentId"
                value={formData.parentId}
                onChange={handleInputChange}
                className="mt-1 block w-full border rounded-md shadow-sm p-2"
              >
                <option value="">{t('categories.form.fields.noParent')}</option>
                {categories
                  .filter(cat => cat.id !== selectedCategory?.id)
                  .map(category => (
                    <option key={category.id} value={category.id}>
                      {category.nameEn}
                    </option>
                  ))}
              </select>
            </div>

            {modalType === 'add' && (
              <div>
                <label className="block text-sm font-medium text-gray-700">{t('categories.form.fields.image')}</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="mt-1"
                  required
                />
                {imagePreview && (
                  <img src={imagePreview} alt="Category preview" className="mt-2 h-20 w-20 object-cover" />
                )}
              </div>
            )}

            {error && <div className="text-red-500">{error}</div>}

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={resetForm}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
              >
                {t('categories.actions.cancel')}
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                {modalType === 'add' ? t('categories.actions.create') : t('categories.actions.update')}
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={deleteDialogOpen}
          onClose={() => {
            setDeleteDialogOpen(false);
            setCategoryToDelete(null);
          }}
          title={t('categories.delete.title')}
        >
          <div className="p-6">
            <p className="mb-4">{t('categories.delete.message')}</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => {
                  setDeleteDialogOpen(false);
                  setCategoryToDelete(null);
                }}
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                disabled={deleteLoading}
              >
                {t('categories.actions.cancel')}
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                disabled={deleteLoading}
              >
                {deleteLoading ? t('categories.delete.processing') : t('categories.delete.confirm')}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}